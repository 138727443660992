<template>
    <div class="modal-wrapper" v-if="profile.incident_detail">
        <div class="modal">
            <div class="modal-header">
                <h3><i class="fas fa-pen"></i>{{profile.incident_detail.calendar.edit_event}}</h3>
                <img src="@/assets/img/logo-white.png" class="logo" />
            </div>
            <div class="modal-inner">
                <span class="close" @click="closeModal()"><i class="fa fa-times"></i></span>
                <p>{{profile.incident_detail.calendar.delete_event_sure}} <br /><br /></p>
                <textarea data-testid="appointmentRemoveReason" class="form-control" v-model="reason" :placeholder="profile.incident_detail.cancelled_reason" :class="{error: reasonError}"></textarea>
                <br />
                <div class="notification notification--error" v-if="reasonError" style="margin-bottom: 20px;">{{ profile.incident_detail.cancelled_reason_error }}.</div>
                <section class="actions actions--full">
                    <div class="button-wrap">
                        <span class="button button--icon button--right" data-testid="appointmentRemoveSubmit" @click="addHandler()"><i class="fa fa-trash-alt"></i>{{profile.incident_detail.calendar.edit_event}}</span>
                    </div>
                </section>
            </div>
        </div>
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale.js';
export default {
    mixins: [setLocale],
    name: 'appointmentEditModal',
    data() {
        return {
            profile: {},
            reason: '',
            reasonError: false
        }
    },
    props: ['appointment', 'incidentInfo', 'mechanicId', 'supplier'],
    created() {
        document.querySelector('.main-content').style.display = 'block';
        document.querySelector('body').classList.add('modal-open');
        let self = this;
        document.onkeydown = function(evt) {
            evt = evt || window.event;
            if (evt.keyCode == 27) {
                self.closeModal();
            }
        };
    },
    beforeDestroy() {
        this.removeHandler();
    },
    methods: {
        closeModal() {
            this.$emit('close');
            document.querySelector('body').classList.remove('modal-open');
        },
        addHandler(id) {
            window.addEventListener('click', this.removeAppointment);
        },
        removeHandler() {
            window.removeEventListener('click', this.removeAppointment);
        },
        removeAppointment() {
            if (this.reason.length < 6) {
                this.reasonError = true;
            }
            else {
                this.reasonError = false;
            }
            if (!this.reasonError) {
                const data = new URLSearchParams();
                let paramId = 'data[' + this.appointment + '][id_appointment]';
                let paramMechanic = 'data[' + this.appointment + '][id_mechanic]';
                let paramIncident = 'data[' + this.appointment + '][id_incident]';
                let paramReason = 'data[' + this.appointment + '][cancel_reason]';
                data.append('action', 'remove');
                data.append(paramId, this.appointment);
                if (this.mechanicId) {
                    data.append(paramMechanic, this.mechanicId);
                }
                data.append(paramIncident, this.incidentInfo.id_incident);
                if (this.reason) {
                    data.append(paramReason, this.reason);
                }
                let self = this;
                fetch('/api/v1/appointment', {
                    method: 'post',
                    body: data
                }).then((resp) => {return resp.json();
                }).then(function(data) {
                    if (!self.supplier) {
                        self.removeRepairCompany();
                    }
                    else {
                        self.$emit('remove');
                    }
                });
            }
        },
        removeRepairCompany() {
            let currentIncidentId = this.getUrlParameter('id');
            if (currentIncidentId && currentIncidentId === this.incidentInfo.id_incident.toString()) {
                let self = this;
                const data = new URLSearchParams();
                data.append('id_incident', this.incidentInfo.id_incident);
                fetch('/api/v1/incident/cancelrepaircompany', {
                    method: 'post',
                    body: data
                }).then((resp) => {return resp.json();
                }).then(function(data) {
                    self.$emit('remove');
                });
            }
        },
        getUrlParameter(name) {
            return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.search)||[""])[1].replace(/\+/g, '%20'))||null;
        }
    }
}
</script>
