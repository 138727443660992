<template>
    <div>
        <div class="main-content">
            <span id="top_anchor" style="position: absolute; top: 0; left: 0;"></span>
            <div class="container">
                <div class="items-wrap">
                    <div class="row">
                        <div class="col">
                            <h3 style="margin: 10px 0; float: left;">{{profile.actionable.recordings}}</h3>
                            <router-link tag="a" to="/monteur/" class="text-link" style="float: right; margin-top: 8px; right: 20px; top: 0; font-size: 16px; font-weight: 500; color: #0867b3;">{{profile.actionable.assignments}} ({{ totalAssignments }})</router-link>
                        </div>
                    </div>
                </div>      
                <div class="items-wrap">
                    <div class="row">
                        <div class="col col-md-4" v-if="qrCode">
                            <div class="button button--outline qr-label" @click="removeQrCode()" style="width: 100%; margin: 20px 0;"><span style="display:block; float: left; font-weight: 500;">S1-CODE&nbsp;</span> <span style="width: 150px; line-height: 49px; overflow: hidden; text-overflow: ellipsis; display:block; float: left; margin-left: 10px;">{{qrCode}}</span> <i class="fas fa-times" style="margin: 15px 0 0 0; float: right;"></i></div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <div class="dashboard-filters">
                                <div class="row">
                                    <div class="col-6 col-md-4" v-if="!qrCode">
                                        <div class="dropdown-section">
                                            <span v-if="selectedAddress" class="remove-selection" style="top: 0; right: 45px; cursor: pointer; z-index: 2; width: 32px; text-align: center; padding-top: 16px;" @click="removeAddress()"><i class="fas fa-times" style="font-size: 22px;"></i></span>
                                            <span class="dropdown" :class="{active: addressDropdownToggled}" @click="addressDropdownToggled = !addressDropdownToggled">
                                                <span v-if="!selectedAddress" class="placeholder">{{profile.places.placetable.address}}</span>
                                                <span v-if="selectedAddress" class="selected">{{selectedAddress}}</span>
                                                <span v-if="selectedAddress" class="seperator">|</span>
                                                <i class="fas fa-chevron-down chevron"></i>
                                            </span>
                                            <div class="dropdown-list" v-if="addressDropdownToggled">
                                                <ul>
                                                    <li v-for="(address, index) in addressList" v-on:click="selectAddress(address)" :key="index">{{address.address}}</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6 col-md-4">
                                        <div class="dropdown-section">
                                            <span v-if="selectedDate" class="remove-selection" style="top: 0; right: 45px; cursor: pointer; z-index: 2; width: 32px; text-align: center; padding-top: 16px;" @click="removeDate()"><i class="fas fa-times" style="font-size: 22px;"></i></span>
                                            <span class="dropdown" :class="{active: dateDropdownToggled}" @click="dateDropdownToggled = !dateDropdownToggled">
                                                <span v-if="!selectedDate" class="placeholder">{{profile.actionable.latest_recording_date}}</span>
                                                <span v-if="selectedDate" class="selected">{{selectedDate}}</span>
                                                <span v-if="selectedDate" class="seperator">|</span>
                                                <i class="fas fa-chevron-down chevron"></i>
                                            </span>
                                            <div class="dropdown-list" v-if="dateDropdownToggled">
                                                <ul>
                                                    <li v-for="(date, index) in dateList" v-on:click="selectDate(date)" :key="index">{{date.latestDate}}</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col col-md-4" v-if="!qrCode">
                                        <div class="button" style="float: right;" @click="scanQrCode()"><i class="fas fa-qrcode" aria-hidden="true"></i> Scan S1-code</div>
                                    </div>
                                </div>                            
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <h4 class="result-counter" v-if="!recordings.length" style="margin-bottom: 20px;">{{profile.dashboard.building_manager.no_resultss}}</h4>
                            <h4 class="result-counter" v-if="recordings.length >= 1" style="margin-bottom: 20px;">{{recordings.length}} {{profile.dashboard.building_manager.results_found}}</h4>
                        </div>
                    </div>
                </div>
                <div class="items-wrap">
                    <div class="row">
                        <div class="col-sm-6 col-lg-4" v-for="(item, index) in recordings" :key="index">
                            <span class="bm-label" style="background: #ffa500;">{{item.building_manager_name}}</span>
                            <div class="appointment-block" @click="toRecording(item.id_planned_action_list)">
                                <div class="order-label" v-if="selectedDate && item.list_order" v-tooltip.right="profile.actionable.route"><i class="fas fa-route"></i> {{ index + 1 }}</div>
                                <div class="post-it" v-if="item.status === 'started'" v-tooltip.right="profile.actionable.open_actionables"><i class="fas fa-exclamation-circle"></i></div>
                                <div class="row">
                                    <div class="col">
                                        <i class="fas fa-cube"></i>
                                        <span class="address">
                                            <b>{{item.asset_name}}</b><br />
                                            {{item.address}} {{item.house_number}}, {{profile.incident_detail.floor_name}} {{item.floor_name}}<br />
                                            {{item.place_name}}
                                        </span>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <p><b><i class="fas fa-clipboard-list"></i> {{profile.actionable.recording}}</b></p>
                                        <span class="address">{{item.planned_action_list_name}}</span>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <p><b><i class="fas fa-calendar-day"></i> {{profile.actionable.latest_recording_date}}</b></p>
                                        <span class="address">{{item.latestDate}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br />
                    <hr />
                    <h3 @click="toggleClosed()" style="cursor: pointer; margin-bottom: 15px;" id="archived">{{profile.assets.history}} <i style="font-weight: 500;">({{archivedRecordings.length}})</i> <i  class="fas fa-chevron-down" :class="{active: showClosed}" style="margin-right: 15px; float: right;"></i></h3>
                    <div v-if="showClosed">
                        <div class="row">
                            <div class="col-sm-8">
                                <section class="search search-inpage">
                                    <form>
                                        <input type="text" ref="search" v-bind:placeholder="profile.places.searchplace" @input="e => searchTerm = e.target.value" :value="searchTerm" v-on:keyup="getArchivedRecordings($event)"  />
                                        <span class="close-suggestions" @click="clearSearch()" v-if="searchTerm"><i class="fas fa-times"></i></span>
                                    </form>
                                </section>
                            </div>
                            <!-- <div class="col-sm-4">
                                <div class="pagination" v-if="archivedCount > 8">
                                    <span class="prev" v-if="archivedCurrentPage !== 1" @click="prevPage('archived')"><i class="fas fa-chevron-left"></i></span>
                                    <span class="page" v-if="archivedCurrentPage !== 1" @click="firstPage('archived')">1</span>
                                    <span class="page-indicator">{{archivedCurrentPage}} / {{archivedTotalPages}}</span>
                                    <span class="page page--last" v-if="archivedTotalPages > 2" @click="lastPage('archived')">{{archivedTotalPages}}</span>
                                    <span class="next" v-if="archivedCurrentPage !== archivedTotalPages" @click="nextPage('archived')"><i class="fas fa-chevron-right"></i></span>
                                </div>
                            </div> -->
                        </div>
                        <div class="row">
                            <div class="col-sm-6 col-lg-4" v-for="(item, index) in archivedRecordings" :key="index">
                                <span class="bm-label" style="background: #ffa500;">{{item.building_manager_name}}</span>
                                <div class="appointment-block" @click="toRecording(item.id_planned_action_list)">
                                    <div class="post-it" v-if="item.status === 'started'" v-tooltip.right="profile.actionable.open_actionables"><i class="fas fa-exclamation-circle"></i></div>
                                    <div class="row">
                                        <div class="col">
                                            <i class="fas fa-cube"></i>
                                            <span class="address">
                                                <b>{{item.asset_name}}</b><br />
                                                {{item.address}} {{item.house_number}}, {{profile.incident_detail.floor_name}} {{item.floor_name}}<br />
                                                {{item.place_name}}
                                            </span>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col">
                                            <p><b><i class="fas fa-clipboard-list"></i> {{profile.actionable.recording}}</b></p>
                                            <span class="address">{{item.planned_action_list_name}}</span>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col">
                                            <p><b><i class="fas fa-calendar-day"></i> {{profile.actionable.latest_recording_date}}</b></p>
                                            <span class="address">{{item.latestDate}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>               
            </div>
        </div>
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale-mechanic.js';
import { mapState } from 'vuex';
import moment from 'moment';
import uniqBy from 'lodash/uniqBy';
import debounce from 'lodash/debounce';
import VueScrollTo from 'vue-scrollto';
import sortBy from 'lodash/sortBy';

export default {
    mixins: [setLocale],
    data() {
        return {
            profile: {},
            recordings: [],
            archivedRecordings: [],
            mechanicId: null,
            addressDropdownToggled: false,
            addressList: [],
            selectedAddress: '',
            dateDropdownToggled: false,
            dateList: [],
            selectedDate: '',
            selectedFrom: null,
            selectedTo: null,
            qrCode: null,
            showClosed: false,
            archivedCount: 0,
            archivedCurrentPage: 1,
            archivedTotalPages: 0,
            searchTerm: ''
        }
    },
    computed: mapState(['totalAssignments']),
    created() {
        document.title = 'S1MONE | ' + this.profile.actionable.recording;
        if (localStorage.getItem('S1-recordingsFilterAddress')) {
            this.selectedAddress = localStorage.getItem('S1-recordingsFilterAddress');
        }
        if (localStorage.getItem('S1-recordingsFilterDate')) {
            this.selectedDate = localStorage.getItem('S1-recordingsFilterDate');
            this.selectedFrom = localStorage.getItem('S1-recordingsFilterFrom');
            this.selectedTo = localStorage.getItem('S1-recordingsFilterTo');
        }
        if (window.location.href.includes('qrcode')) {
            this.qrCode = this.getUrlParameter('qrcode');
            this.getListsByQrCode();
        }
        else {
            this.getId();
        }
    },
    methods: {
        track() {
            this.$gtag.pageview(this.$route);
        },
        getId() {
            let self = this;
            fetch('/api/v1/formechanic/info')
            .then(response => { response.json().then(
                function(data) {
                    self.mechanicId = data.id_mechanic;
                    self.getRecordings();
                    self.getArchivedRecordings();
                });
            });
        },
        getRecordings() {
            this.recordings = [];
            this.dateList = [];
            this.addressList = [];
            let self = this;
            let addressParam = '';
            let fromParam = '';
            let toParam = '';
            let lastChar = this.selectedAddress[this.selectedAddress.length -1];
            if (lastChar === ' ' && this.selectedAddress) {
                addressParam = '?address=' + this.selectedAddress.slice(0, -1) + '%20';
            }
            if (lastChar !== ' ' && this.selectedAddress) {
                addressParam = '?address=' + this.selectedAddress.toLowerCase();
            }
            if (this.selectedTo) {
                fromParam = '?before=' + this.selectedTo;
                toParam = '&after=' + this.selectedFrom;
            }
            if (this.selectedAddress && this.selectedDate) {
                addressParam = '?address=' + this.selectedAddress;
                fromParam = '&before=' + this.selectedTo;
                toParam = '&after=' + this.selectedFrom;
            }
            fetch('/api/v1/plannedactionlist/formechanic' + addressParam + fromParam + toParam)
            .then(response => { response.json().then(
                function(data) {
                    for (var i = 0; i < data.data.length; i++) {
                        let item = data.data[i];
                        item.latestDate = moment(item.latest_possible).locale('nl').format('D MMMM YYYY');
                        self.recordings.push(item);
                    }
                    self.addressList = uniqBy(self.recordings, 'address');
                    self.dateList = uniqBy(self.recordings, 'latest_possible');
                    if (fromParam && self.recordings[0].list_order) {
                        self.recordings = sortBy(self.recordings, 'list_order');
                    }
                });
            });
        },
        toggleClosed() {
            this.showClosed = !this.showClosed;
            setTimeout(function () {
                var options = {
                    offset: -60
                }
                let element = document.getElementById('archived');
                VueScrollTo.scrollTo(element, 200, options);
            }.bind(this), 200);
        },
        getArchivedRecordings: debounce(function (e) {
            this.archivedRecordings = [];
            let self = this;
            let offSetParam = this.archivedCurrentPage - 1;
            offSetParam = offSetParam * 8;
            let searchParam = '';
            if (this.searchTerm.length > 2) {
                searchParam = '&search_term=' + this.searchTerm.toLowerCase();
            }
            else {
                searchParam = '';
            }
            fetch('/api/v1/plannedactionlist/formechanic?show_completed=true' + searchParam)
            .then(response => { response.json().then(
                function(data) {
                    for (var i = 0; i < data.data.length; i++) {
                        let item = data.data[i];
                        item.latestDate = moment(item.latest_possible).locale('nl').format('D MMMM YYYY');
                        self.archivedRecordings.push(item);
                    }
                });
            });
        }, 500),
        clearSearch() {
            this.searchTerm = '';
            this.getArchivedRecordings();
        },
        selectAddress(address) {
            this.selectedAddress = address.address;
            this.dateDropdownToggled = false;
            this.addressDropdownToggled = false;
            localStorage.setItem('S1-recordingsFilterAddress', this.selectedAddress);
            this.getRecordings();
        },
        removeAddress() {
            this.addressDropdownToggled = false;
            this.dateDropdownToggled = false;
            this.selectedAddress = '';
            localStorage.removeItem('S1-recordingsFilterAddress');
            this.getRecordings();
        },
        selectDate(date) {
            this.selectedDate = date.latestDate;
            this.selectedFrom = new Date(date.latest_possible);
            this.selectedFrom.setHours(0, 1);
            this.selectedFrom = this.selectedFrom.getTime();
            this.selectedTo = new Date(date.latest_possible);
            this.selectedTo.setHours(23, 59);
            this.selectedTo = this.selectedTo.getTime();
            this.dateDropdownToggled = false;
            this.addressDropdownToggled = false;
            localStorage.setItem('S1-recordingsFilterDate', this.selectedDate);
            localStorage.setItem('S1-recordingsFilterFrom', this.selectedFrom);
            localStorage.setItem('S1-recordingsFilterTo', this.selectedTo);
            if (!this.qrCode) {
                this.getRecordings();
            }
            else {
                this.getListsByQrCode();
            }
        },
        removeDate() {
            this.addressDropdownToggled = false;
            this.dateDropdownToggled = false;
            this.selectedDate = '';
            this.selectedFrom = ''
            this.selectedTo = '';
            localStorage.removeItem('S1-recordingsFilterDate');
            localStorage.removeItem('S1-recordingsFilterDateTime');
            if (!this.qrCode) {
                this.getRecordings();
            }
            else {
                this.getListsByQrCode();
            }
        },
        toRecording(id) {
            document.getElementById('top_anchor').scrollIntoView();
            this.$router.push('/monteur/opname-detail?id=' + id);
        },
        scanQrCode() {
            this.$router.push('/monteur/scanrecording');
        },
        getListsByQrCode() {
            let self = this;
            let fromParam = '';
            let toParam = '';
            this.recordings = [];
            this.dateList = [];
            if (this.selectedTo) {
                fromParam = '&before=' + this.selectedTo;
                toParam = '&after=' + this.selectedFrom;
            }
            fetch('/api/v1/plannedactionlist/byqrcodewithactions?qr_code=' + this.qrCode + fromParam + toParam)
            .then(response => { response.json().then(
                function(data) {
                    for (var i = 0; i < data.length; i++) {
                        let item = data[i];
                        item.latestDate = moment(item.latest_possible).locale('nl').format('D MMMM YYYY');
                        self.recordings.push(item);
                    }
                    self.dateList = uniqBy(self.recordings, 'latest_possible');
                });
            });
        },
        removeQrCode() {
            this.qrCode = '';
            this.getId();
        },
        nextPage(type) {
            if (type === 'archived') {
                this.archivedCurrentPage++;
                this.getArchivedRecordings();
            }
        },
        prevPage(type) {
            if (type === 'archived') {
                this.archivedCurrentPage--;
                this.getArchivedRecordings();
            }
        },
        firstPage(type) {
            if (type === 'archived') {
                this.archivedCurrentPage = 1;
                this.getArchivedRecordings();
            }
        },
        lastPage(type) {
            if (type === 'archived') {
                this.archivedCurrentPage = this.archivedTotalPages;
                this.getArchivedRecordings();
            }
        }
    }
}
</script>
