<template>
  <header id="header">
      <v-idle
        @idle="onidle"
        :loop="true"
        :duration="14400"
        style="display: none;" />
    <div class="container">
        <div @click="closeNav()">
            <router-link tag="a" to="/s1mone" class="logo"><img src="@/assets/img/logo.png" /></router-link>
        </div>
        <nav class="d-none d-md-block d-lg-block">
            <router-link @click.native="openNav()" tag="a" to="/s1mone/buildingmanagers">{{"Beheerders"}} <span class="counter" v-if="managerWarnings" v-tooltip.right="'Er zijn beheerders met meer objecten dan toegestaan'"> {{ managerWarnings }}</span></router-link>
            <router-link @click.native="openNav()" tag="a" to="/s1mone/buildingmanagerFeatures">{{"Features"}}</router-link>
            <router-link @click.native="openNav()" tag="a" to="/s1mone/statistics">{{"Statistieken"}}</router-link>
            <span class="sub" :class="{active: maintenanceOpen}" @click.stop="maintenanceOpen = !maintenanceOpen">Onderhoud<i class="fas fa-chevron-down"></i>
                <div class="sub-menu" v-show="maintenanceOpen">
                    <router-link tag="a" to="/s1mone/texts" @click.native="openNav()" style="padding-right: 40px;">Teksten </router-link>
                    <router-link tag="a" to="/s1mone/news" @click.native="openNav()" style="padding-right: 20px;">Nieuws </router-link>
                    <router-link tag="a" to="/s1mone/assetTypes" @click.native="openNav()">Objecttypes </router-link>
                    <router-link tag="a" to="/s1mone/defaultCategories" @click.native="openNav()">Standaard categorieën</router-link>
                    <router-link tag="a" to="/s1mone/incidentCategories" @click.native="openNav()">Melding categorieën</router-link>
                    <router-link tag="a" to="/s1mone/specialisms" @click.native="openNav()">Specialismes</router-link>
                    <router-link tag="a" to="/s1mone/industries" @click.native="openNav()">Branches</router-link>
                    <router-link tag="a" to="/s1mone/createdAssets" @click.native="openNav()">Aangemaakte objecten</router-link>
                    <router-link tag="a" to="/s1mone/numberReset" @click.native="openNav()">Telefoonnummer reset </router-link>
                    <router-link tag="a" to="/s1mone/suppliers" @click.native="openNav()">Leveranciers</router-link>
                </div>
            </span>
        </nav>
        <section class="utils">
            <span class="util util--user" @click="showUtils = !showUtils"><i class="fas fa-user"></i></span>
            <ul class="dropdown-nav large" v-show="showUtils">
                <li><a href="/api/v1/logout" style="display: block; width: 47px; text-decoration: none; color: #212529;">Uitloggen</a></li>
            </ul>
        </section>
    </div>
  </header>
</template>

<script>

export default {
    props: ['managerWarnings'],
    data: function () {
        return {
            showUtils: false,
            maintenanceOpen: false
        }
    },
    created() {
        fetch('/api/v1/isauthenticated')
        .then(response => { response.text().then(
            function(data) {
                if (data === 'false') {
                    window.location = '/signin.html';
                }
            });
        });
    },
    methods: {
        onidle() {
            window.location = '/signin.html?timeout=true';
        },
        openNav() {
            this.mobileNavToggle = !this.mobileNavToggle;
            let el = document.body;
            if (this.mobileNavToggle) {
                el.classList.add("modal-open");
            }
            if (!this.mobileNavToggle) {
                el.classList.remove("modal-open");
            }
        },
        closeNav() {
            this.mobileNavToggle = false;
            let el = document.body;
            el.classList.remove("modal-open");
            this.showUtils = false;
        }
    }
}
</script>
