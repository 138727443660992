<template>
    <div>
        <div class="loading" v-if="loading"><img src="@/assets/icons/loading.gif" /></div>
        <div class="highlight-bg" :class="{active: highlightActive}"></div>
        <div class="button-header">
            <div class="incident-head">
                <h1>{{profile.incident_detail.plan_appointment}}</h1>
            </div>
            <div class="row">
                <div class="col">
                    <button class="button button--outline today" @click="today()" style="float: left;">Vandaag</button>
                    <div class="view-buttons">
                        <button class="button button--outline switch-button" @click="changeView('week')" style="float: left;">Week</button>
                        <button class="button button--outline switch-button" @click="changeView('day')" style="float: left;">Dag</button>
                    </div>
                    <button class="button button--outline next" @click="next()" style="float: right; margin-left: 5px;"><i class="fas fa-chevron-right"></i></button>
                    <button class="button button--outline prev" @click="prev()" style="float: right;"><i class="fas fa-chevron-left"></i></button>
                    <button class="button button--outline weekends" @click="toggleWeekends()" :class="{active: !calendarOptions.week.workweek}">{{profile.incident_detail.weekend}}</button>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <h3 style="text-transform: capitalize; padding: 0; background: white; margin: 13px 0 0 0; float: left;">{{currentMonth}}</h3>
                    <button class="button button--cta button--small" data-testid='appointmentNewButton' @click="startNewAppointment()" style="float: right; margin-top: 10px;" v-if="!supplier && !incidentInfo.id_appointment && incidentInfo.serviced_by_bm_user_id === incidentInfo.current_user_id">{{profile.incident_detail.create_appointment}}</button>
                    <button class="button button--cta button--small" data-testid='appointmentNewButton' @click="startNewAppointment()" style="float: right; margin-top: 10px;" v-if="supplier && incidentInfo.serviced_by_rep_user_id && !incidentInfo.id_appointment && incidentInfo.mandate_warning === 'no_warning'">{{profile.incident_detail.create_appointment}}</button>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-3 filter-wrap">
                <div class="mechanic-filter">
                    <div class="mechanics">
                        <h3 style="padding: 15px 0 0 20px; margin-bottom: 5px; border-top: 1px solid #e5e5e5;">Filters <i class="fas fa-info-circle" v-tooltip.right="profile.incident_detail.plan_appointment_tooltip"></i></h3>
                        <div class="mechanic-row">
                            <div class="filter-item all" data-testid="incidentCalenderFilter" @click="saveFilter(false, false, true)">
                                <span class="color-legend" data-testid='calenderFilterAll' :class="{active: allMechanicsActive}">
                                    <i class="fas fa-check" v-if="allMechanicsActive" style="margin: 3px 0 0 2px;"></i>
                                </span>
                                <p>{{profile.mechanics.mechanictable.all_specialists}}</p>
                            </div>
                        </div>
                        <div class="mechanic-row" v-for="mechanic in mechanicsWithAppointment" :key="mechanic.id">
                            <div class="filter-item" @click="saveFilter(mechanic, true, false, false)">
                                <span class="color-legend" data-testid='calenderFilterMechanic' :style="mechanic.active ? 'background:' + mechanic.color : 'border: 2px solid ' + mechanic.color">
                                    <i class="fas fa-check"  v-if="mechanic.active" data-testid='appointmentSpecialistCheck'></i>
                                </span>
                                <p>{{mechanic.name}}</p>
                            </div>
                        </div>
                        <hr v-if="!appointmentPassed && incidentInfo.start_time" />
                        <div class="mechanic-row" style="padding-top: 0;" v-if="!appointmentPassed && incidentInfo.start_time">
                            <div class="filter-item" @click="toAppointment()">
                                <span class="color-legend link" data-testid='appointmentCurrentCheck' style="background: orange;"></span>
                                <p>{{profile.incident_detail.current}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-9 calendar-wrap">
                <Calendar style="height: 800px"
                    ref="calendar"
                    :template="calendarOptions.template"
                    :calendars="calendarList"
                    :view="calendarOptions.view"
                    :usage-statistics="false"
                    :use-detail-popup="false"
                    :month="calendarOptions.month"
                    :events="calendarOptions.events"
                    :week="calendarOptions.week"
                    @selectDateTime="createTempAppointment"
                    @beforeUpdateEvent="updateEventDrop"
                    @clickEvent="eventEdit"
                />
                <div class="creation-popup" ref="draggableContainer" id="draggable-container" v-if="createNewAppointment">
                    <div class="popup-header" @mousedown="dragMouseDown">
                        <span><i class="fas fa-expand-arrows-alt"></i>{{profile.incident_detail.drag_here}}</span>
                    </div>
                    <span @click="cancelAppointmentEdit()" class="close-popup"><i class="fa fa-times"></i></span>
                    <div v-if="eventReadOnly" class="eventreadonly">
                        <div v-html="currentEvent.title"></div>
                        <router-link tag="a" v-bind:to="'/buildingmanager/incident-detail?id=' + currentEvent.id" style="float: left; font-weight: 500;">{{currentEvent.id}}</router-link>
                        <span class="status-bar" v-if="currentEvent.location && !profile['statusses'][currentEvent.location].buildingmanager" style="position: absolute; right: 5px;">
                            <div v-html="profile['status_icons'][currentEvent.location]" v-if="currentEvent.location && currentEvent.location !== 'paused_mechanic'"></div>
                            <div v-html="profile['status_icons'][currentEvent.location]" v-if="currentEvent.location && currentEvent.location === 'paused_mechanic'" v-tooltip.right="incidentInfo.pause_reason"></div>
                            <span v-if="currentEvent.location && currentEvent.location === 'paused_mechanic'" v-tooltip.right="incidentInfo.pause_reason">{{profile['statusses'][currentEvent.location]}}</span>
                            <span v-if="currentEvent.location && currentEvent.location === 'cancelled'" v-tooltip.right="incidentInfo.cancelled_reason">{{profile['statusses'][currentEvent.location]}}</span>
                            <span v-if="currentEvent.location && currentEvent.location !== 'paused_mechanic' && currentEvent.location !== 'cancelled'">{{profile['statusses'][currentEvent.location]}}</span>
                        </span>
                        <span class="status-bar" v-if="currentEvent.location && profile['statusses'][currentEvent.location].buildingmanager" style="position: absolute; right: 5px;">
                            <div v-html="profile['status_icons'][currentEvent.location].buildingmanager" v-if="currentEvent.location && currentEvent.location !== 'paused_mechanic' && profile['status_icons'][currentEvent.location].buildingmanager"></div>
                            <div v-html="profile['status_icons'][currentEvent.location]" v-if="currentEvent.location && currentEvent.location !== 'paused_mechanic' && !profile['status_icons'][currentEvent.location].buildingmanager"></div>
                            <div v-html="profile['status_icons'][currentEvent.location].buildingmanager" v-if="currentEvent.location && currentEvent.location === 'paused_mechanic' && profile['status_icons'][currentEvent.location].buildingmanager" v-tooltip.right="incidentInfo.pause_reason"></div>
                            <div v-html="profile['status_icons'][currentEvent.location]" v-if="currentEvent.location && currentEvent.location === 'paused_mechanic'" v-tooltip.right="incidentInfo.pause_reason"></div>
                            <span v-if="currentEvent.location && currentEvent.location === 'paused_mechanic'" v-tooltip.right="incidentInfo.pause_reason">{{profile['statusses'][currentEvent.location].buildingmanager}}</span>
                            <span v-if="currentEvent.location && currentEvent.location === 'cancelled'" v-tooltip.right="incidentInfo.cancelled_reason">{{profile['statusses'][currentEvent.locations].buildingmanager}}</span>
                            <span v-if="currentEvent.location && currentEvent.location !== 'paused_mechanic' && currentEvent.location !== 'cancelled'">{{profile['statusses'][currentEvent.location].buildingmanager}}</span>
                        </span>
                    </div>
                    <div v-else>
                        <form class="new_appointment" v-on:submit.prevent="planAppointment()">
                            <div class="checkbox-indicator" @click="toggleCalendarChoice()" style="cursor: pointer;">
                                <i class="far fa-check-square" v-if="latestPossible"></i>
                                <i class="far fa-square" data-testid="appointmentLatestButton" v-else></i>
                            </div>
                            <label @click="toggleCalendarChoice()" style="cursor: pointer;">{{profile.incident_detail.choose_latest_possible}}?</label>
                            <br />
                            <div v-if="!latestPossible" style="clear: both;">
                                <select class="form-select" data-testid="appointmentSpecialistSelect" v-model="mechanicSelected" @change="saveFilter(false, false, false, false, mechanicSelected)" required v-if="mechanics.length > 1 || mechanicsNoS1mone.length">
                                    <option :value="null" disabled>{{profile.incident_detail.select_a_mechanic}}</option>
                                    <option data-testid="appointmentSpecialistResult" v-for="(mechanic, index) in mechanics" :value="mechanic.id_mechanic" :key="index">{{mechanic.name}}</option>
                                    <optgroup label="Geen S1MONE" v-if="mechanicsNoS1mone.length">
                                        <option data-testid="appointmentSpecialistResult" v-for="(mechanic, index) in mechanicsNoS1mone" :value="mechanic.id_mechanic" :key="index">{{mechanic.name}}</option>
                                    </optgroup>
                                </select>
                                <flat-pickr v-model="newAppointment.date" class="form-control" data-testid="appointmentDateSelect" :config="flatPickrDateConfig" :placeholder="profile.incident_detail.pick_date" @on-change="changeAppointmentFromInput('date')" @on-close="changeAppointmentFromInput()"></flat-pickr>
                                <div class="row">
                                    <div class="col-6">
                                        <flat-pickr v-model="newAppointment.start_time" class="form-control" data-testid="appointmentStartSelect"  :config="flatPickrTimeConfig" :placeholder="profile.incident_detail.pick_starttime" @on-change="changeAppointmentFromInput('start')" @on-close="changeAppointmentFromInput('start')"></flat-pickr>
                                    </div>
                                    <div class="col-6">
                                        <flat-pickr v-model="newAppointment.end_time" class="form-control" data-testid="appointmentEndSelect" :config="flatPickrTimeConfig" :placeholder="profile.incident_detail.pick_endtime" @on-change="changeAppointmentFromInput()" @on-close="changeAppointmentFromInput()"></flat-pickr>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <p style="margin-bottom: 5px; float: left;"><b>{{ profile.incident_detail.arrival_estimate }}</b></p>
                                        <div class="switch-wrapper" style="float: right; margin: 2px 0 0 0;">
                                            <label class="switch" style="float: left; margin: 0;">
                                                <input type="checkbox" v-model="estimatePeriod">
                                                <span class="slider round"></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div class="row" v-if="estimatePeriod">
                                    <div class="col-6">
                                        <flat-pickr v-model="newAppointment.arrival_estimate_start" class="form-control" data-testid="appointmentStartSelect"  :config="flatPickrTimeConfig" :placeholder="profile.incident_detail.pick_starttime"></flat-pickr>
                                    </div>
                                    <div class="col-6">
                                        <flat-pickr v-model="newAppointment.arrival_estimate_end" class="form-control" data-testid="appointmentEndSelect" :config="flatPickrTimeConfig" :placeholder="profile.incident_detail.pick_endtime"></flat-pickr>
                                    </div>
                                </div>
                                <hr style="margin-top: 5px;" />
                                <div v-if="!incidentInfo.incident_extra_repair_company">
                                    <textarea class="form-control" data-testid="appointmentInfoInput" v-model="tempAdditionalMechanic" :placeholder="profile.incident_detail.extra_info_mech"></textarea>
                                </div>
                                <span class="notification notification--error" v-if="timeError" style="margin-top: 10px;">{{profile.incident_detail.timeError}}</span>
                                <span class="notification notification--error" v-if="pastError" style="margin-top: 10px;">{{profile.incident_detail.pastError}}</span>
                                <div class="highlight" :class="{active: highlightActive}">
                                    <button type="submit" class="button button--small button--right" data-testid="appointmentSubmitButton" v-if="!pastError"><i class="far fa-calendar"></i>
                                        <span v-if="incidentInfo.start_time">{{profile.incident_detail.edit_appointment}}</span>
                                        <span v-else>{{profile.incident_detail.create_appointment}}</span>
                                    </button>
                                    <div class="highlight-box" v-if="highlightActive">
                                        <span @click="highlightActive = !highlightActive"><i class="fa fa-times"></i></span>
                                        <h3>{{profile.incident_detail.appointment_highlight_title}}</h3>
                                        <p>{{profile.incident_detail.appointment_highlight_text}}</p>
                                        <div class="button button--small button--left" style="margin-top: 15px;" @click="dontShowHighlight()">Niet meer tonen</div>
                                    </div>
                                </div>
                                <span class="button button--small button--right" data-testid="appointmentDeleteButton" style="margin-top: 10px;" @click="appointmentModalOpen = true" v-if="appointmentEdit"><i class="fa fa-times"></i>{{profile.incident_detail.calendar.edit_event}}</span>
                            </div>
                        </form>
                        <form class="new_appointment" v-on:submit.prevent="planAppointment()" v-if="latestPossible">
                            <div style="clear: both;">
                                <select class="form-select" data-testid="appointmentSpecialistSelect" v-model="mechanicSelected" required @change="saveFilter(false, false, false, false, mechanicSelected)" v-if="mechanics.length || mechanicsNoS1mone.length">
                                    <option :value="null" disabled>{{profile.incident_detail.select_a_mechanic}}</option>
                                    <option v-for="(mechanic, index) in mechanics" :value="mechanic.id_mechanic" :key="index">{{mechanic.name}}</option>
                                    <optgroup label="Geen S1MONE" v-if="mechanicsNoS1mone.length">
                                        <option v-for="(mechanic, index) in mechanicsNoS1mone" :value="mechanic.id_mechanic" :key="index">{{mechanic.name}}</option>
                                    </optgroup>
                                </select>
                                <flat-pickr v-model="newAppointment.date" class="form-control" :config="flatPickrDateConfig" :placeholder="profile.incident_detail.pick_date" @on-change="changeAppointmentFromInput()" @on-close="changeAppointmentFromInput()"></flat-pickr>
                                <div v-if="!incidentInfo.incident_extra_repair_company">
                                    <textarea class="form-control" v-model="tempAdditionalMechanic" :placeholder="profile.incident_detail.extra_info_mech"></textarea>
                                </div>
                                <span class="notification notification--error" v-if="pastError" style="margin-top: 10px;">{{profile.incident_detail.pastError}}</span>
                                <div class="highlight" :class="{active: highlightActive}">
                                    <button type="submit" class="button button--small button--right" data-testid="appointmentEditSubmitButton"  v-if="!pastError"><i class="far fa-calendar"></i>
                                        <span v-if="incidentInfo.start_time">{{profile.incident_detail.edit_appointment}}</span>
                                        <span v-else>{{profile.incident_detail.create_appointment}}</span>
                                    </button>
                                    <div class="highlight-box" v-if="highlightActive">
                                        <span @click="highlightActive = !highlightActive"><i class="fa fa-times"></i></span>
                                        <h3>{{profile.incident_detail.appointment_highlight_title}}</h3>
                                        <p>{{profile.incident_detail.appointment_highlight_text}}</p>
                                        <div class="button button--small button--left" style="margin-top: 15px;" @click="dontShowHighlight()">Niet meer tonen</div>
                                    </div>
                                </div>
                                <span class="button button--small button--right" data-testid="appointmentDeleteSubmitButton" style="margin-top: 10px;" @click="appointmentModalOpen = true" v-if="appointmentEdit && !pastError && !timeError"><i class="fa fa-times"></i>{{profile.incident_detail.calendar.edit_event}}</span>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <AppointmentEditModal v-if="appointmentModalOpen" @close="appointmentModalOpen = false" @remove="appointmentModalClose(selectedMechanic)" :appointment="incidentInfo.id_appointment" :incidentInfo="incidentInfo" :mechanicId="selectedMechanic" :supplier="supplier" />
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale.js';
import {Dutch} from 'flatpickr/dist/l10n/nl.js';
import Calendar from '@toast-ui/vue-calendar';
import '@toast-ui/calendar/dist/toastui-calendar.css';
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import moment from 'moment';
import uniqBy from 'lodash/uniqBy';
import AppointmentEditModal from "@/components/modals/AppointmentEditModal.vue";

export default {
    name: 'Schedule',
    mixins: [setLocale],
    components: {
        flatPickr,
        Calendar,
        AppointmentEditModal
    },
    data: function () {
        return {
            loading: false,
            refNumberEdit: false,
            calendarOptions: {
                view: 'week',
                week: {
                    dayNames: ['Zo', 'Ma', 'Di', 'Wo', 'Do', 'Vr', 'Za'],
                    workweek: true,
                    taskView: false,
                    hourStart: 7,
                    hourEnd: 21,
                    visibleWeeksCount: 3
                },
                month: {
                    dayNames: ['Z', 'M', 'D', 'W', 'D', 'V', 'Z'],
                    visibleWeeksCount: 3,
                },
                template: {
                    timegridDisplayPrimaryTime({ time }) {
                        let date = time.d.d;
                        let times = moment(date).format('HH:mm');
                        return times;
                    },
                    timegridDisplayTime({ time }) {
                        return `sub timezone: ${time}`;
                    },
                    alldayTitle() {
                        return 'Uiterste<br />uitvoer';
                    }
                },
                events: []
            },
            flatPickrDateConfig: {
                altInput: true,
                altFormat: "F j, Y",
                dateFormat: "Y-m-d"
            },
            flatPickrTimeConfig: {
                enableTime: true,
                noCalendar: true,
                dateFormat: "H:i",
                time_24hr: true
            },
            newAppointment: {
                date: null,
                start_time: null,
                end_time: null,
                arrival_estimate_start: null,
                arrival_estimate_end: null
            },
            keepMechs: false,
            loadCalendar: false,
            mechanicsWithAppointment: [],
            calendarList: [],
            createNewAppointment: false,
            positions: {
                clientX: undefined,
                clientY: undefined,
                movementX: 0,
                movementY: 0
            },
            eventReadOnly: false,
            appointmentEdit: false,
            currentEvent: {},
            appointmentPassed: false,
            currentMonth: '',
            latestPossible: false,
            timeError: false,
            pastError: false,
            highlightActive: false,
            mechanicSelected: null,
            appointmentModalOpen: false,
            tempAdditionalMechanic: '',
            allMechanicsActive: false,
            mechanicNotApplicable: false,
            chatMessage: '',
            estimatePeriod: true
        }
    },
    props: ['incidentInfo', 'readOnly', 'admin', 'selectedMechanic', 'mechanics', 'mechanicsNoS1mone', 'supplier'],
    computed: {
        calendarInstance() {
            if (this.$refs.calendar) {
                return this.$refs.calendar.getInstance();
            }
            else {
                return ''
            }
        }
    },
    created() {
        this.mechanicSelected = this.selectedMechanic;
        if (this.locale === 'nl') {
            this.flatPickrDateConfig.locale = Dutch;
            this.flatPickrDateConfig.altFormat = "j F, Y";
        }
        this.$emit('getMechanics', false);
        var curr = new Date;
        if (this.keepMechs) {
            this.getAppointments(curr, true);
        }
        else {
            this.getAppointments(curr);
        }
        if (this.incidentInfo.id_appointment) {
            this.toAppointment();
        }
        if (this.incidentInfo.calendar_choice === 'choose_latest_possible') {
            this.latestPossible = true;
        }
    },
    methods: {
        getAppointments(curr, keepMechs) {
            this.calendarOptions.events = [];
            this.eventReadOnly = false;
            var first = curr.getDate() - curr.getDay();
            first = first - 31;
            var last = first + 300;
            var firstday = new Date(curr.setDate(first)).valueOf();
            var lastday = new Date(curr.setDate(last)).valueOf();

            if (this.incidentInfo.start_time) {
                let startDate = new Date(this.incidentInfo.start_time);
                let startFirst = startDate.getDate() - startDate.getDay();
                startFirst = startFirst - 31;
                firstday = startFirst;
                firstday = new Date(startDate.setDate(startFirst)).valueOf();
            }
            let self = this;
            fetch('/api/v1/appointment/byrepaircompanyforcal' + '?fetchStartDate=' + firstday + '&fetchEndDate=' + lastday)
            .then(response => { response.json().then(
                function(data) {
                    let appointments = data;
                    if (appointments.length) {
                        if (!keepMechs) {
                            self.mechanicsWithAppointment = [];
                        }
                        let tempEvents = [];
                        for (var i = 0; i < appointments.length; i++) {
                            let event = appointments[i];
                            event.startTime = new Date(event.start);
                            event.endTime = new Date(event.end);
                            let calendarEvent = {};
                            calendarEvent.id = event.url;
                            calendarEvent.title = '<h3>' + event.asset_name + ' |</h3><span class="description" style="display: block; font-weight: 500;">' + event.description + '|</span><p>' + event.place_address + '</p>';
                            calendarEvent.start = new Date(event.start);
                            calendarEvent.end = new Date(event.end);
                            calendarEvent.isReadOnly = true;
                            calendarEvent.color = 'white';
                            calendarEvent.isVisible = false;
                            calendarEvent.calendarId = '1';
                            calendarEvent.attendees = [event.mechanic_name];
                            if (event.mechanic_id_mechanic) {
                                calendarEvent.state = event.mechanic_id_mechanic.toString();
                            }
                            calendarEvent.location = event.incident_status;
                            if (calendarEvent.location === 'on_location_mechanic'
                                || calendarEvent.location === 'fixed_mechanic'
                                || calendarEvent.location === 'fixed_agreed_auto'
                                || calendarEvent.location === 'closed'
                                || calendarEvent.location === 'cancelled'
                                || calendarEvent.location === 'fixed_agreed_repaircompany'
                                || calendarEvent.location === 'maintenance_checkin_mechanic'
                            ) {
                                calendarEvent.backgroundColor = '#ccc';
                                calendarEvent.isReadOnly = true;
                            }
                            let startTime = calendarEvent.start.getHours();
                            let endTime = calendarEvent.end.getHours();
                            if (startTime === 1 & endTime === 23) {
                                calendarEvent.isAllday = true;
                            }
                            calendarEvent.customStyle = {
                                borderLeft: 'none'
                            }
                            let mechanic = {
                                name: event.mechanic_name,
                                id: event.mechanic_id_mechanic,
                                active: false,
                                color: '#0867b3',
                                backgroundColor: '#0867b3'
                            }
                            if (!mechanic.id) {
                                mechanic.name = 'Zonder naam';
                            }
                            if (!keepMechs) {
                                self.mechanicsWithAppointment.push(mechanic);
                            }
                            tempEvents.push(calendarEvent);
                        }
                        if (!keepMechs) {
                            self.mechanicsWithAppointment = uniqBy(self.mechanicsWithAppointment, 'id');
                            self.mechanicsWithAppointment[0].color = '#343844';
                            self.mechanicsWithAppointment[0].backgroundColor = '#343844';
                            if (self.mechanicsWithAppointment[1]) {
                                self.mechanicsWithAppointment[1].color = '#2AB69D';
                                self.mechanicsWithAppointment[1].backgroundColor = '#2AB69D';
                            }
                            if (self.mechanicsWithAppointment[2]) {
                                self.mechanicsWithAppointment[2].color = '#E65848';
                                self.mechanicsWithAppointment[2].backgroundColor = '#E65848';
                            }
                            if (self.mechanicsWithAppointment[3]) {
                                self.mechanicsWithAppointment[3].color = '#FDC536';
                                self.mechanicsWithAppointment[3].backgroundColor = '#FDC536';
                            }
                            if (self.mechanicsWithAppointment[4]) {
                                self.mechanicsWithAppointment[4].color = '#FCF2D7';
                                self.mechanicsWithAppointment[4].backgroundColor = '#FCF2D7';
                            }
                            if (self.mechanicsWithAppointment[5]) {
                                self.mechanicsWithAppointment[5].color = '#B8D9C6';
                                self.mechanicsWithAppointment[5].backgroundColor = '#B8D9C6';
                            }
                            if (self.mechanicsWithAppointment[6]) {
                                self.mechanicsWithAppointment[6].color = '#F2C48D';
                                self.mechanicsWithAppointment[6].backgroundColor = '#F2C48D';
                            }
                            if (self.mechanicsWithAppointment[7]) {
                                self.mechanicsWithAppointment[7].color = '#F28B66';
                                self.mechanicsWithAppointment[7].backgroundColor = '#F28B66';
                            }
                            if (self.mechanicsWithAppointment[8]) {
                                self.mechanicsWithAppointment[8].color = '#F2EBE9';
                                self.mechanicsWithAppointment[8].backgroundColor = '#F2EBE9';
                            }
                            if (self.mechanicsWithAppointment[9]) {
                                self.mechanicsWithAppointment[9].color = '#F27166';
                                self.mechanicsWithAppointment[9].backgroundColor = '#F27166';
                            }
                        }
                        self.calendarList = self.mechanicsWithAppointment;
                        for (var i = 0; i < tempEvents.length; i++) {
                            let event = tempEvents[i];
                            if (event.location !== 'on_location_mechanic'
                                && event.location !== 'fixed_mechanic'
                                && event.location !== 'fixed_agreed_auto'
                                && event.location !== 'closed'
                                && event.location !== 'cancelled'
                                && event.location !== 'fixed_agreed_repaircompany'
                                && event.location !== 'maintenance_checkin_mechanic'
                            ) {
                                if (self.mechanicsWithAppointment[0].id && event.state === self.mechanicsWithAppointment[0].id.toString()) {
                                    event.backgroundColor = '#343844';
                                }
                                if (self.mechanicsWithAppointment[1] && self.mechanicsWithAppointment[1].id) {
                                    if (event.state === self.mechanicsWithAppointment[1].id.toString()) {
                                        event.backgroundColor = '#2AB69D';
                                    }
                                }
                                if (self.mechanicsWithAppointment[2] && self.mechanicsWithAppointment[2].id) {
                                    if (event.state === self.mechanicsWithAppointment[2].id.toString()) {
                                        event.backgroundColor = '#E65848';
                                    }
                                }
                                if (self.mechanicsWithAppointment[3] && self.mechanicsWithAppointment[3].id) {
                                    if (event.state === self.mechanicsWithAppointment[3].id.toString()) {
                                        event.backgroundColor = '#FDC536';
                                    }
                                }
                                if (self.mechanicsWithAppointment[4] && self.mechanicsWithAppointment[4].id) {
                                    if (event.state === self.mechanicsWithAppointment[4].id.toString()) {
                                        event.backgroundColor = '#FCF2D7';
                                    }
                                }
                                if (self.mechanicsWithAppointment[5] && self.mechanicsWithAppointment[5].id) {
                                    if (event.state === self.mechanicsWithAppointment[5].id.toString()) {
                                        event.backgroundColor = '#B8D9C6';
                                    }
                                }
                                if (self.mechanicsWithAppointment[6] && self.mechanicsWithAppointment[6].id) {
                                    if (event.state === self.mechanicsWithAppointment[6].id.toString()) {
                                        event.backgroundColor = '#F2C48D';
                                    }
                                }
                                if (self.mechanicsWithAppointment[7] && self.mechanicsWithAppointment[7].id) {
                                    if (event.state === self.mechanicsWithAppointment[7].id.toString()) {
                                        event.backgroundColor = '#F28B66';
                                    }
                                }
                                if (self.mechanicsWithAppointment[8] && self.mechanicsWithAppointment[8].id) {
                                    if (event.state === self.mechanicsWithAppointment[8].id.toString()) {
                                        event.backgroundColor = '#F2EBE9';
                                    }
                                }
                                if (self.mechanicsWithAppointment[9] && self.mechanicsWithAppointment[9]) {
                                    if (event.state === self.mechanicsWithAppointment[9].id.toString()) {
                                        event.backgroundColor = '#F27166';
                                    }
                                }
                            }
                            if (event.id.toString() === self.incidentInfo.id_incident.toString()) {
                                event.backgroundColor = 'orange';
                                for (var j = 0; j < self.mechanicsWithAppointment.length; j++) {
                                    let mechanic = self.mechanicsWithAppointment[j];
                                    if (mechanic.id && mechanic.id.toString() === event.state) {
                                        event.customStyle = {
                                            borderLeft: '3px solid ' + mechanic.color
                                        }
                                    }
                                }
                                event.isReadOnly = false;
                            }
                            if (event.id.toString() === self.incidentInfo.id_incident.toString()
                                && (self.incidentInfo.incident_status === 'on_location_mechanic'
                                || self.incidentInfo.incident_status === 'fixed_mechanic'
                                || self.incidentInfo.incident_status === 'fixed_agreed_auto'
                                || self.incidentInfo.incident_status === 'closed'
                                || self.incidentInfo.incident_status === 'cancelled'
                                || self.incidentInfo.incident_status === 'fixed_agreed_repaircompany'
                                || self.incidentInfo.incident_status === 'maintenance_checkin_mechanic'
                            )) {
                                self.appointmentPassed = true;
                            }
                        }
                        self.calendarOptions.events = tempEvents;
                        if (!keepMechs) {
                            setTimeout(function () {
                                for (var i = 0; i < self.mechanicsWithAppointment.length; i++) {
                                    let mechanic = self.mechanicsWithAppointment[i];
                                    if (self.calendarInstance) {
                                        if (mechanic.id === self.incidentInfo.id_mechanic || (!mechanic.id && self.incidentInfo.id_appointment && !self.incidentInfo.id_mechanic)) {
                                            mechanic.active = true;
                                            self.saveFilter(mechanic);
                                        }
                                    }
                                }
                                let date = '';
                                if (self.calendarInstance.container) {
                                    date = self.calendarInstance.getDateRangeStart();
                                }
                                date = date.d.d;
                                if (self.locale === 'nl') {
                                    self.currentMonth = moment(date).locale('nl').format('MMMM');
                                }
                                else {
                                    self.currentMonth = moment(date).format('MMMM');
                                }
                                self.loading = false;
                            }.bind(this), 500);
                        }
                        else {
                            setTimeout(function () {
                                for (var i = 0; i < self.mechanicsWithAppointment.length; i++) {
                                    let mechanic = self.mechanicsWithAppointment[i];
                                    if (self.calendarInstance) {
                                        if (self.allMechanicsActive) {
                                            self.saveFilter(false, false, true, true);
                                        }
                                        else {
                                            self.saveFilter(mechanic);
                                        }
                                    }
                                }
                                self.loading = false;
                            }.bind(this), 500);
                        }
                        setTimeout(function () {
                            if (self.calendarInstance.container && !self.incidentInfo.id_appointment) {
                                self.calendarInstance.setOptions({
                                    isReadOnly: false
                                });
                            }
                            let blocks = document.querySelectorAll('.toastui-calendar-template-time span');
                            blocks.forEach(function(el) {
                                el.innerHTML = el.innerHTML.replaceAll('|', '<br />')
                            });
                        }.bind(this), 1000);
                    }
                });
            });
        },
        saveFilter(mechanic, fromInput, all, keepAll, mechanicId) {
            if (fromInput) {
                mechanic.active = !mechanic.active;
                for (var i = 0; i < this.mechanicsWithAppointment.length; i++) {
                    let mechanic = this.mechanicsWithAppointment[i];
                    if (mechanic.active) {
                        this.saveFilter(mechanic);
                    }
                }
            }
            if (mechanic) {
                if (this.allMechanicsActive) {
                    for (var i = 0; i < this.calendarOptions.events.length; i++) {
                        let event = this.calendarOptions.events[i];
                        this.calendarInstance.updateEvent(event.id, event.calendarId, {
                            isVisible: false
                        });
                    }
                }
                this.allMechanicsActive = false;
                for (var i = 0; i < this.calendarOptions.events.length; i++) {
                    let event = this.calendarOptions.events[i];
                    if ((mechanic.id && event.state === mechanic.id.toString()) || (!mechanic.id && !event.state)) {
                        if (mechanic.active) {
                            this.calendarInstance.updateEvent(event.id, event.calendarId, {
                                isVisible: true,
                            });
                            if (event.location !== 'on_location_mechanic'
                                && event.location !== 'fixed_mechanic'
                                && event.location !== 'fixed_agreed_auto'
                                && event.location !== 'closed'
                                && event.location !== 'cancelled'
                                && event.location !== 'fixed_agreed_repaircompany'
                                && event.location !== 'maintenance_checkin_mechanic'
                            ) {
                                if (event.id.toString() !== this.incidentInfo.id_incident.toString()) {
                                    this.calendarInstance.updateEvent(event.id, event.calendarId, {
                                        backgroundColor: mechanic.color
                                    });
                                }
                            }
                            else {
                                this.calendarInstance.updateEvent(event.id, event.calendarId, {
                                    backgroundColor: '#ccc',
                                    customStyle: {
                                        borderLeft: '3px solid ' + mechanic.color
                                    }
                                });
                            }
                        }
                        else {
                            this.calendarInstance.updateEvent(event.id, event.calendarId, {
                                isVisible: false
                            });
                        }
                    }
                    if (event.id.toString() === this.incidentInfo.id_incident.toString()) {
                        this.calendarInstance.updateEvent(event.id, event.calendarId, {
                            customStyle: {
                                borderLeft: '3px solid ' + mechanic.color
                            }
                        });
                    }
                }
            }
            if (mechanicId) {
                this.mechanicNotApplicable = false;
            }
            else {
                this.mechanicNotApplicable = true;
            }
            if (all) {
                if (!keepAll) {
                    this.allMechanicsActive = !this.allMechanicsActive;
                    for (var i = 0; i < this.calendarOptions.events.length; i++) {
                        let event = this.calendarOptions.events[i];
                        this.calendarInstance.updateEvent(event.id, event.calendarId, {
                            isVisible: false
                        });
                    }
                }
                if (this.allMechanicsActive || keepAll) {
                    for (var i = 0; i < this.mechanicsWithAppointment.length; i++) {
                        let mechanic = this.mechanicsWithAppointment[i];
                        mechanic.active = true;
                    }
                    for (var i = 0; i < this.calendarOptions.events.length; i++) {
                        let event = this.calendarOptions.events[i];
                        this.calendarInstance.updateEvent(event.id, event.calendarId, {
                            isVisible: true
                        });
                        if (event.location !== 'on_location_mechanic'
                            && event.location !== 'fixed_mechanic'
                            && event.location !== 'fixed_agreed_auto'
                            && event.location !== 'closed'
                            && event.location !== 'cancelled'
                            && event.location !== 'fixed_agreed_repaircompany'
                            && event.location !== 'maintenance_checkin_mechanic'
                        ) {
                            if (event.id.toString() !== this.incidentInfo.id_incident.toString()) {
                                this.calendarInstance.updateEvent(event.id, event.calendarId, {
                                    backgroundColor: event.backgroundColor
                                });
                            }
                        }
                        else {
                            this.calendarInstance.updateEvent(event.id, event.calendarId, {
                                backgroundColor: '#ccc',
                                customStyle: {
                                    borderLeft: '3px solid ' + event.backgroundColor
                                }
                            });
                        }
                    }
                }
                else {
                    for (var i = 0; i < this.mechanicsWithAppointment.length; i++) {
                        let mechanic = this.mechanicsWithAppointment[i];
                        mechanic.active = false;
                    }
                    for (var i = 0; i < this.calendarOptions.events.length; i++) {
                        let event = this.calendarOptions.events[i];
                        this.calendarInstance.updateEvent(event.id, event.calendarId, {
                            isVisible: false
                        });
                    }
                }
            }
            setTimeout(function () {
                let blocks = document.querySelectorAll('.toastui-calendar-template-time span');
                blocks.forEach(function(el) {
                    el.innerHTML = el.innerHTML.replaceAll('|', '<br />')
                });
            }.bind(this), 1000);
        },
        prev() {
            this.calendarInstance.prev();
            let date = this.calendarInstance.getDateRangeStart();
            date = date.d.d;
            if (this.locale === 'nl') {
                this.currentMonth = moment(date).locale('nl').format('MMMM');
            }
            else {
                this.currentMonth = moment(date).format('MMMM');
            }
            this.getAppointments(date, true);
        },
        next() {
            this.calendarInstance.next();
            let date = this.calendarInstance.getDateRangeStart();
            date = date.d.d;
            if (this.locale === 'nl') {
                this.currentMonth = moment(date).locale('nl').format('MMMM');
            }
            else {
                this.currentMonth = moment(date).format('MMMM');
            }
            this.getAppointments(date, true);
        },
        changeView(type) {
            if (type === 'week') {
                this.calendarInstance.changeView('week');
            }
            else {
                this.calendarInstance.changeView('day');
            }
        },
        today() {
            this.calendarInstance.today();
            let date = this.calendarInstance.getDateRangeStart();
            date = date.d.d;
            if (this.locale === 'nl') {
                this.currentMonth = moment(date).locale('nl').format('MMMM');
            }
            else {
                this.currentMonth = moment(date).format('MMMM');
            }
        },
        toggleWeekends() {
            if (this.calendarOptions.week.workweek) {
                this.calendarOptions.week.workweek = false;
                this.calendarInstance.setOptions({
                    week: {
                        workweek: false
                    }
                });
            }
            else {
                this.calendarOptions.week.workweek = true;
                this.calendarInstance.setOptions({
                    week: {
                        workweek: true
                    }
                });
            }
        },
        toggleCalendarChoice() {
            this.latestPossible = !this.latestPossible;
            let id = Number(this.incidentInfo.id_incident);
            if (this.latestPossible) {
                this.calendarInstance.updateEvent(this.incidentInfo.id_incident, '1', {
                    isAllday: true
                });
                if (this.$el.querySelector('.toastui-calendar-grid-selection')) {
                    this.$el.querySelector('.toastui-calendar-grid-selection').style.display = 'none';
                }
            }
            else {
                this.calendarInstance.updateEvent(id, '1', {
                    isAllday: false
                });
                if (this.$el.querySelector('.toastui-calendar-grid-selection')) {
                    this.$el.querySelector('.toastui-calendar-grid-selection').style.display = 'block';
                }
            }
        },
        submitRepairCompanyAction() {
            let self = this;
            const data = new URLSearchParams();
            data.append('id_incident', this.incidentInfo.id_incident);
            data.append('id_repair_company', this.incidentInfo.canbe_id_repair_company);
            fetch('/api/v1/incident/torepaircompany', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.json();
            }).then(function(data) {
            });
        },
        changeAppointmentFromInput(type) {
            let date;
            if (this.newAppointment.date.toString().includes('-')) {
                date = new Date(this.newAppointment.date);
            }
            else {
                date = this.newAppointment.date;
            }
            // Create start moment for event
            let startHour = this.newAppointment.start_time.split(':')[0];
            let startMinutes = this.newAppointment.start_time.split(':')[1];
            startHour = Number(startHour);
            startMinutes = Number(startMinutes);
            let startTime = date.setHours(startHour, startMinutes);
            let startTimeDate = new Date(startTime);
            if (type === 'start') {
                let endTime = this.newAppointment.end_time = this.newAppointment.start_time.split(':')[0];
                // let estimateStart =
                endTime = Number(endTime) + 1;
                endTime = endTime.toString();
                endTime = endTime + ':' + this.newAppointment.start_time.split(':')[1];
                this.newAppointment.end_time = endTime;

                let estimateStart = moment(this.newAppointment.date);
                estimateStart.subtract(30 , 'minutes');
                estimateStart = estimateStart.format('HH:mm');
                let estimateEnd = moment(this.newAppointment.date);
                estimateEnd.add(30 , 'minutes').format();
                estimateEnd = estimateEnd.format('HH:mm');
                this.chatMessage = 'De verwachte aankomsttijd van de specialist: tussen ' + estimateStart + ' en ' + estimateEnd;
                this.newAppointment.arrival_estimate_start = estimateStart;
                this.newAppointment.arrival_estimate_end = estimateEnd;
            }
            let endHour;
            let endMinutes;
            // Create end moment for event
            if (this.newAppointment.end_time) {
                endHour = this.newAppointment.end_time.split(':')[0];
                endMinutes = this.newAppointment.end_time.split(':')[1]
            }
            endHour = Number(endHour);
            endMinutes = Number(endMinutes);
            let endTime = date.setHours(endHour, endMinutes);
            let endTimeDate = new Date(endTime);
            let id = Number(this.incidentInfo.id_incident);
            let now = new Date();
            let nowTime = now.getTime();
            let planDate = startTimeDate.getTime();
            if (planDate < nowTime) {
                this.pastError = true;
            }
            else {
                this.pastError = false;
            }
            if (this.latestPossible && moment(now).format('MM YY DD') === moment(this.newAppointment.date).format('MM YY DD')) {
                this.pastError = false;
            }
            this.calendarInstance.updateEvent(id, '1', {
                start: startTimeDate,
                end: endTimeDate
            });
        },
        planAppointment() {
            if (!this.incidentInfo.id_repair_company) {
                this.submitRepairCompanyAction();
            }
            this.loading = true;
            this.createNewAppointment = false;
            this.highlightActive = false;
            if (!this.incidentInfo.incident_extra_repair_company && this.tempAdditionalMechanic) {
                this.saveAdditionalMechanic();
            }
            let start_time = new Date(this.newAppointment.date);
            let end_time = new Date(this.newAppointment.date);
            let time_start;
            let time_end;
            let start_date;
            if (this.latestPossible) {
                start_date = moment(start_time).format('MM YY');
                start_time.setHours(1);
                end_time.setHours(23);
                start_time = start_time.getTime();
                end_time = end_time.getTime();
            }
            else {
                time_start = this.newAppointment.start_time.split(':');
                time_end = this.newAppointment.end_time.split(':');
                start_time.setHours(time_start[0], time_start[1], '00', '00');
                end_time.setHours(time_end[0], time_end[1], '00', '00');
                start_time = start_time.getTime();
                end_time = end_time.getTime();
            }
            let now = new Date();
            now = now.getTime();
            let planDate = start_time;
            let now_start_date = moment(now).format('MM YY');
            if (planDate < now) {
                this.pastError = true;
            }
            else {
                this.pastError = false;
            }
            if (this.latestPossible && start_date === now_start_date) {
                this.pastError = false;
            }
            let calendar_choice;
            if (this.latestPossible) {
                calendar_choice = 'choose_latest_possible';
            }
            else {
                calendar_choice = 'plan_appointment';
            }
            const data = new URLSearchParams();
            data.append('id_incident', this.incidentInfo.id_incident);
            data.append('calendar_choice', calendar_choice);
            let self = this;
            fetch('/api/v1/incident/setcalendarchoice', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.json();
            }).then(function(data) {
                if (self.appointmentEdit) {
                    self.calendarInstance.clearGridSelections();
                    const data = new URLSearchParams();
                    let paramStart = 'data[' + self.incidentInfo.id_appointment + '][start_time]';
                    let paramEnd = 'data[' + self.incidentInfo.id_appointment + '][end_time]';
                    let paramId = 'data[' + self.incidentInfo.id_appointment + '][id_incident]';
                    let paramMechanic = 'data[' + self.incidentInfo.id_appointment + '][id_mechanic]';
                    data.append('action', 'edit');
                    data.append(paramStart, start_time);
                    data.append(paramEnd, end_time);
                    data.append(paramId, self.incidentInfo.id_incident.toString());
                    if (self.mechanicSelected) {
                        data.append(paramMechanic, self.mechanicSelected);
                    }
                    else {
                        data.append(paramMechanic, '');
                    }
                    fetch('/api/v1/appointment', {
                        method: 'post',
                        body: data
                    }).then((resp) => {return resp.json();
                    }).then(function() {
                        self.getIncidentInfo(false, true);
                        self.appointmentEdit = false;
                        self.createNewAppointment  = false;
                        self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                        self.loading = false;
                        if (!self.latestPossible && self.estimatePeriod) {
                            self.$store.dispatch('updateAppointmentMessage', self.chatMessage);
                        }
                    });
                }
                else {
                    if (!self.pastError) {
                        const data = new URLSearchParams();
                        let paramStart = 'data[0][start_time]';
                        let paramEnd = 'data[0][end_time]';
                        let paramId = 'data[0][id_incident]';
                        let paramMechanic = 'data[0][id_mechanic]';
                        data.append('action', 'create');
                        data.append(paramStart, start_time);
                        data.append(paramEnd, end_time);
                        data.append(paramId, self.incidentInfo.id_incident.toString());
                        if (self.mechanicSelected) {
                            data.append(paramMechanic, self.mechanicSelected);
                        }
                        fetch('/api/v1/appointment', {
                            method: 'post',
                            body: data
                        }).then((resp) => {return resp.json();
                        }).then(function(data) {
                            self.getIncidentInfo(false, true);
                            var curr = new Date;
                            self.getAppointments(curr);
                            self.getInvoiceParty();
                            self.newAppointment.date = '';
                            self.newAppointment.start_time = '';
                            self.newAppointment.end_time = '';
                            self.incidentInfo.incident_status = 'planned';
                            self.chatKey += 1;
                            self.manualCheckIn = true;
                            self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                            self.loading = false;
                            if (!self.latestPossible && self.estimatePeriod) {
                                self.$store.dispatch('updateAppointmentMessage', self.chatMessage);
                            }
                        });
                    }
                }
            });
        },
        getInvoiceParty() {
            this.selectedType = '';
            let self = this;
            fetch('/api/v1/invoice_data/byincident?id_incident=' + this.incidentInfo.id_incident)
            .then(response => { response.json().then(
                function(data) {
                    self.invoiceParty = data;
                    self.invoiceParty.date = moment(self.invoiceParty.created).format('DD/MM/YYYY HH:mm:ss');
                    self.loading = false;
                });
            });
        },
        createTempAppointment(event) {
            if (!this.readOnly) {
                this.calendarInstance.clearGridSelections();
                if (!this.incidentInfo.id_appointment && (this.incidentInfo.serviced_by_rep_user_id === this.incidentInfo.current_user_id || this.incidentInfo.serviced_by_bm_user_id === this.incidentInfo.current_user_id)) {
                    this.calendarInstance.clearGridSelections();
                    this.createNewAppointment = true;
                    let now = new Date();
                    now = now.getTime();
                    let planDate = event.start.getTime();
                    if (planDate < now) {
                        this.pastError = true;
                    }
                    else {
                        this.pastError = false;
                    }
                    this.newAppointment.date = event.start;
                    this.newAppointment.start_time = moment(event.start).format().split('T')[1];
                    this.newAppointment.end_time = moment(event.end).format().split('T')[1];
                    console.log(this.newAppointment.date);
                    let estimateStart = moment(this.newAppointment.date);
                    estimateStart.subtract(30 , 'minutes');
                    estimateStart = estimateStart.format('HH:mm');
                    let estimateEnd = moment(this.newAppointment.date);
                    estimateEnd.add(30 , 'minutes').format();
                    estimateEnd = estimateEnd.format('HH:mm');
                    this.chatMessage = 'De verwachte aankomsttijd van de specialist: tussen ' + estimateStart + ' en ' + estimateEnd;
                    this.newAppointment.arrival_estimate_start = estimateStart;
                    this.newAppointment.arrival_estimate_end = estimateEnd;

                    if (!localStorage.getItem('S1-highlight-plan_appointment')) {
                        if (window.innerWidth > 991 && !this.pastError) {
                            this.highlightActive = true;
                        }
                    }
                }
            }
        },
        updateEventDrop(event) {
            this.mechanicSelected = this.incidentInfo.id_mechanic;
            if (!this.readOnly) {
                this.calendarInstance.clearGridSelections();
                this.createNewAppointment = true;
                this.appointmentEdit = true;
                this.newAppointment.end_time = moment(event.changes.end.d.d).format().split('T')[1];
                if (event.changes.start) {
                    this.newAppointment.date = event.changes.start.d.d;
                    this.newAppointment.start_time = moment(event.changes.start.d.d).format().split('T')[1];
                    this.calendarInstance.updateEvent(event.event.id, event.event.calendarId, {
                        start: event.changes.start.d.d,
                        end: event.changes.end.d.d
                    });
                }
                else {
                    this.newAppointment.date = event.event.start.d.d;
                    this.newAppointment.start_time = moment(event.event.start.d.d).format().split('T')[1];
                    this.calendarInstance.updateEvent(event.event.id, event.event.calendarId, {
                        end: event.changes.end.d.d
                    });
                }
            }
        },
        startNewAppointment() {
            this.createNewAppointment = true;
            if (!this.incidentInfo.id_mechanic) {
                this.mechanicSelected = null;
            }
            let now = new Date();
            this.newAppointment.date = now;
            this.newAppointment.start_time = moment(now).add(5, 'minutes').format().split('T')[1];
            this.newAppointment.end_time = moment(now).add(35, 'minutes').format().split('T')[1];
            let estimateStart = moment(this.newAppointment.date);
            estimateStart.subtract(25 , 'minutes');
            estimateStart = estimateStart.format('HH:mm');
            let estimateEnd = moment(this.newAppointment.date);
            estimateEnd.add(25 , 'minutes').format();
            estimateEnd = estimateEnd.format('HH:mm');
            this.chatMessage = 'De verwachte aankomsttijd van de specialist: tussen ' + estimateStart + ' en ' + estimateEnd;
            this.newAppointment.arrival_estimate_start = estimateStart;
            this.newAppointment.arrival_estimate_end = estimateEnd;
        },
        cancelAppointmentEdit() {
            this.eventReadOnly = false;
            this.highlightActive = false;
            this.createNewAppointment = false;
            self.appointmentEdit = false;
            if (this.$el.querySelector('.toastui-calendar-grid-selection')) {
                this.$el.querySelector('.toastui-calendar-grid-selection').style.display = 'none';
            }
            this.tempAdditionalMechanic = '';
        },
        eventEdit(event) {
            this.mechanicSelected = this.incidentInfo.id_mechanic;
            let strippedTitle = event.event.title.replaceAll('|', '');
            if (this.$el.querySelector('.toastui-calendar-grid-selection')) {
                this.$el.querySelector('.toastui-calendar-grid-selection').style.display = 'none';
            }
            this.createNewAppointment = true;
            this.appointmentEdit = true;
            if (event.event.isReadOnly) {
                this.eventReadOnly = true;
                let currentEvent = {
                    title: strippedTitle,
                    id: event.event.id,
                    location: event.event.location
                }
                this.currentEvent = currentEvent;
            }
            let now = new Date();
            let nowTime = now.getTime();
            let start_time;
            let end_time;
            if (event.changes && event.changes.start) {
                start_time = event.changes.start.d.d.getTime();
                this.newAppointment.date = event.changes.start.d.d;
                this.newAppointment.start_time = moment(event.changes.start.d.d).format().split('T')[1];
                this.calendarInstance.updateEvent(event.event.id, event.event.calendarId, {
                    start: event.changes.start.d.d
                });
            }
            else {
                start_time = event.event.start.d.d.getTime();
                this.newAppointment.date = event.event.start.d.d;
                this.newAppointment.start_time = moment(event.event.start.d.d).format().split('T')[1];
                this.calendarInstance.updateEvent(event.event.id, event.event.calendarId, {
                    start: event.event.start.d.d
                });
            }
            if (event.changes && event.changes.end) {
                end_time = event.changes.end.d.d.getTime();
                this.newAppointment.end_time = moment(event.changes.end.d.d).format().split('T')[1];
                this.calendarInstance.updateEvent(event.event.id, event.event.calendarId, {
                    end: event.changes.end.d.d
                });
            }
            else {
                end_time = event.event.end.d.d.getTime();
                this.newAppointment.end_time = moment(event.event.end.d.d).format().split('T')[1];
                this.calendarInstance.updateEvent(event.event.id, event.event.calendarId, {
                    end: event.event.end.d.d
                });
            }
            if (start_time < nowTime) {
                this.pastError = true;
            }
            else {
                this.pastError = false;
            }
            if (this.latestPossible && moment(event.event.start.d.d).format('YY MM DD') === moment(now).format('YY MM DD')) {
                this.pastError = false;
            }
        },
        toAppointment() {
            setTimeout(function () {
                let date = new Date(this.incidentInfo.start_time);
                if (this.locale === 'nl') {
                    this.currentMonth = moment(date).locale('nl').format('MMMM');
                }
                else {
                    this.currentMonth = moment(date).format('MMMM');
                }
                let parsedDate = moment(date).format();
                if (this.calendarInstance) {
                    this.calendarInstance.setDate(parsedDate);
                }
            }.bind(this), 500);
        },
        appointmentModalClose() {
            this.createNewAppointment = false;
            this.appointmentEdit = false;
            this.appointmentModalOpen = false;
            this.timeError = false;
            this.pastError = false;
            this.incidentInfo.incident_status = 'pending_repaircompany';
            this.newAppointment = {};
            var curr = new Date;
            this.getAppointments(curr);
            this.getIncidentInfo(false, true);
        },
        saveAdditionalMechanic() {
            const data = new URLSearchParams();
            data.append('id_incident', this.incidentInfo.id_incident);
            data.append('extra_repair_company', this.tempAdditionalMechanic);
            this.incidentInfo.incident_extra_repair_company = this.tempAdditionalMechanic;
            fetch('/api/v1/incident/updateextrarepaircompany', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.json();
            }).then(function(data) {
            });
        },
        dragMouseDown: function (event) {
            event.preventDefault()
            // get the mouse cursor position at startup:
            this.positions.clientX = event.clientX
            this.positions.clientY = event.clientY
            document.onmousemove = this.elementDrag
            document.onmouseup = this.closeDragElement
        },
        elementDrag: function (event) {
            event.preventDefault()
            this.positions.movementX = this.positions.clientX - event.clientX
            this.positions.movementY = this.positions.clientY - event.clientY
            this.positions.clientX = event.clientX
            this.positions.clientY = event.clientY
            // set the element's new position:
            this.$refs.draggableContainer.style.top = (this.$refs.draggableContainer.offsetTop - this.positions.movementY) + 'px'
            this.$refs.draggableContainer.style.left = (this.$refs.draggableContainer.offsetLeft - this.positions.movementX) + 'px'
        },
        closeDragElement() {
            document.onmouseup = null
            document.onmousemove = null
        },
        dontShowHighlight() {
            if (!localStorage.getItem('S1-highlight-plan_appointment')) {
                localStorage.setItem('S1-highlight-plan_appointment', true);
                this.highlightActive = false;
            }
        },
        getIncidentInfo(keepMechs, refreshChat, removeMechanicChat) {
            this.$emit('getIncidentInfo', false, refreshChat, removeMechanicChat);
            var curr = new Date;
            this.getAppointments(curr, keepMechs);
        }
    }
}
</script>
