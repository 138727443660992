<template>
    <div class="modal-wrapper">
        <div class="modal">
            <div class="modal-header">
                <h3><i class="far fa-trash-alt"></i> Melding categorie verwijderen </h3>
                <img src="@/assets/img/logo-white.png" class="logo" />
            </div>
            <div class="modal-inner">
                <span class="close" @click="closeModal()"><i class="fas fa-times"></i></span>
                <p> Weet u zeker dat u deze categorie wilt verwijderen?</p>
                <button class="button button--icon button--right" v-on:click="removeIndustry()"><i class="far fa-trash-alt"></i>Melding categorie verwijderen</button>
            </div>
        </div>
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale-mechanic.js';
export default {
    mixins: [setLocale],
    name: 'removeIncidentCategoryModal',
    props: ['category'],
    data() {
        return {
            profile: {}
        }
    },
    created() {
        document.querySelector('body').classList.add('modal-open');
        let self = this;
        document.onkeydown = function(evt) {
            evt = evt || window.event;
            if (evt.keyCode == 27) {
                self.closeModal();
            }
        };
    },
    methods: {
        removeIndustry() {
            const data = new URLSearchParams();
            data.append('action', 'remove');
            data.append('data['+this.category.id_incident_category+'][id_default_incident_category]', this.category.id_incident_category);
            data.append('data['+this.category.id_incident_category+'][dutch_name]', this.category.dutch_name);
            data.append('data['+this.category.id_incident_category+'][english_name]', this.category.english_name);
            data.append('data['+this.category.id_incident_category+'][ukranian_name]', this.category.ukranian_name);
            data.append('data['+this.category.id_incident_category+'][russian_name]', this.category.russian_name);
            data.append('data['+this.category.id_incident_category+'][polish_name]', this.category.polish_name);
            data.append('data['+this.category.id_incident_category+'][italian_name]', this.category.italian_name);
            data.append('data['+this.category.id_incident_category+'][german_name]', this.category.german_name);
            data.append('data['+this.category.id_incident_category+'][french_name]', this.category.french_name);
            data.append('data['+this.category.id_incident_category+'][preferred_contact_type]', "");
            data.append('data['+this.category.id_incident_category+'][contact_phone]', this.category.contact_phone);
            data.append('data['+this.category.id_incident_category+'][contact_url]', this.category.contact_url);
            data.append('data['+this.category.id_incident_category+'][id_building_manager]', this.category.id_building_manager);
            data.append('data['+this.category.id_incident_category+'][active]', this.category.active);
            data.append('data['+this.category.id_incident_category+'][list_order]', this.category.list_order);
            data.append('data['+this.category.id_incident_category+'][for_whom]', this.category.for_whom);
            data.append('data['+this.category.id_incident_category+'][media_wanted]', this.category.media_wanted);
            data.append('data['+this.category.id_incident_category+'][media_required]', this.category.media_required);
            let self = this;
            fetch('/api/v1/incident_category', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.json();
            }).then(function(data) {
                self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                self.closeModal();
            });
        },
        closeModal() {
            this.$emit('close');
            document.querySelector('body').classList.remove('modal-open');
        }
    }
}
</script>
