<template>
    <div>
        <div class="main-content" v-if="profile.incident_detail">
            <div class="container">
                <div class="tabs">
                    <div class="tab" @click="currentTab = 'places'" :class="{active: currentTab === 'places'}">
                        <h3>{{profile.nav.places}}</h3>
                    </div>
                    <div class="tab" data-testid="typeTab" @click="currentTab = 'location types'" :class="{active: currentTab === 'location types'}" v-if="admin && !disableEdit">
                        <h3>{{profile.places.placetype}}</h3>
                    </div>
                </div>
                <div class="tab-view" :class="{active: currentTab === 'places'}">
                    <div class="row">
                        <div class="col" style="position: relative;">
                            <h1>{{profile.nav.places}}</h1>
                            <span class="edit-button icon" v-tooltip.right="profile.places.importplaces" @click="importLocationModalOpen = true" style="right: 60px;" v-if="admin && !disableEdit"><i class="fas fa-file-excel"></i></span>
                            <span class="edit-button icon" @click="$router.push('/buildingmanager/new-location')" v-if="admin && !disableEdit"><i class="fas fa-plus"></i></span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-8">
                            <section class="search search-inpage" :class="{active: searchActive}">
                                <form @submit.prevent="search()">
                                    <input type="text" ref="search" v-bind:placeholder="profile.places.searchplace" @input="e => searchTerm = e.target.value" :value="searchTerm" v-on:keyup="search($event)"  />
                                    <span class="close-suggestions" @click="clearSearch()" v-if="searchActive"><i class="fas fa-times"></i></span>
                                </form>
                            </section>
                        </div>
                        <div class="col-sm-4">
                            <div class="pagination mobile-margin" v-if="placesTotalPages > 1">
                                <span class="prev" v-if="placesCurrentPage !== 1" @click="prevPage()"><i class="fas fa-chevron-left"></i></span>
                                <span class="page" v-if="placesCurrentPage !== 1" @click="firstPage()">1</span>
                                <span class="page-indicator">{{placesCurrentPage}} / {{placesTotalPages}}</span>
                                <span class="page page--last" v-if="placesTotalPages > 2" @click="lastPage('currentincident')">{{placesTotalPages}}</span>
                                <span class="next" @click="nextPage()" v-if="placesCurrentPage !== placesTotalPages"><i class="fas fa-chevron-right"></i></span>
                            </div>
                        </div>
                    </div>
                    <div class="table-wrap">
                        <div v-if="!loading">
                            <h4 class="result-counter" data-testid="locationSearchResult" v-if="!searchActive && totalPlaces" style="margin-bottom: 20px;">{{totalPlaces}} <span style="text-transform: lowercase; font-weight: 500;">{{profile.places.placetable.tablename}}</span></h4>
                            <h4 class="result-counter" data-testid="locationSearchResult" v-if="searchActive && !totalPlaces" style="margin-bottom: 20px;">{{profile.dashboard.building_manager.no_results}} '{{searchTerm}}'.</h4>
                            <h4 class="result-counter" data-testid="locationSearchResult" v-if="searchActive && totalPlaces >= 1" style="margin-bottom: 20px;">{{places.length}} {{profile.dashboard.building_manager.results}} '{{searchTerm}}'</h4>
                        </div>
                        <table class="table table-striped" v-if="places.length && !loading">
                            <thead>
                                <tr>
                                    <th scope="col" @click="sortTable('address')" :class="{active: placesSortType === 'address'}">{{profile.incidents.incidenttable.address}} <i class="fas fa-sort"></i></th>
                                    <th scope="col" @click="sortTable('zipcode')" :class="{active: placesSortType === 'zipcode'}">{{profile.places.placetable.zipcode}} <i class="fas fa-sort"></i></th>
                                    <th scope="col" @click="sortTable('city')" :class="{active: placesSortType === 'city'}">{{profile.places.placetable.city}} <i class="fas fa-sort"></i></th>
                                    <th scope="col" @click="sortTable('name')" :class="{active: placesSortType === 'name'}">{{profile.places.placetable.name}} <i class="fas fa-sort"></i></th>
                                    <th scope="col" @click="sortTable('floor_name')" :class="{active: placesSortType === 'floor_name'}">{{profile.places.placetable.floor}} <i class="fas fa-sort"></i></th>
                                    <th scope="col" @click="sortTable('owner_name')" :class="{active: placesSortType === 'name_owner'}" v-if="vve">{{profile.assets.vve_short}} <i class="fas fa-sort"></i></th>
                                    <th scope="col" @click="sortTable('owner_name')" :class="{active: placesSortType === 'name_owner'}" v-else>{{profile.places.fieldsArr[7].label}} <i class="fas fa-sort"></i></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="item in places" :key="item.id_place" @click="toLocation(item.id_place)">
                                    <td data-testid="tableAddressText">{{item.address}} {{item.house_number}}</td>
                                    <td data-testid="tableZipText">{{item.zipcode}}</td>
                                    <td data-testid="tableCityText">{{item.city}}</td>
                                    <td data-testid="tableNameText">{{item.name}}</td>
                                    <td data-testid="tableFloorText" v-if="item.floor_name === 'NVT'">{{profile.places.n_a}}</td>
                                    <td data-testid="tableFloorText" v-if="item.floor_name === '0'">{{profile.places.b_g}}</td>
                                    <td data-testid="tableFloorText" v-if="item.floor_name !== '0' && item.floor_name !== 'NVT'">{{item.floor_name}}</td>
                                    <td data-testid="tableOwnerText">{{item.name_owner}}</td>
                                </tr>
                            </tbody>
                        </table>
                        <img src="@/assets/icons/loading.gif" v-if="loading" class="loader" />
                    </div>
                </div>
                <div class="tab-view" :class="{active: currentTab === 'location types'}">
                    <div class="row">
                        <div class="col" style="position: relative;">
                            <h1 :class="{empty: !locationTypes.length}">{{profile.places.placetype}}</h1>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-6">
                            <ul class="seperated-list">
                                <li v-for="(type, index) in locationTypes" :key="index">
                                    <span data-testid="typeNameText" v-if="!type.edit">{{type.name}}</span>
                                    <input v-if="type.edit" type="text" class="form-control" data-testid="typeNameInput" v-model="type.name" />
                                    <span class="edit-button icon" data-testid="typeDeleteButton" @click="deleteType(type)"><i class="fas fa-trash-alt"></i></span>
                                    <span class="button-seperator">|</span>
                                    <span class="edit-button icon" data-testid="typeEditButton" @click="type.edit = true" v-if="!type.edit"><i class="fas fa-pen"></i></span>
                                    <span class="edit-button icon" data-testid="typeSaveButton" @click="editType(type)" v-if="type.edit"><i class="fas fa-save"></i></span>
                                </li>
                            </ul>
                            <div class="add-box">
                                <input type="text" class="form-control" data-testid="typeAddNameInput" :placeholder="profile.s1mone.defaultplacetypetable.addtitle" v-model="locationTypeTerm" />
                                <span class="edit-button icon" data-testid="typeAddButton" @click="createType()"><i class="fas fa-plus"></i></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ImportLocationModal v-if="importLocationModalOpen" @close="importplaces()" />
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale.js';
import { mapState } from 'vuex';
import debounce from 'lodash/debounce';
import ImportLocationModal from "@/components/modals/ImportLocationModal.vue";

export default {
    mixins: [setLocale],
    components: {
        ImportLocationModal
    },
    data() {
        return {
            profile: {},
            places: [],
            totalPlaces: 0,
            locationTypes: [],
            placesSortType: '',
            placesCurrentPage: 1,
            placesTotalPages: 1,
            sortOrder: 'asc',
            searchTerm: '',
            locationTypeTerm: '',
            searchActive: false,
            importLocationModalOpen: false,
            currentTab: 'places',
            admin: false,
            loading: false,
            disableEdit: false
        }
    },
    computed: mapState(['vve']),
    created() {
        if (this.profile.nav) {
            document.title = 'S1MONE | ' + this.profile.nav.places;
        }
        if (localStorage.getItem('S1-locationsPage')) {
            this.placesCurrentPage = localStorage.getItem('S1-locationsPage');
        }
        if (localStorage.getItem('S1-locationsSortOrder')) {
            this.sortOrder = localStorage.getItem('S1-locationsSortOrder');
        }
        if (localStorage.getItem('S1-locationsSorter')) {
            this.placesSortType = localStorage.getItem('S1-locationsSorter');
        }
        if (localStorage.getItem('S1-locationsSearchTerm')) {
            this.searchTerm = localStorage.getItem('S1-locationsSearchTerm');
            this.placesCurrentPage = 1;
            this.sortOrder = 'asc';
            this.placesSortType = '';
            this.searchActive = true;
        }
        this.getPlaces();
        this.getAccountInfo();
        this.getTypes();
    },
    methods: {
        track() {
            this.$gtag.pageview(this.$route);
        },
        getAccountInfo() {
            let self = this;
            fetch('/api/v1/userandbuildingmanager')
            .then(response => { response.json().then(
                function(data) {
                    self.admin = data.building_manager_admin;
                    self.disableEdit = data.disable_edit_basedata;
                });
            });
        },
        getPlaces: debounce(function (e) {
            this.loading = true;
            this.places = [];
            let from;
            if (this.placesCurrentPage === 1) {
                from = 0;
            }
            else {
                from = (this.placesCurrentPage - 1) * 25;
            }
            let fromParam = '&offset=' + from;
            let orderParam = '&sort_dir=' + this.sortOrder;
            let sortParam = '';
            if (this.placesSortType) {
                sortParam = '&sort_col=' + this.placesSortType;
            }
            let searchParam = '';
            if (this.searchTerm) {
                searchParam = '&search_name=' + this.searchTerm;
            }
            let self = this;
            fetch('/api/v1/place/withownerinfo?limit=25' + fromParam + orderParam + sortParam + searchParam)
            .then(response => { response.json().then(
                function(data) {
                    self.places = data.data;
                    self.totalPlaces = data.recordsTotal;
                    self.placesTotalPages = data.recordsTotal / 25;
                    self.placesTotalPages = Math.ceil(self.placesTotalPages);
                    for (var j = 0; j < self.places.length; j++) {
                        let item = self.places[j];
                        if (item.floor_name === '0') {
                            if (self.locale === 'nl') {
                                item.floor_name = 'Begane grond'
                            }
                            if (self.locale === 'en') {
                                item.floor_name = 'Ground floor'
                            }
                        }
                        item.zipcode = item.zipcode.replace(/\s/g, '');
                        item.zipcode = item.zipcode.substring(0, item.zipcode.length - 2) + ' ' + item.zipcode.substring(item.zipcode.length - 2);
                    }
                    self.loading = false;
                });
            });
        }, 500),
        getTypes() {
            let self = this;
            fetch('/api/v1/placetype')
            .then(response => { response.json().then(
                function(data) {
                    for (var i = 0; i < data.data.length; i++) {
                        let type = data.data[i];
                        type.edit = false;
                    }
                    self.locationTypes = data.data;
                });
            });
        },
        sortTable(sorter) {
            this.sortOrder = this.sortOrder !== 'asc' ? 'asc' : 'desc';
            localStorage.setItem('S1-locationsSortOrder', this.sortOrder);
            this.placesSortType = sorter;
            localStorage.setItem('S1-locationsSorter', this.placesSortType);
            this.getPlaces();
        },
        nextPage() {
            this.placesCurrentPage++;
            this.getPlaces();
            localStorage.setItem('S1-locationsPage', this.placesCurrentPage);
        },
        prevPage() {
            this.placesCurrentPage--;
            this.getPlaces();
            localStorage.setItem('S1-locationsPage', this.placesCurrentPage);
        },
        firstPage() {
            this.placesCurrentPage = 1;
            this.getPlaces();
            localStorage.setItem('S1-locationsPage', this.placesCurrentPage);
        },
        lastPage() {
            this.placesCurrentPage = this.placesTotalPages;
            this.getPlaces();
            localStorage.setItem('S1-locationsPage', this.placesCurrentPage);
        },
        search($event) {
            this.placesCurrentPage = 1;
            let searchTerm = this.searchTerm.toLowerCase();
            if (searchTerm.length > 2) {
                this.searchActive = true;
                this.getPlaces();
            }
            if (searchTerm.length < 2 && this.searchActive)  {
                localStorage.removeItem('S1-locationsSearchTerm');
                this.searchActive = false;
                this.getPlaces();
            }
            if($event && $event.keyCode === 27) {
                this.searchActive = false;
                this.searchTerm = '';
                this.getPlaces();
                localStorage.removeItem('S1-locationsSearchTerm');
            }
            localStorage.setItem('S1-locationsSearchTerm', this.searchTerm);
        },
        clearSearch() {
            this.searchActive = false;
            this.searchTerm = '';
            this.getPlaces();
            localStorage.removeItem('S1-locationsSearchTerm');
        },
        toLocation(id) {
            this.$router.push('/buildingmanager/location-detail?id_place=' + id);
        },
        createType() {
            const data = new URLSearchParams();
            let param = 'data[0][name]';
            data.append('action', 'create');
            data.append(param, this.locationTypeTerm);
            let self = this;
            if (this.locationTypeTerm) {
                fetch('/api/v1/placetype', {
                    method: 'post',
                    body: data
                }).then((resp) => {return resp.json();
                }).then(function(data) {
                    self.getTypes();
                    self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                    self.locationTypeTerm = '';
                });
            }
        },
        deleteType(type) {
            const data = new URLSearchParams();
            let idPlaceType = 'data[' + type.id_place_type + '][id_place_type]';
            let idBuildingManager = 'data[' + type.id_place_type + '][id_building_manager]';
            let name = 'data[' + type.id_place_type + '][name]';
            data.append('action', 'remove');
            data.append(idPlaceType, type.id_place_type);
            data.append(idBuildingManager, type.id_building_manager);
            data.append(name, type.name);
            let self = this;
            fetch('/api/v1/placetype', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.json();
            }).then(function(data) {
                self.getTypes();
                self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
            });
        },
        editType(type) {
            type.edit = false;
            const data = new URLSearchParams();
            let idPlaceType = 'data[' + type.id_place_type + '][id_place_type]';
            let idBuildingManager = 'data[' + type.id_place_type + '][id_building_manager]';
            let name = 'data[' + type.id_place_type + '][name]';
            data.append('action', 'edit');
            data.append(idPlaceType, type.id_place_type);
            data.append(idBuildingManager, type.id_building_manager);
            data.append(name, type.name);
            let self = this;
            fetch('/api/v1/placetype', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.json();
            }).then(function(data) {
                self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
            });
        },
        importplaces() {
            this.importLocationModalOpen = false;
            this.getPlaces();
        }
    }
}
</script>
