<template>
    <div>
        <div class="main-content">
            <div class="container">
                <div class="row">
                    <div class="col">
                        <div class="block block--owners" >
                            <section class="incident-head">
                                <h1>Beheerders</h1>
                                <span class="edit-button icon" @click="newPartnerModalOpen = true"><i class="fas fa-plus"></i></span>
                            </section>
                            <section class="search search-alt" v-if="managers.length">
                                <form @submit.prevent="searchSuggest()">
                                    <input type="text" ref="search" v-bind:placeholder="'Zoek een beheerder op naam'" @input="e => searchTerm = e.target.value" :value="searchTerm" v-on:keyup="searchSuggest($event)" class="no-margin" />
                                    <span class="close-suggestions" @click="clearSuggestions()" v-if="searchActive"><i class="fas fa-times"></i></span>
                                </form>
                            </section>
                            <div class="row">
                                <div class="col">
                                    <div class="filter-checkboxes">
                                        <div class="filter-checkbox-block">
                                            <div class="checkbox-indicator" @click="selectFilter('active')">
                                                <i class="far fa-check-square" v-if="checkboxes.active"></i>
                                                <i class="far fa-square" v-else></i>
                                            </div>
                                            <label @click="selectFilter('active')">Actief</label>
                                        </div>
                                        <div class="filter-checkbox-block">
                                            <div class="checkbox-indicator" @click="selectFilter('demo')">
                                                <i class="far fa-check-square" v-if="checkboxes.demo"></i>
                                                <i class="far fa-square" v-else></i>
                                            </div>
                                            <label @click="selectFilter('demo')">Demo</label>
                                        </div>
                                        <div class="filter-checkbox-block">
                                            <div class="checkbox-indicator" @click="selectFilter('noDemo')">
                                                <i class="far fa-check-square" v-if="checkboxes.noDemo"></i>
                                                <i class="far fa-square" v-else></i>
                                            </div>
                                            <label @click="selectFilter('noDemo')">Geen Demo</label>
                                        </div>
                                        <div class="filter-checkbox-block">
                                            <div class="checkbox-indicator" @click="selectFilter('limit')">
                                                <i class="far fa-check-square" v-if="checkboxes.limit"></i>
                                                <i class="far fa-square" v-else></i>
                                            </div>
                                            <label @click="selectFilter('limit')">Overschrijding</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="searchActive">
                                <h4 class="result-counter" v-if="searchSuggestions.length === 1">{{searchSuggestions.length}} {{'resultaat gevonden met de term'}} '{{searchTerm}}'</h4>
                                <h4 class="result-counter" v-if="!searchSuggestions.length">{{"Geen resultaten gevonden met de term"}} '{{searchTerm}}'.</h4>
                                <h4 class="result-counter" v-if="searchSuggestions.length > 1">{{searchSuggestions.length}} {{'resultaten gevonden met de term'}} '{{searchTerm}}'</h4>
                                <div v-for="manager in searchSuggestions" :key="manager.id_building_manager" class="list-item partner-list-item" @click="selectManager(manager.id_building_manager)">
                                    <span style="float: left;" v-tooltip.right="'Deze beheerder heeft meer objecten dan toegestaan'" v-if="manager.stickers > manager.paid_amount_assets"> &#x2757; </span>
                                    <b style="float: left; margin-left: 20px;">{{ manager.name}}</b>
                                    <span class="active-label" v-if="manager.active" style="top: 0; right: 0; font-size: 13px; float: right; position: relative;">Actief</span>
                                    <span class="active-label" v-if="manager.demo_account" style="top: 0;background: orange; font-size: 13px; float: right; position: relative;">Demo</span>
                                    <span v-if="manager.stickers" style="display: block; margin-left:5px; float:left; color: gray;">| Objecten: {{manager.stickers}} uit maximaal {{ manager.paid_amount_assets }}</span>
                                </div>
                            </div>
                            <div v-else style="height: 600px; overflow: auto; padding-right: 10px; margin-top: 10px;">
                                <h4 class="result-counter">{{managersShown.length}} {{'Beheerders'}}</h4>
                                <div class="list-item partner-list-item" v-for="(manager, index) in managersShown" :key="index" style="border: 1px solid #dbdbdb; margin-bottom: 10px;" @click="selectManager(manager.id_building_manager)"> 
                                    <span style="float: left;" v-tooltip.right="'Deze beheerder heeft meer objecten dan toegestaan'" v-if="manager.stickers > manager.paid_amount_assets"> &#x2757; </span>
                                    <b style="float: left; margin-left: 20px;">{{ manager.name }} </b>
                                    <span class="active-label" v-if="manager.active" style="top: 0; right: 0; font-size: 13px; float: right; position: relative;">Actief</span>
                                    <span class="active-label" v-if="manager.demo_account" style="top: 0;background: orange; font-size: 13px; float: right; position: relative;">Demo</span>
                                    <span v-if="manager.stickers" style="display: block; margin-left:5px; float:left; color: gray;">| Objecten: {{manager.stickers}} uit maximaal {{ manager.paid_amount_assets }} </span>
                                </div>
                            </div>                          
                        </div>
                    </div>
                </div>
            </div>    
        </div>
        <S1moneHeader :managerWarnings="managerWarnings"/>
        <NewPartnerModal v-if="newPartnerModalOpen" @close="loadManagers()" />
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale-mechanic.js';
import NewPartnerModal from "@/views/s1mone/modals/NewPartnerModal.vue";
import S1moneHeader from '../../components/headers/S1moneHeader.vue';

export default {
    mixins: [setLocale],
    components: {
        NewPartnerModal,
        S1moneHeader
    },
    data() {
        return {
            searchTerm: '',
            searchSuggestions: [],
            managers: [],
            managersShown: [],
            managerId: null,
            searchActive: false,
            newPartnerModalOpen: false,
            managerWarnings: 0,
            checkboxes: {
                active: false,
                demo: false,
                noDemo: false,
                limit: false
            }
        }
    },
    created() {
        document.title = 'S1MONE | Partners';
        if(localStorage.getItem('S1-SIMONE-checkboxes')){
            this.checkboxes = JSON.parse(localStorage.getItem('S1-SIMONE-checkboxes'));
        }
        this.loadManagers();
    },
    methods: {
        setManagerWarnings() {
            for (var i = 0; i < this.managers.length; i++) {
                if(this.managers[i].stickers > this.managers[i].paid_amount_assets){
                    this.managerWarnings += 1;
                }
            }
        },
        loadManagers() {
            this.newPartnerModalOpen = false;
            let self = this;
            fetch('/api/v1/buildingmanager/s1monedashboard')
            .then(response => { response.json().then(
                function(data) {
                    self.managers = data.data;
                    self.managersShown = data.data;
                    self.setManagerWarnings();
                    self.activateFilters();
                });
            });
        },
        loadDetailsManager(){
            let self = this;
            for (var i = 0; i < this.managers.length; i++) {
                let managerID = self.managers[i].id_building_manager;
                fetch('/api/v1/buildingmanager/byid?id_building_manager=' + managerID)
                .then(response => { response.json().then(
                    function(data) {
                    });
                });
            }
        },
        searchSuggest($event) {
            this.searchSuggestions = [];
            let searchTerm = this.searchTerm.toLowerCase();
            if (searchTerm.length > 2) {
                this.searchActive = true;
                for (var i = 0; i < this.managersShown.length; i++) {
                    let manager = this.managersShown[i];
                    let managerName = manager.name.toLowerCase();
                    if (managerName.includes(searchTerm)) {
                        this.searchSuggestions.push(manager);
                    }
                }
            }
            if (searchTerm.length === 0) {
                this.searchActive = false;
            }
            if($event && $event.keyCode === 27) {
                this.searchActive = false;
                this.searchTerm = '';
                this.searchSuggestions = [];
            }
        },
        clearSuggestions() {
            this.searchSuggestions = [];
            this.searchTerm = '';
            this.searchActive = false;
        },
        selectManager(id) {
            this.$router.push('/s1mone/buildingmanager-detail?id=' + id);
        },
        selectFilter(type) {
            if (type === 'active') {
                if (this.checkboxes.active) {
                    this.managersShown = this.managers;
                    if (this.checkboxes.demo) {
                        this.managersShown = this.managersShown.filter(manager => manager.demo_account);
                    }
                    if (this.checkboxes.noDemo) {
                        this.managersShown = this.managersShown.filter(manager => !manager.demo_account);
                    }
                    if (this.checkboxes.limit) {
                        this.managersShown = this.managersShown.filter(manager => manager.stickers > manager.paid_amount_assets);
                    }
                }
                else {
                    this.managersShown = this.managersShown.filter(manager => manager.active);
                }
                this.checkboxes.active = !this.checkboxes.active;
                if(this.searchActive){
                    this.searchSuggest();
                }
            }
            if (type === 'demo') {
                if (this.checkboxes.demo) {
                    this.managersShown = this.managers;
                    if (this.checkboxes.active) {
                        this.managersShown = this.managersShown.filter(manager => manager.active);
                    }
                    if (this.checkboxes.limit) {
                        this.managersShown = this.managersShown.filter(manager => manager.stickers > manager.paid_amount_assets);
                    }
                }
                else {
                    if(this.checkboxes.noDemo){
                        this.selectFilter('noDemo');
                    }
                    this.managersShown = this.managersShown.filter(manager => manager.demo_account);
                }
                this.checkboxes.demo = !this.checkboxes.demo;
                if(this.searchActive){
                    this.searchSuggest();
                }
            }
            if (type === 'noDemo') {
                if (this.checkboxes.noDemo) {
                    this.managersShown = this.managers;
                    if (this.checkboxes.active) {
                        this.managersShown = this.managersShown.filter(manager => manager.active);
                    }
                    if (this.checkboxes.limit) {
                        this.managersShown = this.managersShown.filter(manager => manager.stickers > manager.paid_amount_assets);
                    }
                }
                else {
                    if(this.checkboxes.demo){
                        this.selectFilter('demo');
                    }
                    this.managersShown = this.managersShown.filter(manager => !manager.demo_account);
                }
                this.checkboxes.noDemo = !this.checkboxes.noDemo;
                if(this.searchActive){
                    this.searchSuggest();
                }
            }
            if (type === 'limit') {
                if (this.checkboxes.limit) {
                    this.managersShown = this.managers;
                    if (this.checkboxes.active) {
                        this.managersShown = this.managersShown.filter(manager => manager.active);
                    }
                    if (this.checkboxes.demo) {
                        this.managersShown = this.managersShown.filter(manager => manager.demo_account);
                    }
                    if (this.checkboxes.noDemo) {
                        this.managersShown = this.managersShown.filter(manager => !manager.demo_account);
                    }
                }
                else {
                    this.managersShown = this.managersShown.filter(manager => manager.stickers > manager.paid_amount_assets);
                }
                this.checkboxes.limit = !this.checkboxes.limit;
                if(this.searchActive){
                    this.searchSuggest();
                }
            }
            localStorage.setItem('S1-SIMONE-checkboxes', JSON.stringify(this.checkboxes));
        },
        activateFilters(){
            console.log(this.managersShown);
            if(this.checkboxes.active){
                this.managersShown = this.managersShown.filter(manager => manager.active);
            }
            if(this.checkboxes.demo){
                this.managersShown = this.managersShown.filter(manager => manager.demo_account);
            }
            if(this.checkboxes.noDemo){
                this.managersShown = this.managersShown.filter(manager => !manager.demo_account);
            }
            if(this.checkboxes.limit){
                this.managersShown = this.managersShown.filter(manager => manager.stickers > manager.paid_amount_assets);
            }
        }
    }
}
</script>
