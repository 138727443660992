<template>
  <header v-if="profile.nav">
    <v-idle
        @idle="onidle"
        :loop="true"
        :duration="14400"
        style="display: none;"
    />
    <div class="container">
        <div @click="closeNav()">
            <router-link tag="a" to="/supplier" class="logo"><img src="@/assets/img/logo.png" /></router-link>
        </div>
        <nav class="d-none d-md-block d-lg-block" :class="{active: mobileNavToggle}" @click="openNav()">
            <router-link tag="a" data-testid="tabSupplier" to="/supplier">{{profile.nav.dashboard}}</router-link>
            <router-link tag="a" data-testid="tabSpecialist" to="/supplier/specialists">{{profile.nav.mechanics}}</router-link>
        </nav>
        <section class="utils">
            <span class="chat-toggle" @click="showUnreadChats = !showUnreadChats" :class="{active: showUnreadChats}" v-if="unreadChats.length"><i class="fas fa-comment"></i><b v-if="totalUnread">{{totalUnread}}</b></span>
            <span class="util util--language" data-testid="tabLanguage" v-if="langSwitch"><img v-if="locale === 'nl'" src="@/assets/icons/dutch.svg" /><img v-if="locale === 'en'" src="@/assets/icons/english.svg" />
                <ul class="dropdown-nav">
                    <span class="util util--language" data-testid="specialistTabDutch" v-if="locale === 'en'" @click="setLang('nl')"><img src="@/assets/icons/dutch.svg" /></span>
                    <span class="util util--language" data-testid="specialistTabEnglish" v-if="locale === 'nl'" @click="setLang('en')"><img src="@/assets/icons/english.svg" /></span>
                </ul>
            </span>
            <span class="util util--user" @click="showUtils = !showUtils"><i class="fas fa-user"></i></span>
            <ul class="dropdown-nav large" v-show="showUtils">
                <router-link tag="li" data-testid="tabAccount" to="/supplier/account" @click.native="closeNav()">{{profile.nav.account.account}}</router-link>
                <router-link tag="li" data-testid="tabNews" to="/supplier/news" @click.native="closeNav()">{{profile.nav.news}}</router-link>
                <li><a href="/api/v1/logout" data-testid="tabLogout" style="display: block; width: 47px; text-decoration: none; color: #212529;">{{profile.nav.account.logout}}</a></li>
            </ul>
        </section>
        <div class="chats-window" v-if="showUnreadChats">
            <i class="fa fa-times" @click="showUnreadChats = false" style="position: absolute; right: 10px; top: 10px; cursor: pointer; font-size: 16px;"></i>
            <h3>{{profile.dashboard.building_manager.messages}}</h3>
            <p v-if="!totalUnread" style="padding-left: 10px; margin-bottom: 10px;">{{profile.dashboard.building_manager.messages_expla}}</p>
            <div class="unread-chats">
                <div class="unread-chat" v-for="(chat, index) in unreadChats" :key="index" v-show="chat.body || chat.media_url">
                    <div @click="toIncident(chat, true, false)">
                        <div class="labels">
                            <span class="incident-id">{{chat.id_incident}}</span>
                            <div class="current-user"><i class="fas fa-user"></i> {{chat.assigned_to_name}}</div>
                        </div>
                        <div class="chat-item" v-if="chat.body ||chat.media_url">
                            <span class="rep-name" v-if="chat.reporter_name">{{chat.reporter_name}}</span>
                            <span class="rep-name" v-if="chat.servicedesk_name">{{chat.servicedesk_name}}</span>
                            <span class="role-label" v-if="chat.reporter_name">{{profile.incident_detail.incident_reporter}}</span>
                            <span class="role-label" v-if="chat.servicedesk_name">{{profile.incidents.incidenttable.client}}</span>
                            <div class="message-body">
                                <div v-if="chat.media_url">
                                    <img :src="chat.media_url" v-if="chat.media_content_type && chat.media_content_type.includes('image/')" style="max-width: 50px; max-height: 50px; border-radius: 5px;" />
                                    <video v-if="chat.media_content_type === 'video/mp4'" style="max-width: 50px; max-height: 50px; border-radius: 5px;" autoplay loop muted><source :src="chat.media_url" type="video/mp4"></video>
                                </div>
                                <p v-else>{{chat.body}}</p><i>•</i><span class="timedate" v-if="chat.date">{{chat.date}}</span>
                            </div>
                            <span class="setasread" @click.stop="setwhatsappasread(chat)"><img src="@/assets/icons/checkchat.svg"></span>
                            <span class="counter"><b>{{chat.count}}</b></span>
                        </div>
                    </div>
                    <span class="new-window" @click="toIncident(chat, true, true)"><img src="@/assets/icons/open.svg"></span>
                </div>
            </div>
        </div>
        <span class="open-nav d-block d-md-none" @click="openNav()" v-if="!mobileNavToggle"><i class="fas fa-bars"></i></span>
        <span class="open-nav d-block d-md-none" @click="openNav()" v-if="mobileNavToggle"><i class="fa fa-times"></i></span>
        <NewsModal v-if="news && (news.for_role === 'ALL' || news.for_role === 'REPAIRCOMPANY')" @close="newsRead()" :news="news" />
    </div>
  </header>
</template>

<script>
import { EventBus } from '@/js/event-bus.js';
import uniqBy from 'lodash/uniqBy';
import sortBy from 'lodash/sortBy';
import NewsModal from "@/components/modals/NewsModal.vue";
export default {
    components: {
        NewsModal
    },
    data: function () {
        return {
            mobileNavToggle: false,
            industry: '',
            profile: {},
            locale: '',
            langSwitch: true,
            showUtils: false,
            showUnreadChats: false,
            unreadChats: [],
            totalUnread: 0,
            news: null,
            newsInterval: null
        }
    },
    created() {
        this.getNews();
        let self = this;
        fetch('/api/v1/repaircompanystats')
        .then(response => { response.json().then(
            function(data) {
                let industryId = data.repair_company.industry_constant;
                if (!industryId) {
                    industryId = "HOUSING";
                }
                if (industryId === 'LEASING') {
                    self.industry = 'leasing';
                }
                if (industryId === 'HOUSING' || industryId === 'VVEMANAGEMENT') {                    
                    self.industry = 'housing';
                }
                if (industryId === 'FACILITY') {
                    self.industry = 'facility';
                    self.langSwitch = false;
                }
                localStorage.setItem('S1-industry', self.industry);
                let locale = localStorage.getItem('S1-locale');
                if (locale === null) {
                    locale = 'nl';
                }
                self.locale = locale;
                self.profile = require('../../assets/locales/' + self.locale + '/' + self.industry + '.json');
                self.showUnread();
            });
        });
        setTimeout(function () {
            if (!this.industry) {
                window.location = '/signin.html';
            }
        }.bind(this), 2000);
        EventBus.$on('read', prop => {
            this.showUnread();
        });
        this.$root.$on('appendmessage', (payload) => {
            let self = this;
            self.appendMessage(payload);
        });
        this.newsInterval = setInterval(function () {
            this.getNews();
        }.bind(this), 1800000);
    },
    methods: {
        getUrlParameter(name) {
            return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.search)||[""])[1].replace(/\+/g, '%20'))||null;
        },
        onidle() {
            window.location = '/signin.html?timeout=true';
        },
        openNav() {
            this.mobileNavToggle = !this.mobileNavToggle;
            let el = document.body;
            if (this.mobileNavToggle) {
                el.classList.add("modal-open");
            }
            if (!this.mobileNavToggle) {
                el.classList.remove("modal-open");
            }
        },
        closeNav() {
            this.mobileNavToggle = false;
            this.showUtils = false;
            let el = document.body;
            el.classList.remove("modal-open");
        },
        setLang(lang) {
            this.locale = lang;
            if (this.industry === 'vvemanagement') {
                this.industry = 'housing';
            }
            let busObj = {
                locale: lang,
                industry: this.industry
            }
            localStorage.setItem('S1-locale', lang);
            EventBus.$emit('langChange', busObj);
            this.profile = require('../../assets/locales/' + this.locale + '/' + this.industry + '.json');
        },
        getNews() {
            let self = this;
            fetch('/api/v1/userandrepaircompany')
            .then(response => { response.json().then(
                function(data) {
                    self.news = data.new_news[0];
                });
            });
        },
        ISODateString(d) {
            function pad(n) { return n < 10 ? '0' + n : n }
            return pad(d.getDate()) + '/' + pad(d.getMonth() + 1) + '/' + d.getFullYear()+" "+d.getHours()+":"+pad(d.getMinutes());
        },
        pad(n) {
            return n < 10 ? '0' + n : n
        },
        showUnread() {
            let self = this;
            fetch('/api/v1/chat/unreadall')
            .then(response => { response.json().then(
                function(data) {
                    self.unreadChats = [];
                    self.totalUnread = 0;
                    let reporterChats = data.reporter;
                    if (reporterChats.length) {
                        for (var i = 0; i < reporterChats.length; i++) {
                            let item = reporterChats[i];
                            let dateSent = new Date(item.created);
                            let month = dateSent.getMonth() + 1;
                            month = month.toString();
                            let dateStr = dateSent.getDate() + " " + self.profile.months_numbered[month]  + ", " + dateSent.getHours() + ":"+self.pad(dateSent.getMinutes());
                            item.date = dateStr;
                            item.channel = 'reporter';
                            self.unreadChats.push(item);
                        }
                    }
                    let serviceDeskChats = data.servicedesk;

                    if (serviceDeskChats.length) {
                        for (var i = 0; i < serviceDeskChats.length; i++) {
                            let item = serviceDeskChats[i];
                            let dateSent = new Date(item.created);
                            let month = dateSent.getMonth() + 1;
                            month = month.toString();
                            let dateStr = dateSent.getDate() + " " + self.profile.months_numbered[month]  + ", " + dateSent.getHours() + ":"+self.pad(dateSent.getMinutes());
                            item.date = dateStr;
                            item.channel = 'servicedesk';
                            self.unreadChats.push(item);
                        }
                    }
                    if (self.unreadChats.length) {
                        self.unreadChats = uniqBy(self.unreadChats, 'id_incident');
                        for (var k = 0; k < self.unreadChats.length; k++) {
                            let item = self.unreadChats[k];
                            if (item.body || item.media_content_type) {
                                let totalCount = item.count;
                                if (item.ownerCount && item.body !== item.ownerBody) {
                                    totalCount = totalCount + item.ownerCount;
                                }
                                self.totalUnread = self.totalUnread + totalCount;
                            }
                        }
                    }
                    self.unreadChats = sortBy(self.unreadChats, 'created');
                });
            });
        },
        toIncident(incident, openChat, newWindow) {
            let openChatParam = '';
            let currentIncidentId = '';
            if (window.location.href.includes('id=')) {
                currentIncidentId = this.getUrlParameter('id');
            }
            if (openChat) {
                if (incident.type === 'whatsapp_incoming_reporter') {
                    openChatParam = 'reporterChat';
                }
                if (incident.type === 'service_desk_to_service_desk' || incident.type === 'firestore' || incident.type === 'service_desk_to_supplier') {
                    openChatParam = 'servicedeskChat';
                }
            }
            if (newWindow) {
                if (currentIncidentId && currentIncidentId !== incident.id_incident) {
                    const routeData = this.$router.resolve({path: '/supplier/incident-detail', query: {id: incident.id_incident, openchat: openChatParam}});
                    window.open(routeData.href, '_blank');
                }
                if (!currentIncidentId) {
                    const routeData = this.$router.resolve({path: '/supplier/incident-detail', query: {id: incident.id_incident, openchat: openChatParam}});
                    window.open(routeData.href, '_blank');
                }
            }
            else {
                if (currentIncidentId && currentIncidentId !== incident.id_incident.toString()) {
                    this.$router.push('/supplier/incident-detail?id=' + incident.id_incident + '&openchat=' + openChatParam);
                    this.showUnreadChats = false;
                }
                if (!currentIncidentId) {
                    this.$router.push('/supplier/incident-detail?id=' + incident.id_incident + '&openchat=' + openChatParam);
                    this.showUnreadChats = false;
                }
            }
        },
        setwhatsappasread(chat) {
            let type;
            if (chat.type === 'whatsapp_incoming_reporter') {
                type = 'reporter';
            }
            if (chat.type === 'service_desk_to_service_desk' || chat.type === 'firestore' || chat.type === 'service_desk_to_supplier') {
                type = 'servicedesk';
            }
            const data = new URLSearchParams();
            data.append('id_incident', chat.id_incident);
            data.append('type', type);
            let self = this;
            fetch('/api/v1/chat/setwhatsappasread', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.json();
            }).then(function(data) {
                EventBus.$emit('refreshChat', true);
                setTimeout(function() {
                    self.showUnread();
                }.bind(self), 500);
            });
        },
        newsRead() {
            this.news = false;
            fetch('/api/v1/newsmessage/hasread', {
                method: 'post'
            }).then((resp) => {return resp.json();
            }).then(function(data) {});
        }
    }
}
</script>
