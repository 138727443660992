<template>
    <div class="modal-wrapper" v-if="profile.incident_detail">
        <div class="modal">
            <div class="modal-header">
                <h3 v-if="vve"><i class="fas fa-history"></i>{{profile.incident_detail.incidents_owner_vve}}</h3>
                <h3 v-else><i class="fas fa-history"></i>{{profile.incident_detail.incidents_owner}}</h3>
                <img src="@/assets/img/logo-white.png" class="logo" />
            </div>
            <div class="modal-inner">
                <span class="close" data-testid="historyClose" @click="closeModal()"><i class="fas fa-times"></i></span>
                <table class="table table-striped">
                    <thead>
                        <tr>
                            <th scope="col">{{profile.incident_detail.number}}</th>
                            <th scope="col">{{profile.dashboard.building_manager.message_table.date}}</th>
                            <th scope="col">{{profile.incident_detail.category}}</th>
                            <th scope="col">{{profile.incidents.incidenttable.description}}</th>
                            <th scope="col">{{profile.incidents.incidenttable.status}}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in incidents" :key="item.id_incident" @click="toIncident(item.id_incident)">
                            <td data-testid="activeIdText">{{item.id_incident}}</td>
                            <td data-testid="activeDateText">{{item.date}} {{item.time}}</td>
                            <td data-testid="activeCategoryText" v-if="locale === 'nl'">{{item.dutch_name}}</td>
                            <td data-testid="activeCategoryEnText" v-if="locale === 'en'">{{item.english_name}}</td>
                            <td data-testid="activeDescriptionText">{{item.incident_description}}</td>
                            <td data-testid="activeStatusText" v-if="!profile['statusses'][item.status].buildingmanager">{{profile['statusses'][item.status]}}</td>
                            <td data-testid="activeStatusText2" v-if="profile['statusses'][item.status].buildingmanager">{{profile['statusses'][item.status].buildingmanager}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale.js';
import moment from 'moment';

export default {
    mixins: [setLocale],
    data() {
        return {
            profile: {},
            incidents: []
        }
    },
    props: ['incidentInfo', 'vve'],
    created() {
        document.querySelector('body').classList.add('modal-open');
        let self = this;
        fetch('/api/v1/incidentcombined/openincidentsowner?id_incident=' + this.incidentInfo.id_incident)
        .then(response => { response.json().then(
            function(data) {
                console.log(data);
                for (var i = 0; i < data.length; i++) {
                    let item = data[i];
                    item.date = moment(item.created).locale('nl').format('D MMMM YYYY');
                    item.time = moment(item.created).locale('nl').format('LT');
                    self.incidents.push(item);
                }
            });
        });
        document.onkeydown = function(evt) {
            evt = evt || window.event;
            if (evt.keyCode == 27) {
                self.closeModal();
            }
        };
    },
    methods: {
        closeModal() {
            this.$emit('close');
            document.querySelector('body').classList.remove('modal-open');
        },
        toIncident(id) {
            this.$emit('close');
            this.$router.replace({ path: '/buildingmanager/incident-detail', query: { id: id} });
        }
    }
}
</script>
