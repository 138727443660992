<template>
    <div class="modal-wrapper" v-if="profile.places">
        <div class="modal">
            <div class="modal-header">
                <h3><i class="fas fa-photo-video"></i> {{profile.invoicing.add_quotation}}</h3>
                <img src="@/assets/img/logo-white.png" class="logo" />
            </div>
            <div class="modal-inner">
                <span class="close" data-testid="commissionOfferClose" @click="closeModal()"><i class="fas fa-times"></i></span>
                <p style="margin-bottom: 20px;">{{profile.invoicing.upload_expla}}</p>
                <div class="row" v-if="incidentInfo.quotation_value_supplier || incidentInfo.quotation_number_supplier">
                    <div class="col-sm-6">
                        <label>{{profile.invoicing.quotation_amount}}</label>
                        <p style="font-size: 16px; margin-bottom: 10px;">€ {{incidentInfo.quotation_value_supplier}}</p>
                    </div>
                    <div class="col-sm-6" v-if="incidentInfo.quotation_number_supplier">
                        <label>{{profile.invoicing.quotation_number}}</label>
                        <p style="font-size: 16px; margin-bottom: 10px;">{{incidentInfo.quotation_number_supplier}}</p>
                    </div>
                </div>
                <form v-on:submit.prevent="uploadMedia()" v-if="!loading">
                    <div class="row">
                        <div class="col-sm-6">
                            <div class="form-field focus">
                                <label>{{profile.accountPage.account.amount}} *</label>
                                <input type="text" class="form-control" data-testid="commissionOfferAmount" v-model="fields.amount" @keyup="validateAmount()" required>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-field focus">
                                <label>{{profile.invoicing.quotation_number}} *</label>
                                <input type="text" class="form-control" data-testid="commissionOfferNumber" v-model="fields.number" required>
                            </div>
                        </div>
                    </div>
                    <div class="row" v-if="violationAmount">
                        <div class="col">
                            <label>{{ profile.mandates.violation_amount }}</label>
                            <p style="margin-bottom: 10px;">€ {{ violationAmount }}</p>
                        </div>
                    </div>
                    <div class="form-field-upload">
                        <input data-testid="commissionOfferUpload" type="file" @change="fileChange" multiple required />
                    </div>
                    <span class="notification notification--error" v-if="uploadError" style="margin-bottom: 10px;">{{profile.accountPage.account.upload_error}}</span>
                    <button type="submit" class="button button--icon" data-testid="commissionOfferSubmit"><i class="fas fa-file-pdf"></i>{{profile.invoicing.add_quotation}}</button>
                </form>
                <img src="@/assets/icons/loading.gif" v-if="loading" class="loader" />
            </div>
        </div>
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale.js';
export default {
    mixins: [setLocale],
    name: 'UploadQuotationModal',
    data() {
        return {
            fields: {
                amount: null,
                number: '',
                file: null,
            },
            uploadError: false,
            loading: false,
            violationAmount: null
        }
    },
    props: ['incidentId', 'incidentInfo', 'isSupplier'],
    created() {
        if (this.incidentInfo.quotation_value_supplier) {
            this.fields.amount = this.incidentInfo.quotation_value_supplier;
        }
        if (this.incidentInfo.quotation_number_supplier) {
            this.fields.number = this.incidentInfo.quotation_number_supplier;
        }
        document.querySelector('body').classList.add('modal-open');
        document.querySelector('.main-content').style.display = 'block';
        let self = this;
        document.onkeydown = function(evt) {
            evt = evt || window.event;
            if (evt.keyCode == 27) {
                self.closeModal();
            }
        };
        console.log(this.incidentInfo);
    },
    methods: {
        closeModal() {
            this.$emit('close');
            document.querySelector('body').classList.remove('modal-open');
        },
        fileChange(e) {
            this.fields.file = e.target.files || e.dataTransfer.files;
        },
        validateAmount() {
            this.fields.amount = this.fields.amount.replace(/[A-Za-z!@#$%^&*().]/g, '');
            let violationAmount = this.fields.amount - this.incidentInfo.mandate.amount;
            if (violationAmount > 0) {
                this.violationAmount = violationAmount;
            }
            else {
                this.violationAmount = null;
            }
        },
        uploadMedia() {
            this.uploadError = false;
            if (this.fields.file) {
                for (var i = 0; i < this.fields.file.length; i++) {
                    let file = this.fields.file[i];
                    if (file.type === 'application/pdf') {
                        this.loading = true;
                        const data = new FormData();
                        let filename = 'offerte-' + this.fields.number + '.pdf';
                        data.append('file', file);
                        data.append('filename', filename);
                        data.append('id_incident', this.incidentId);
                        data.append('filetype', 'quotation_pdf_supplier');
                        let self = this;
                        fetch('/api/v1/fileupload/uploadincidentpdf', {
                            method: 'post',
                            body: data
                        }).then((resp) => {return resp.json();
                        }).then(function(data) {
                            if (!data.url) {
                                self.uploadError = true;
                            }
                            else {
                                self.$store.dispatch('updateQuotationAdded', true);
                                self.saveQuotationAmount();
                            }
                        });
                    }
                    else {
                        this.uploadError = true;
                    }
                }
            }
            else {
                this.saveQuotationAmount()
            }
        },
        saveQuotationAmount() {
            const data = new URLSearchParams();
            data.append('id_incident', this.incidentId);
            this.fields.amount = this.fields.amount.toString().replaceAll(',', '.');
            if (this.violationAmount) {
                this.violateIncident();
            }
            data.append('quotation_value_supplier', this.fields.amount);
            data.append('quotation_number_supplier', this.fields.number);
            let self = this;
            fetch('/api/v1/order/supplierquotation', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.json();
            }).then(function(data) {
                self.loading = false;
                self.$vToastify.success(self.profile.places.upload_result_successes, self.profile.toast.changes_saved);
                self.closeModal();
                self.$emit('success');
            });
        },
        violateIncident() {
            const data = new URLSearchParams();
            data.append('id_incident', this.incidentInfo.id_incident);
            data.append('quotation_value_supplier', this.fields.amount);
            data.append('mandate_warning', 'quotation_amount');
            data.append('quotation_number_supplier', this.fields.number);
            let self = this;
            fetch('/api/v1/order/suppliermandatewarning', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.json();
            }).then(function(data) {
            });
        }
    }
}
</script>
