<template>
    <div>
        <div class="loading" v-if="loading"><img src="@/assets/icons/loading.gif" /></div>
        <div class="main-content" v-if="profile.statusses">
            <div class="container">
                <div class="row">
                    <div class="col">
                        <a class="back-link" @click="$router.go(-1)"><i class="fas fa-chevron-left"></i>{{profile.nav.back_link}}</a>
                    </div>
                </div>
                <MainBlock  :incidentInfo="incidentInfo"
                            :readOnly="readOnly"
                            :readyToFinish="readyToFinish"
                            :readyForRestart="readyForRestart"
                            :newIncident="newIncident"
                            :readyForTakeover="readyForTakeover"
                            :forwardedReadOnly="forwardedReadOnly"
                            :hideRestart="false"
                            v-if="incidentInfo.id_incident" />
                <div class="row">
                    <div class="col-lg-8">
                        <MediaDocs :incidentId="incidentId" :incidentInfo="incidentInfo" :media="media" :docs="docs" :mediacount="mediaCount" :movs="movs" :forwardedReadOnly="forwardedReadOnly" :readOnly="readOnly" @getIncidentInfo="getIncidentInfo()" />
                    </div>
                    <div class="col-lg-4">
                        <OtherDetails :incidentInfo="incidentInfo" :readOnly="readOnly" @getIncidentInfo="getIncidentInfo()" />
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-4" style="display: flex;">
                        <div class="block block--incident_detail location" style="width: 100%;">
                            <section class="incident-head" style="margin-bottom: 10px;">
                                <h1>{{profile.assets.assetmodal.place}}</h1>
                                <span class="label" v-if="incidentInfo.place_floor_name === 'Complex'" style="background: #424242; border-radius: 3px; padding: 3px 5px; font-size: 13px; color: white; font-weight: 500; float: right;">Complex</span>
                            </section>
                            <div class="row">
                                <div class="col">
                                    <a :href="mapsUrl" target="_blank">
                                        <img :src="mapsApiUrl" id="address-map" />
                                    </a>
                                </div>
                            </div>
                            <p data-testid="locationInfo"  style="margin-bottom: 0;">
                                <router-link data-testid="locationNameLink" tag="a" v-bind:to="'/owner/location-detail?id_place=' + incidentInfo.id_place">{{incidentInfo.place_name}}</router-link>
                                <!-- {{incidentInfo.place_name}} -->
                                {{incidentInfo.place_address}}<br />
                                {{incidentInfo.place_zipcode}} {{incidentInfo.place_city}}<br />
                                <span data-testid="locationFloor"  v-if="incidentInfo.place_floor_name !== 'Complex'">{{profile.incident_detail.floor_name}}: {{incidentInfo.place_floor_name}}</span>
                            </p>
                        </div>
                    </div>
                    <div class="col-sm-4" style="display: flex;">
                        <div class="block block--incident_detail location" style="width: 100%">
                            <section class="incident-head" style="margin-bottom: 10px;">
                                <h1>{{profile.incidents.new_incident.id_asset}}</h1>
                            </section>
                            <p><router-link tag="a" data-testid="assetNameLink" style="display: inline;" v-bind:to="'/owner/asset-detail?asset=' + incidentInfo.id_asset">{{incidentInfo.asset_name}}</router-link></p>
                            <!-- <p>{{incidentInfo.asset_name}}</p> -->
                            <span class="button button--neutral asset-history" data-testid="assetHistoryButton" @click="historyModalOpen = true"><i class="fas fa-history"></i>{{profile.incident_detail.asset_history}}</span>
                        </div>
                    </div>
                    <div class="col-sm-4" style="display: flex;">
                        <div class="block block--incident_detail location" style="width: 100%">
                            <section class="incident-head" style="margin-bottom: 10px;">
                                <h1>{{profile.parts.part_name}}</h1>
                            </section>
                            <p v-if="incidentInfo.id_owner">{{incidentInfo.owner_name}}</p>
                            <label v-if="owner.description">{{profile.owners.ownertable.description}}</label>
                            <p v-if="owner.description" style="margin-bottom: 65px;" v-html="owner.description"></p>
                        </div>
                    </div>
                </div>
                <Commission :incidentInfo="incidentInfo"
                            :readOnly="readOnly"
                            :preStepChoice="preStepChoice"
                            :hasRepairCompany="hasRepairCompany"
                            :preferredSuppliers="preferredSuppliers"
                            :selectedRepairCompanyId="selectedRepairCompanyId"
                            :selectedInvoiceParty="selectedInvoiceParty"
                            :owner="owner"
                            :currentAppointment="currentAppointment"
                            :currentAppointmentDate="currentAppointmentDate"
                            :repairInfo="repairInfo"
                            v-if="incidentInfo.id_repair_company"
                />
            </div>
        </div>
        <Chat :incidentInfo="incidentInfo" :readOnly="readOnly" @getIncidentInfo="getIncidentInfo()" ref="chatRef" v-if="incidentInfo.id_incident && showChat" />
        <HistoryModal v-if="historyModalOpen" :incidentInfo="incidentInfo" @close="historyModalOpen = false" />
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale-mechanic.js';
import { mapState } from 'vuex';
import moment from 'moment';
import sortBy from 'lodash/sortBy';
import MainBlock from '@/components/owner/MainBlock.vue';
import MediaDocs from '@/components/owner/MediaDocs.vue';
import Chat from '@/components/owner/Chat.vue';
import OtherDetails from '@/components/owner/OtherDetails.vue';
import Commission from '@/components/owner/Commission.vue';
import HistoryModal from "@/components/owner/modals/HistoryModal.vue";
import VueScrollTo from 'vue-scrollto';
import { EventBus } from '@/js/event-bus.js';

export default {
    mixins: [setLocale],
    components: {
        MainBlock,
        MediaDocs,
        Chat,
        OtherDetails,
        Commission,
        HistoryModal
    },
    data() {
        return {
            profile: {},
            selectedAsset: {},
            incidentId: 0,
            incidentInfo: {},
            owner: {},
            loading: true,
            mapsApiUrl: '',
            mapsUrl: '',
            repairCompanies: [],
            mechanics: [],
            mechanicsNoS1mone: [],
            appointmentModalOpen: false,
            historyModalOpen: false,
            hasRepairCompany: false,
            preferredSuppliers: [],
            selectedRepairCompanyId: null,
            hasMechanic: false,
            selectedMechanic: null,
            tempAdditionalMechanic: '',
            manualCheckIn: false,
            manualCheckOut: false,
            manualCheckInOut: false,
            weekend: false,
            media: [],
            movs: [],
            mediaCount: 0,
            docs: [],
            noStatusChange: false,
            newIncident: false,
            readyForTakeover: false,
            readOnly: false,
            forwardedReadOnly: false,
            readyToFinish: false,
            readyForRestart: false,
            repairInfo: {},
            feedbackTooltip: '',
            feedbackCount: 0,
            invoiceParty: {},
            selectedInvoiceParty: {},
            invoiceList: [],
            preStepChoice: '',
            currentAppointment: '',
            currentAppointmentDate: '',
            hideRestart: false,
            historyList: [],
            showChat: false
        }
    },
    computed: mapState(['mapsKey', 'invoices']),
    watch: {
        invoices() {
            this.invoiceList = this.invoices;
        }
    },
    created() {
        let queryString = window.location.search;
        this.incidentId = queryString.split('=')[1];
        this.getIncidentInfo();
        this.checkForChats();
        document.title = 'S1MONE | ' + this.incidentId;
        setTimeout(function () {
            document.body.scrollTop = document.documentElement.scrollTop = 0;
        }.bind(this), 100)

        if (localStorage.getItem('S1-historylist')) {
            this.historyList = JSON.parse(localStorage.getItem('S1-historylist'));
            let lastVisit = moment().format('DD/MM/YYYY HH:mm:ss');
            let datum;
            if (this.historyList.length === 10) {
                datum = this.historyList.map(function(e) { return e.created; }).sort()[0];
            }
            for (var i = 0; i < this.historyList.length; i++) {
                let item = this.historyList[i];
                if (item.id === this.incidentId) {
                    this.historyList.splice(i, 1);
                }
                if (datum && datum === item.created) {
                    this.historyList.splice(i, 1);
                }
            }
            let item = {
                id: this.incidentId,
                created: new Date(),
                date: lastVisit
            }
            this.historyList.push(item);
            this.historyList = sortBy(this.historyList, 'created').reverse();
            localStorage.setItem('S1-historylist', JSON.stringify(this.historyList));
            EventBus.$emit('history', this.historyList);
        }
        else {
            let lastVisit = moment().format('DD/MM/YYYY HH:mm:ss');
            let item = {
                id: this.incidentId,
                created: new Date(),
                date: lastVisit
            }
            this.historyList.push(item);
            this.historyList = sortBy(this.historyList, 'id');
            localStorage.setItem('S1-historylist', JSON.stringify(this.historyList));
            EventBus.$emit('history', this.historyList);
        }
    },
    methods: {
        track() {
            this.$gtag.pageview(this.$route);
        },
        getIncidentInfo(scrollCalendar, hideCalendar) {
            document.querySelector('body').classList.remove('modal-open');
            this.readyForTakeover = false;
            this.invoiceError = false;
            this.manualCheckIn = false;
            this.manualCheckOut = false;
            this.manualCheckInOut = false;
            this.removeFileModalOpen = false;
            this.repairInfo = {};
            this.currentAppointment = '';
            this.currentAppointmentDate = '';
            this.invoiceCity = '';
            this.newIncident = false;
            this.readOnly = false;
            let self = this;
            fetch('/api/v1/incidentcombined?id_incident=' + self.incidentId)
            .then(response => { response.json().then(
                function(data) {
                    data.day = moment(data.created).locale('nl').format('dddd');
                    data.date = moment(data.created).locale('nl').format('D MMMM YYYY');
                    data.time = moment(data.created).locale('nl').format('LT');
                    let created = moment(data.last_status_time).format('DD/MM/YYYY HH:mm:ss');
                    data.last_status_full =  moment(data.last_status_time).locale('nl').format('DD/MM/YYYY HH:mm:ss');
                    data.appointment_created_full =  moment(data.appointment_created).locale('nl').format('DD/MM/YYYY HH:mm:ss');
                    let now = moment().format('DD/MM/YYYY HH:mm:ss');
                    let difference = moment(now,"DD/MM/YYYY HH:mm:ss").diff(moment(created,"DD/MM/YYYY HH:mm:ss"));
                    let differenceObj = self.convertToDays(difference);
                    let differenceString = differenceObj.days + self.profile.datetime.days + ' ' + differenceObj.hours + self.profile.datetime.hours + ' ' +  differenceObj.minutes  + self.profile.datetime.minutes;
                    data.overtime = differenceString;
                    if (differenceObj.days < 1) {
                        data.overtimeStatus = 'green';
                    }
                    if (differenceObj.days > 1) {
                        data.overtimeStatus = 'orange';
                    }
                    if (differenceObj.days > 3) {
                        data.overtimeStatus = 'red';
                    }
                    if (data.start_time) {
                        var start = moment(data.start_time).format();
                        let startDay = moment(start).format('DD');
                        start = moment(data.start_time).locale('nl').format('D MMMM, H:mm');
                        var date = moment(data.start_time).locale('nl').format('D MMMM');
                        var end = moment(data.end_time).format();
                        let endDay = moment(end).format('DD');
                        let endDayDate = moment(end).locale('nl').format('D MMMM');
                        end = moment(data.end_time).format('H:mm');
                        self.currentAppointmentDate = date;
                        if (startDay === endDay) {
                            self.currentAppointment = start + ' - ' + end;
                        }
                        else {
                            self.currentAppointment = start + ' - ' + endDayDate + ' ' + end;
                        }
                    }
                    let gmapsAddress    = data.place_address.replace(/ /g , "+")+"+"+data.place_city.replace(/ /g , "+");
                    self.mapsApiUrl     = 'https://maps.googleapis.com/maps/api/staticmap?markers='+
                                           gmapsAddress+
                                            '&zoom=16&size=250x150&key=' + self.mapsKey;
                    self.mapsUrl        = "https://www.google.com/maps/place/"+gmapsAddress;
                    if (data.id_repair_company) {
                        self.hasRepairCompany = true;
                    }
                    else {
                        self.hasRepairCompany = false;
                    }
                    data.incident_description = data.incident_description.replace(/\r?\n/g, '<br>');
                    self.selectedRepairCompany = data.id_repair_company;
                    if (data.incidentCategory) {
                        self.selectedCategory = data.incidentCategory.id_incident_category;
                    }
                    if (data.place_floor_name === '0' && self.industry !== 'leasing') {
                        if (self.locale === 'nl') {
                            data.place_floor_name = 'Begane grond'
                        }
                        if (self.locale === 'en') {
                            data.place_floor_name = 'Ground floor'
                        }
                    }
                    if (data.place_floor_name === 'VVEVEST') {
                        if (self.locale === 'nl') {
                            data.place_floor_name = 'Niet van toepassing'
                        }
                        if (self.locale === 'en') {
                            data.place_floor_name = 'Not applicable'
                        }
                    }
                    if (data.incident_status === 'fixed_mechanic' || data.incident_status === 'fixed_agreed_reporter' || data.incident_status === 'fixed_agreed_auto' || data.incident_status === 'fixed_agreed_repaircompany' || data.incident_status=== 'closed') {
                        fetch('/api/v1/incidentcombined/repairinfo?id_incident=' + data.id_incident)
                        .then(response => { response.json().then(
                            function(data) {
                                var start = moment(data.start).format();
                                start = moment(data.start).format('D/MM/YYYY HH:mm');
                                var end = moment(data.end).format();
                                end = moment(data.end).format('D/MM/YYYY HH:mm');
                                var timeWorked = self.createWorkedString(data.timeWorked);
                                data.start = start;
                                data.end = end;
                                data.timeWorked = timeWorked;
                                self.repairInfo = data;
                            });
                        });
                    }
                    if (data.pictures) {
                        self.media = [];
                        self.movs = [];
                        self.docs = [];
                        let latestOrderRequestSupplier = null;
                        for (var i = 0; i < data.pictures.length; i++) {
                            let media = data.pictures[i];
                            if (self.locale === 'nl') {
                                media.date = moment(media.created).locale('nl').format('D MMM YYYY HH:mm:ss');
                            }
                            else {
                                media.date = moment(media.created).format('MMM D YYYY HH:mm:ss');
                            }
                            if ((!media.file_url.includes('.pdf') && media.content_type.toLowerCase() !== 'application/pdf') && media.content_type !== 'video/quicktime') {
                                media.popover = false;
                                media.rotate = 1;
                                if (media.type === 'incident_reporter') {
                                    media.order = 1;
                                }
                                if (media.type === 'incident_building_manager') {
                                    media.order = 2;
                                }
                                if (media.type === 'incident_mechanic') {
                                    media.order = 3;
                                }
                                media.selected = false;
                                if (!media.content_type) {
                                    media.content_type = 'image/jpeg';
                                }
                                self.media.push(media);
                                self.media = sortBy(self.media, 'order');
                            }
                            if (media.file_url.includes('.pdf') || media.content_type.toLowerCase() === 'application/pdf') {
                                let n = media.file_url.lastIndexOf('/');
                                let fileName = media.file_url.substring(n + 1);
                                media.pdf = true;
                                media.filename = fileName;
                                media.selected = false;
                                if (media.type === "order_request_supplier" ) {
                                    // Track the latest "order_request_supplier" object
                                    if (!latestOrderRequestSupplier || media.created > latestOrderRequestSupplier.created) {
                                        latestOrderRequestSupplier = media;
                                    }                                  
                                }
                                if (media.type !== 'order_request_supplier' && media.type !== 'invoice_pdf_supplier') {
                                    self.docs.push(media);
                                }      
                                if (media.type === 'quotation_pdf_supplier') {
                                    data.hasQuotation = true;
                                    data.quotationUrl = media.file_url;
                                }
                            }
                            if (media.content_type === 'video/quicktime') {
                                self.movs.push(media);
                            }
                        }
                        if (latestOrderRequestSupplier) {
                            self.docs.push(latestOrderRequestSupplier);
                        }
                    }
                    if (data.latest_fixed_agreed_repaircompany) {
                        data.latest_fixed_agreed_repaircompany_date = moment(data.latest_fixed_agreed_repaircompany).format('DD/MM/YYYY HH:mm:ss');
                    }
                    if (data.service_desk_suspended_until) {
                        data.service_desk_suspended_until_date = moment(data.service_desk_suspended_until).add(1, 'days').locale('nl').format('D MMMM YYYY');
                    }
                    self.selectedRepairCompanyId = data.id_repair_company;
                    if (data.canbe_id_repair_company === -1 || !data.canbe_id_repair_company) {
                        self.preStepChoice = 'other';
                    }
                    self.incidentInfo = data;
                    if (self.incidentInfo.reporter_phone.length < 4)  {
                        self.incidentInfo.reporter_phone = '';
                    }
                    if (self.id_mechanic) {
                        self.selectedMechanic = self.incidentInfo.id_mechanic;
                    }
                    else {
                        self.selectedMechanic = 0;
                    }
                    if (self.incidentInfo.teams.length) {
                        self.incidentInfo.team_id_team = self.incidentInfo.teams[0].id_team;
                        self.incidentInfo.team_name = self.incidentInfo.teams[0].name;
                    }
                    if (self.incidentInfo.quotation_value_supplier && self.incidentInfo.quotation_value_supplier.toString().includes('.')) {
                        self.incidentInfo.quotation_value_supplier = (Math.round(self.incidentInfo.quotation_value_supplier * 100) / 100).toFixed(2);
                    }
                    if (scrollCalendar) {
                        setTimeout(function () {
                            var options = {
                                offset: -60
                            }
                            let element = document.getElementById('calendar');
                            VueScrollTo.scrollTo(element, 200, options);
                        }.bind(this), 500);
                    }
                    self.loading = false;
                });
            });
        },

        createWorkedString(timeWorked) {
            var result;
            var days;
            var hours;
            var minutes;
            let daysStr;
            let hoursStr;
            let minutesStr;
            if (!days) {
                daysStr   = this.profile.datetime.days;
                hoursStr  = this.profile.datetime.hours;
                minutesStr = this.profile.datetime.minutes;
            }

            days = Math.floor(timeWorked/86400000);
            days = days < 0 ? 0 : days;

            timeWorked = timeWorked - days*86400000;
            hours = Math.floor(timeWorked/3600000);
            hours = hours < 0 ? 0 : hours;

            timeWorked = timeWorked - hours*3600000;
            minutes = Math.floor(timeWorked/60000);
            minutes = minutes < 0 ? 0 : minutes;

            result = days+daysStr+" ";
            result += hours+hoursStr+" ";
            result += minutes+minutesStr;

            return result;
        },
        convertToDays(milliSeconds){
            let days = Math.floor(milliSeconds/(86400 * 1000));
            milliSeconds -= days*(86400*1000);
            let hours = Math.floor(milliSeconds/(60 * 60 * 1000 ));
            milliSeconds -= hours * (60 * 60 * 1000);
            let minutes = Math.floor(milliSeconds/(60 * 1000));
            return {
                days, hours, minutes
            }
        },
        checkForChats() {
            this.mediaModalOpen = false;
            // Servicedesk chat
            let self = this;
            fetch('/api/v1/chat/byincident?id_incident='+this.incidentId+'&limit='+100 + '&type=owneruser_servicedesk', {
            }).then((resp) => {
                return resp.json();
            }).then(function(data) {
                if (data.messages.length) {
                    self.showChat = true;
                }
            });
        },
    }
}
</script>
