<template>
    <div class="modal-wrapper" v-if="profile.actionable">
        <div class="modal">
            <div class="modal-header">
                <h3><i class="fas fa-pen"></i>{{profile.actionable.edit_planned_list}}</h3>
                <img src="@/assets/img/logo-white.png" class="logo" />
            </div>
            <div class="modal-inner higher">
                <span class="close" @click="closeModal()"><i class="fas fa-times"></i></span>
                <h2>{{ actionList.planned_action_list_name }}</h2>
                <form>
                    <label>{{profile.incidents.new_incident.id_asset}}</label>
                    <p style="margin-bottom: 20px;">{{actionList.asset_name}}<br />{{actionList.address}} {{actionList.house_number}}, {{profile.incident_detail.floor_name}} {{actionList.floor_name}} <br />{{actionList.place_name}}</p>
                    <label>{{profile.actionable.actions}}</label>
                    <div style="margin-bottom: 20px; overflow: hidden; position: relative;" v-if="actionList.planned_actions.length">
                        <div v-for="action in actionList.planned_actions" :key="action.id_planned_action" style="text-align: center; width: 100%; overflow: hidden; position: relative;">
                            <p style="float: none; margin: 0; text-align: left; font-size: 14px;">{{action.name}}</p>
                            <span class="edit-button edit-button--small" @click="action.sure_remove = true" style="padding: 0; top: 0;" v-if="!action.sure_remove"><i class="far fa-trash-alt"></i></span>
                            <span class="edit-button edit-button--small" @click="action.sure_remove = false" style="padding: 0; top: 0;" v-else><i class="fas fa-times"></i></span>
                            <div class="notification notification--warning" style="width: 100%; margin: 5px 0 10px 0; padding-right: 10px;" v-if="action.sure_remove">
                                <p style="text-align: left;">{{profile.actionable.sure_remove_action}}</p>
                                <span class="button button--small" @click="removeAction(action)">{{profile.actionable.delete_actionable}}</span>
                            </div>
                        </div>
                    </div>
                    <div v-for="(group, index) in actionList.groups" :key="index" style="text-align: center; width: 100%;">
                        <h4 style="margin: 0 0 0 0; text-align: left; text-transform: capitalize; font-size: 14px;">{{group.group_name}}</h4>
                        <div style="padding-left: 25px; margin-bottom: 20px; overflow: hidden;">
                            <div v-for="action in group.planned_actions" :key="action.id_planned_action" style="text-align: center; width: 100%; position: relative; overflow: hidden;">
                                <p style="float: none; margin: 0; text-align: left; font-size: 14px; ">{{action.name}}</p>
                                <span class="edit-button edit-button--small" @click="action.sure_remove = true" style="padding: 0; top: 0;" v-if="!action.sure_remove"><i class="far fa-trash-alt"></i></span>
                                <span class="edit-button edit-button--small" @click="action.sure_remove = false" style="padding: 0; top: 0;" v-else><i class="fas fa-times"></i></span>
                                <div class="notification notification--warning" style="width: 100%; margin: 5px 0 10px 0; padding-right: 10px;" v-if="action.sure_remove">
                                    <p style="text-align: left;">{{profile.actionable.sure_remove_action}}</p>
                                    <span class="button button--small" @click="removeAction(action)">{{profile.actionable.delete_actionable}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <label>{{profile.actionable.latest_recording_date}}</label>
                            <p style="margin-bottom: 5px;">{{actionList.latestDate}}</p>
                            <flat-pickr v-model="actionList.latest_possible" class="form-control" :config="flatPickrDateConfig" :placeholder="profile.incident_detail.pick_date"></flat-pickr>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <label>{{profile.incident_detail.mechanic_comment}}</label>
                            <div class="dropdown-section">
                                <span class="dropdown" v-on:click="mechanicDropdownToggled = !mechanicDropdownToggled" :class="{active: mechanicDropdownToggled}">
                                    <span v-if="!actionList.id_mechanic && !selectedMechanic.id_mechanic">{{profile.incident_detail.select_a_mechanic}} *</span>
                                    <span v-if="actionList.id_mechanic || selectedMechanic.id_mechanic" class="selected">{{selectedMechanic.name}}{{actionList.mechanic_name}}</span>
                                    <i class="fas fa-chevron-down chevron"></i>
                                </span>
                                <div class="dropdown-list" v-if="mechanicDropdownToggled">
                                    <ul>
                                        <li v-for="mechanic in mechanics" v-on:click="selectMechanic(mechanic)" :key="mechanic.id_mechanic">
                                            <span>{{mechanic.name}}</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <span class="button button--icon button--right" @click="saveActionList()"><i class="fas fa-pen"></i>{{profile.actionable.edit_planned_list}}</span>
                            <span class="button button--icon button--right" @click="removeConfirm = true" style="margin-right: 15px;"><i class="far fa-trash-alt"></i>{{profile.actionable.remove_planned_list}}</span>
                            <div class="notification notification--warning" style="width: 100%; margin: 15px 0 0 0; padding-right: 10px;" v-if="removeConfirm">
                                <span class="close-notification" @click="removeConfirm = false"><i class="fas fa-times"></i></span>
                                <p>{{profile.actionable.delete_planned_sure}}</p>
                                <span class="button button--small" @click="removeActionList()">{{profile.actionable.remove_planned_list}}</span>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale.js';
import {Dutch} from 'flatpickr/dist/l10n/nl.js';
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import moment from 'moment';
import sortBy from 'lodash/sortBy';
import uniqBy from 'lodash/uniqBy';

export default {
    mixins: [setLocale],
    components: {
        flatPickr
    },
    props: ['selectedActionList', 'mechanics'],
    data() {
        return {
            profile: {},
            flatPickrDateConfig: {
                altInput: true,
                altFormat: "F j, Y",
                dateFormat: "Y-m-d",
            },
            mechanicDropdownToggled: false,
            selectedMechanic: {},
            removeConfirm: false,
            actionList: {}
        }
    },
    created() {
        this.actionList = this.selectedActionList;
        if (this.locale === 'nl') {
            this.flatPickrDateConfig.locale = Dutch;
            this.flatPickrDateConfig.altFormat = "j F, Y";
        }
        this.today = new Date();
        this.today = moment(this.today).format();
        this.today = this.today.split('T')[0];
        this.flatPickrDateConfig.minDate = this.today;
        let day = moment(this.actionList.latest_possible).format().split('T')[0];
        if (day < this.today) {
            this.actionList.latest_possible = this.today;
        }
        document.querySelector('body').classList.add('modal-open');
        let self = this;
        document.onkeydown = function(evt) {
            evt = evt || window.event;
            if (evt.keyCode == 27) {
                self.closeModal();
            }
        };
        if (this.actionList.planned_actions.length) {
            for (var i = 0; i < this.actionList.planned_actions.length; i++) {
                let action = this.actionList.planned_actions[i];
                action.trimmedName = action.name.toLowerCase();
                if (action.status === 'completed') {
                    this.actionList.planned_actions.splice(i, 1);
                }
            }
            for (var i = 0; i < this.actionList.planned_actions.length; i++) {
                let action = this.actionList.planned_actions[i];
                if (action.status === 'completed') {
                    this.actionList.planned_actions.splice(i, 1);
                }
            }
            this.actionList.planned_actions = sortBy(this.actionList.planned_actions, 'name');
            for (var j = 0; j < this.actionList.planned_actions.length; j++) {
                let action = this.actionList.planned_actions[j];
                if (action.group_name) {
                    action.group_name_trimmed = action.group_name.trim().toLowerCase();
                    this.actionList.groups.push({ 'group_name': action.group_name, 'group_name_trimmed': action.group_name_trimmed, 'planned_actions': []});
                }
                this.actionList.groups = uniqBy(this.actionList.groups, 'group_name_trimmed');
                this.actionList.groups = sortBy(this.actionList.groups, 'group_name_trimmed');
            }
        }
        for (var i = 0; i < this.actionList.groups.length; i++) {
            let group = this.actionList.groups[i];
            const targetKey = 'group_name_trimmed';
            const targetValue = group.group_name_trimmed;
            // Function to check for the key and value pair
            function hasKeyValue(obj) {
                return obj.hasOwnProperty(targetKey) && obj[targetKey] === targetValue;
            }
            // Filter out objects with the specific key-value pair
            let filteredArray =  this.actionList.planned_actions.filter(hasKeyValue);
            // Remove filtered objects from the source array
            const sourceArrayWithoutMatch =  this.actionList.planned_actions.filter(obj => !hasKeyValue(obj));
            this.actionList.planned_actions = sourceArrayWithoutMatch;
            group.planned_actions = filteredArray;
            group.planned_actions = sortBy(group.planned_actions, 'trimmedName');
        }
        this.actionList.groups = sortBy(this.actionList.groups, 'name');
    },
    methods: {
        closeModal() {
            this.$emit('close');
            document.querySelector('body').classList.remove('modal-open');
        },
        selectMechanic(mechanic) {
            this.mechanicDropdownToggled = false;
            this.actionList.id_mechanic = null;
            this.actionList.mechanic_name = null;
            this.selectedMechanic = mechanic;
        },
        removeAction(action) {
            const data = new URLSearchParams();
            data.append('id_planned_action', action.id_planned_action);
            let self = this;
            fetch('/api/v1/plannedaction/delete', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.json();
            }).then(function(data) {
                self.$vToastify.success(self.profile.toast.edited, self.profile.incident_detail.select_this_repaircompany);
                if (action.group_name) {
                    for (var i = 0; i < self.actionList.groups.length; i++) {
                        let group = self.actionList.groups[i];
                        if (group.group_name_trimmed === action.group_name_trimmed) {
                            for (var j = 0; j < group.planned_actions.length; j++) {
                                let groupAction = group.planned_actions[j];
                                if (groupAction.name === action.name) {
                                    group.planned_actions.splice(j, 1);
                                }
                            }
                        }
                    }
                }
                else {
                    for (var i = 0; i < self.actionList.planned_actions.length; i++) {
                        let plannedAction = self.actionList.planned_actions[i];
                        if (plannedAction.id_planned_action === action.id_planned_action) {
                            self.actionList.planned_actions.splice(i, 1);
                        }
                    }
                }      
            });
        },
        saveActionList() {
            let date = this.actionList.latest_possible = this.actionList.latest_possible.split(' ')[0];
            date = new Date(this.actionList.latest_possible);
            date.setHours(23, 59, 59);
            date = date.getTime();
            const data = new URLSearchParams();
            data.append('id_planned_action_list', this.actionList.id_planned_action_list);
            data.append('name', this.actionList.planned_action_list_name);
            if (this.actionList.id_mechanic) {
                data.append('id_mechanic', this.actionList.id_mechanic);
            }
            else {
                data.append('id_mechanic', this.selectedMechanic.id_mechanic);
            }
            data.append('latest_possible', date);
            let self = this;
            fetch('/api/v1/plannedactionlist/update', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.json();
            }).then(function(data) {
                self.$emit('success');
                self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                document.querySelector('body').classList.remove('modal-open');
            });
        },
        removeActionList() {
            const data = new URLSearchParams();
            data.append('id_planned_action_list', this.actionList.id_planned_action_list);
            let self = this;
            fetch('/api/v1/plannedactionlist/delete', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.json();
            }).then(function(data) {
                self.$emit('success');
                self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                document.querySelector('body').classList.remove('modal-open');
            });
        },
    }
}
</script>
