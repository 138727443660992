<template>
    <div class="modal-wrapper">
        <div class="modal">
            <div class="modal-header">
                <h3><i class="fas fa-plus"></i>Nieuw objecttype</h3>
                <img src="@/assets/img/logo-white.png" class="logo" />
            </div>
            <div class="modal-inner">
                <span class="close" @click="closeModal()"><i class="fas fa-times"></i></span>
                <form v-on:submit.prevent="addAssetType()">
                    <div class="row">
                        <div class="col">
                            <div class="form-field" :class="{focus:fields.display_name.length}">
                                <label>{{"Display naam"}} *</label>
                                <input type="text" ref="name" class="form-control" v-model="fields.display_name" required>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <div class="form-field" :class="{focus:fields.constant_name.length}">
                                <label>{{"Canonieke naam"}} *</label>
                                <input type="text" ref="name" class="form-control" v-model="fields.constant_name">
                            </div>
                        </div>
                    </div> 
                    <div class="dropdown-section">
                        <span class="dropdown" v-on:click="industriesDropdownToggle = !industriesDropdownToggle" :class="{active: industriesDropdownToggle}">
                            <span v-if="fields.selectedIndustryID" class="selected">{{getIndustryName(fields.selectedIndustryID)}}</span>
                            <i class="fas fa-chevron-down chevron"></i>
                        </span>
                        <div class="dropdown-list" v-if="industriesDropdownToggle" :class="{search: industries.length > 9}">
                            <ul>
                                <li v-for="industry in industries" v-on:click="selectIndustry(industry.id_industry)" :key="industry.id_industry">
                                    {{industry.display_name}}
                                </li>
                            </ul>
                        </div>
                    </div> 
                    <div class="dropdown-section">
                        <span class="dropdown" v-on:click="managersDropwdownToggle = !managersDropwdownToggle" :class="{active: managersDropwdownToggle}">
                            <span v-if="fields.selectedManagerID" class="selected">{{getManagerName(fields.selectedManagerID)}}</span>
                            <i class="fas fa-chevron-down chevron"></i>
                        </span>
                        <div class="dropdown-list" v-if="managersDropwdownToggle" :class="{search: managers.length > 9}">
                            <ul>
                                <li v-for="manager in managers" v-on:click="selectManager(manager.id_building_manager)" :key="manager.id_building_manager">
                                    {{manager.name}}
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6">
                            <div class="form-field" :class="{focus:fields.extra_description_a.length}">
                                <label> Extra beschrijving A</label>
                                <input type="text" ref="name" class="form-control" v-model="fields.extra_description_a">
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="form-field" :class="{focus:fields.extra_description_b.length}">
                                <label> Extra beschrijving B</label>
                                <input type="text" ref="name" class="form-control" v-model="fields.extra_description_b">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6">
                            <div class="form-field" :class="{focus:fields.extra_description_c.length}">
                                <label> Extra beschrijving C</label>
                                <input type="text" ref="name" class="form-control" v-model="fields.extra_description_c">
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="form-field" :class="{focus:fields.extra_description_d.length}">
                                <label> Extra beschrijving D</label>
                                <input type="text" ref="name" class="form-control" v-model="fields.extra_description_d">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6">
                            <div class="form-field" :class="{focus:fields.extra_description_e.length}">
                                <label> Extra beschrijving E</label>
                                <input type="text" ref="name" class="form-control" v-model="fields.extra_description_e">
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="form-field" :class="{focus:fields.extra_description_f.length}">
                                <label> Extra beschrijving F</label>
                                <input type="text" ref="name" class="form-control" v-model="fields.extra_description_f">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6">
                            <div class="form-field " :class="{focus:fields.extra_description_g.length}">
                                <label> Extra beschrijving G</label>
                                <input type="text" ref="name" class="form-control" v-model="fields.extra_description_g">
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="form-field" :class="{focus:fields.extra_description_h.length}">
                                <label> Extra beschrijving H</label>
                                <input type="text" ref="name" class="form-control" v-model="fields.extra_description_h">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6">
                            <div class="form-field" :class="{focus:fields.extra_description_i.length}">
                                <label> Extra beschrijving I</label>
                                <input type="text" ref="name" class="form-control" v-model="fields.extra_description_i">
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="form-field" :class="{focus:fields.extra_description_j.length}">
                                <label> Extra beschrijving J</label>
                                <input type="text" ref="name" class="form-control" v-model="fields.extra_description_j">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-3">
                            <div class="switch-wrapper" style="padding-bottom: 15px;">
                                <label class="switch" style="float: left;">
                                    <input type="checkbox" v-model="fields.is_document_a">
                                    <span class="slider round"></span>
                                </label>
                                <span style="font-weight: 500;">Is uploadable A?</span>
                            </div>
                        </div>
                        <div class="col-3">
                            <div class="switch-wrapper" style="padding-bottom: 15px;">
                                <label class="switch" style="float: left;">
                                    <input type="checkbox" v-model="fields.is_document_b">
                                    <span class="slider round"></span>
                                </label>
                                <span style="font-weight: 500;">Is uploadable B?</span>
                            </div>
                        </div>
                        <div class="col-3">
                            <div class="switch-wrapper" style="padding-bottom: 15px;">
                                <label class="switch" style="float: left;">
                                    <input type="checkbox" v-model="fields.is_document_c">
                                    <span class="slider round"></span>
                                </label>
                                <span style="font-weight: 500;">Is uploadable C?</span>
                            </div>
                        </div>
                        <div class="col-3">
                            <div class="switch-wrapper" style="padding-bottom: 15px;">
                                <label class="switch" style="float: left;">
                                    <input type="checkbox" v-model="fields.is_document_d">
                                    <span class="slider round"></span>
                                </label>
                                <span style="font-weight: 500;">Is uploadable D?</span>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-3">
                            <div class="switch-wrapper" style="padding-bottom: 15px;">
                                <label class="switch" style="float: left;">
                                    <input type="checkbox" v-model="fields.is_document_e">
                                    <span class="slider round"></span>
                                </label>
                                <span style="font-weight: 500;">Is uploadable E?</span>
                            </div>
                        </div>
                        <div class="col-3">
                            <div class="switch-wrapper" style="padding-bottom: 15px;">
                                <label class="switch" style="float: left;">
                                    <input type="checkbox" v-model="fields.is_document_f">
                                    <span class="slider round"></span>
                                </label>
                                <span style="font-weight: 500;">Is uploadable F?</span>
                            </div>
                        </div>
                        <div class="col-3">
                            <div class="switch-wrapper" style="padding-bottom: 15px;">
                                <label class="switch" style="float: left;">
                                    <input type="checkbox" v-model="fields.is_document_g">
                                    <span class="slider round"></span>
                                </label>
                                <span style="font-weight: 500;">Is uploadable G?</span>
                            </div>
                        </div>
                        <div class="col-3">
                            <div class="switch-wrapper" style="padding-bottom: 15px;">
                                <label class="switch" style="float: left;">
                                    <input type="checkbox" v-model="fields.is_document_h">
                                    <span class="slider round"></span>
                                </label>
                                <span style="font-weight: 500;">Is uploadable H?</span>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-3">
                            <div class="switch-wrapper" style="padding-bottom: 15px;">
                                <label class="switch" style="float: left;">
                                    <input type="checkbox" v-model="fields.is_document_i">
                                    <span class="slider round"></span>
                                </label>
                                <span style="font-weight: 500;">Is uploadable I?</span>
                            </div>
                        </div>
                        <div class="col-3">
                            <div class="switch-wrapper" style="padding-bottom: 15px;">
                                <label class="switch" style="float: left;">
                                    <input type="checkbox" v-model="fields.is_document_j">
                                    <span class="slider round"></span>
                                </label>
                                <span style="font-weight: 500;">Is uploadable J?</span>
                            </div>
                        </div>
                    </div>  
                    <button type="submit" class="button button--cta button--icon"><i class="fas fa-plus"></i> Objecttype toevoegen</button>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale.js';

export default {
    mixins: [setLocale],
    data() {
        return {
            profile: {},
            defaultCategoriesDropdownToggle: false,
            managersDropwdownToggle: false,
            industriesDropdownToggle: false,
            managers: [],
            industries: [],
            fields: {
                display_name: '',
                constant_name: '',
                selectedManagerID: '',
                selectedIndustryID: '',
                extra_description_a: '',
                extra_description_b: '',
                extra_description_c: '',
                extra_description_d: '',
                extra_description_e: '',
                extra_description_f: '',
                extra_description_g: '',
                extra_description_h: '',
                extra_description_i: '',
                extra_description_j: '',
                is_document_a : false,
                is_document_b : false,
                is_document_c : false,
                is_document_d : false,
                is_document_e : false,
                is_document_f : false,
                is_document_g : false,
                is_document_h : false,
                is_document_i : false,
                is_document_j : false,
            },
        }
    },
    created() {
        document.querySelector('body').classList.add('modal-open');
        let self = this;
        document.onkeydown = function(evt) {
            evt = evt || window.event;
            if (evt.keyCode == 27) {
                self.closeModal();
            }
        };
        this.loadManagers();
        this.loadIndustries();
    },
    methods: {
        closeModal() {
            this.$emit('close');
            document.querySelector('body').classList.remove('modal-open');
        },
        loadIndustries() {
            let self = this;
            fetch('/api/v1/industry?')
            .then(response => { response.json().then(
                function(data) {
                    self.industries = data.data;
                    self.selectIndustry(self.industries[0].id_industry);
                });
            });
        },
        loadManagers(){
            let self = this;
            fetch('/api/v1/buildingmanager?')
            .then(response => { response.json().then(
                function(data) {
                    self.managers = data.data;
                    self.selectManager(self.managers[0].id_building_manager);
                });
            });
        },
        getManagerName(id){
            const manager = this.managers.find(m => m.id_building_manager === id);
            return manager ? manager.name : '';
        },
        selectManager(managerID) {
            this.managersDropwdownToggle = false;
            this.fields.selectedManagerID = managerID;
        },
        getIndustryName(id){
            const industry = this.industries.find(i => i.id_industry === id);
            return industry ? industry.display_name : '';
        },
        selectIndustry(industryID) {
            this.industriesDropdownToggle = false;
            this.fields.selectedIndustryID = industryID;
        },
        addAssetType() {
            let self = this;
            const data = new URLSearchParams();
            data.append('action', 'create');
            data.append('data[0][display_name]', this.fields.display_name);
            data.append('data[0][constant_name]', this.fields.constant_name);
            data.append('data[0][id_industry]', this.fields.selectedIndustryID);
            data.append('data[0][id_building_manager]', this.fields.selectedManagerID);
            data.append('data[0][extra_description_a]', this.fields.extra_description_a);
            data.append('data[0][extra_description_b]', this.fields.extra_description_b);
            data.append('data[0][extra_description_c]', this.fields.extra_description_c);
            data.append('data[0][extra_description_d]', this.fields.extra_description_d);
            data.append('data[0][extra_description_e]', this.fields.extra_description_e);
            data.append('data[0][extra_description_f]', this.fields.extra_description_f);
            data.append('data[0][extra_description_g]', this.fields.extra_description_g);
            data.append('data[0][extra_description_h]', this.fields.extra_description_h);
            data.append('data[0][extra_description_i]', this.fields.extra_description_i);
            data.append('data[0][extra_description_j]', this.fields.extra_description_j);
            data.append('data[0][is_document_a]', this.fields.is_document_a);
            data.append('data[0][is_document_b]', this.fields.is_document_b);
            data.append('data[0][is_document_c]', this.fields.is_document_c);
            data.append('data[0][is_document_d]', this.fields.is_document_d);
            data.append('data[0][is_document_e]', this.fields.is_document_e);
            data.append('data[0][is_document_f]', this.fields.is_document_f);
            data.append('data[0][is_document_g]', this.fields.is_document_g);
            data.append('data[0][is_document_h]', this.fields.is_document_h);
            data.append('data[0][is_document_i]', this.fields.is_document_i);
            data.append('data[0][is_document_j]', this.fields.is_document_j);
            fetch('/api/v1/asset_type', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.json();
            }).then(function(data) {
                self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                self.$emit('close');
                document.querySelector('body').classList.remove('modal-open');
            });
        }
    }
}
</script>