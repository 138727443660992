<template>
    <div>
        <div class="main-content" v-if="profile.accountPage">
            <div class="container">
                <div class="block block--account">
                    <div class="row">
                        <div class="col">
                            <div class="profile-card" style="border-right: 0;">
                                <div class="row">
                                    <div class="col-3">
                                        <div class="profile-pic-wrapper">
                                            <span class="edit" data-testid="accountLogoEdit" @click="editLogo()" v-if="accountInfo.profile_image_loc && accountInfo.profile_image_loc !== '/images/no-profile-img.png'"><i class="fas fa-pen"></i></span>
                                            <span class="seperator" v-if="accountInfo.profile_image_loc && accountInfo.profile_image_loc !== '/images/no-profile-img.png'">|</span>
                                            <span class="remove" data-testid="accountLogoDelete" @click="removeLogo()" v-if="accountInfo.profile_image_loc && accountInfo.profile_image_loc !== '/images/no-profile-img.png'"><i class="far fa-trash-alt"></i></span>
                                            <img :src="accountInfo.profile_image_loc" class="profile-pic" v-if="accountInfo.profile_image_loc && accountInfo.profile_image_loc !== '/images/no-profile-img.png'" />
                                            <span class="add" @click="editLogo()" v-if="!accountInfo.profile_image_loc || accountInfo.profile_image_loc === '/images/no-profile-img.png'"><i class="fas fa-plus"></i></span>
                                            <div class="profile-pic placeholder" v-if="!accountInfo.profile_image_loc || accountInfo.profile_image_loc === '/images/no-profile-img.png'">
                                                <i class="far fa-image"></i>
                                            </div>
                                            <input data-testid="accountLogoInput" type="file" id="profile-image-upload" style="display: none;" @change="uploadLogo($event)"/>
                                        </div>
                                    </div>
                                    <div class="col-9">
                                        <h2 data-testid="accountNameText" style="float: none;">{{accountInfo.repair_company_name}}</h2>
                                        <h3 data-testid="accountEmailText" style="color: #9d9797; margin-top: 5px;">{{accountInfo.username}}</h3>
                                    </div>
                                </div>
                                <span class="notification notification--error" v-if="uploadError" style="margin-top: 20px;">{{profile.accountPage.account.upload_error}}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-6">
                        <div class="block block--account">
                            <div class="account-info">
                                <h4>{{profile.accountPage.account.general}}
                                    <span class="edit-button edit-button--small" data-testid="generalEditButton" @click="accountInfoEdit = true" v-if="!accountInfoEdit"><i class="fas fa-pen"></i></span>
                                    <span class="edit-button edit-button--small" data-testid="generalSaveButton" @click="saveAccountInfo()" v-if="accountInfoEdit" v-show="(passwordCorrect && passwordsMatch && newPassword.length) || !newPassword.length" v-tooltip.right="profile.buttons.save"><i class="fas fa-save"></i></span>
                                    <span class="edit-button edit-button--small" data-testid="generalCancelButton" @click="cancelAccountInfo()" v-if="accountInfoEdit" v-tooltip.right="profile.buttons.cancel"><i class="fas fa-times"></i></span>
                                </h4>
                                <div class="account-contents">
                                    <div v-if="!accountInfoEdit">
                                        <label>{{profile.accountPage.account.password}}</label>
                                        <p>•••••••••</p>
                                        <label >{{ profile.accountPage.account.email_addresses_supplier }} <i class="fas fa-info-circle" v-tooltip.right="profile.accountPage.account.receivers_tooltip_supplier" style="cursor: pointer;"></i></label>
                                        <p style="margin-bottom: 0;" data-testid="generalEmailText">{{ accountInfo.repair_company_email_addresses }}</p>
                                    </div>
                                    <div v-if="accountInfoEdit">
                                        <div class="row">
                                            <div class="col-lg-6">
                                                <label>{{profile.accountPage.account.new_pw}}</label>
                                                <input data-testid="generalPasswordInput" type="password" class="form-control" v-model="newPassword" @input="passwordCheck()" />
                                                <ul class="password-requirements" v-if="!passwordCorrect">
                                                    <li><i class="fas fa-times red" v-if="!passwordRequirements.characters"></i><i class="fas fa-check green" v-if="passwordRequirements.characters"></i> {{profile.accountPage.account.length}}</li>
                                                    <li><i class="fas fa-times red" v-if="!passwordRequirements.uppercase"></i><i class="fas fa-check green" v-if="passwordRequirements.uppercase"></i> {{profile.accountPage.account.ucase}}</li>
                                                    <li><i class="fas fa-times red" v-if="!passwordRequirements.lowercase"></i><i class="fas fa-check green" v-if="passwordRequirements.lowercase"></i> {{profile.accountPage.account.lcase}}</li>
                                                    <li><i class="fas fa-times red" v-if="!passwordRequirements.number"></i><i class="fas fa-check green" v-if="passwordRequirements.number"></i> {{profile.accountPage.account.nr}}</li>
                                                </ul>
                                            </div>
                                            <div class="col-lg-6">
                                                <label>{{profile.accountPage.account.confirm_new_pw}}</label>
                                                <input data-testid="generalPasswordConfirmInput" type="password" class="form-control" v-model="newPasswordConfirm" @input="checkPasswords()" />
                                                <p class="password-requirements" v-if="!passwordsMatch"><b>{{profile.accountPage.account.match}}</b></p>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col">
                                                <label v-tooltip.right="profile.buttons.save">{{ profile.accountPage.account.email_addresses_supplier }} <i class="fas fa-info-circle" v-tooltip.right="profile.accountPage.account.receivers_tooltip_supplier" style="cursor: pointer;"></i></label>
                                                <input data-testid="generalEmailInput" type="text" class="form-control" v-model="accountInfo.repair_company_email_addresses" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 hidden-xs" v-if="accountInfo.service_qr_code">
                            <div class="block block--incident_detail location" style="min-height: 0; overflow: hidden;">
                                <section class="incident-head">
                                    <h1 style="max-width: 100%;">{{profile.accountPage.account.need_service}}</h1>
                                </section>
                                <img :src="'/api/v1/repaircompany/qrcodeasimage?qr_code=' + accountInfo.service_qr_code" class="qr-code" v-tooltip.right="profile.accountPage.account.service_qr" />
                                <a :href="'/open/onboarding/?qrcode=' + accountInfo.service_qr_code" target="blank" class="button button-qr-code"><i class="fas fa-concierge-bell" style="margin-right: 10px;"></i>{{profile.accountPage.account.ask_service}}</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="block block--account">
                            <div class="account-info">
                                <h4>{{profile.accountPage.company.my_company}}
                                    <span class="edit-button edit-button--small" @click="companyInfoEdit = true" v-if="!companyInfoEdit && accountInfo.repair_company_admin"><i class="fas fa-pen"></i></span>
                                    <span class="edit-button edit-button--small" @click="saveCompanyInfo()" v-if="companyInfoEdit  && accountInfo.repair_company_admin"><i class="fas fa-save"></i></span>
                                </h4>
                                <div class="account-contents">
                                    <div v-if="!companyInfoEdit">
                                        <div class="row">
                                            <div class="col-sm-6">
                                                <label>{{profile.accountPage.account.contactname}}</label>
                                                <p>{{accountInfo.repair_company_contact_name}}</p>
                                            </div>
                                            <div class="col-sm-6">
                                                <label>{{profile.accountPage.account.contactmobile}}</label>
                                                <p>{{accountInfo.repair_company_mobile}}</p>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-sm-6">
                                                <label>{{ profile.accountPage.account.contactphone }}</label>
                                                <p>{{ accountInfo.repair_company_phone }}</p>
                                            </div>
                                            <div class="col-sm-6">
                                                <label>{{ profile.accountPage.account.correspondence_email }}</label>
                                                <p>{{ accountInfo.repair_company_email }}</p>
                                            </div>             
                                        </div>
                                        <div class="row">
                                            <div class="col-sm-6">
                                                <label>{{ profile.accountPage.account.industry }}</label>
                                                <p>{{ industry.display_name }}</p>
                                            </div>                                  
                                        </div>
                                    </div>
                                    <div v-if="companyInfoEdit">
                                        <div class="row">
                                            <div class="col-sm-6">
                                                <label>{{profile.accountPage.account.contactname}}</label>
                                                <input type="text" class="form-control" v-model="accountInfo.repair_company_contact_name" />
                                            </div>
                                            <div class="col-sm-6">
                                                <label>{{profile.accountPage.account.contactmobile}}</label>
                                                <input type="text" class="form-control" v-model="accountInfo.repair_company_mobile" @input="validateMobilePhone()" :class="{valid: validMobilePhone, error: invalidMobilePhone}" />
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-sm-6">
                                                <label>{{ profile.accountPage.account.contactphone }}</label>
                                                <input type="text" class="form-control" v-model="accountInfo.repair_company_phone"  @input="validateGeneralPhone()" :class="{valid: validGeneralPhone, error: invalidGeneralPhone}" />
                                            </div>
                                            <div class="col-sm-6">
                                                <label>{{ profile.accountPage.account.correspondence_email }}</label>
                                                <input type="text" class="form-control" v-model="accountInfo.repair_company_email" @input="validateEmail()" :class="{valid: validEmail, error: invalidEmail}" />
                                            </div>             
                                        </div>
                                        <div class="row">
                                            <div class="col-sm-6">
                                                <label>{{ profile.accountPage.account.industry }}</label>
                                                <div class="dropdown-section" style="margin-bottom: 20px;">
                                                    <span class="dropdown" v-on:click="industryDropdownToggled = !industryDropdownToggled" :class="{active: industryDropdownToggled}">
                                                        <span v-if="!industry.display_name" style="color: #212529; font-size: 14px;">{{ profile.accountPage.account.industry }}</span>
                                                        <span v-else class="selected">{{industry.display_name}}</span>
                                                        <i class="fas fa-chevron-down chevron"></i>
                                                    </span>
                                                    <div class="dropdown-list" v-if="industryDropdownToggled">
                                                        <ul>
                                                            <li v-for="(industry, index) in industries" v-on:click="selectIndustry(industry)" :key="index">
                                                                {{industry.display_name}}
                                                            </li>        
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr style="margin-top: 0;" />
                                    <label style="font-size: 18px; margin-bottom: 10px;">{{profile.accountPage.account.notifications_via_wa}}</label>
                                    <div class="row">
                                        <div class="col">
                                            <label>{{ profile.accountPage.account.contactmobile }}
                                                <span data-testid="phoneEditButton" class="edit-button edit-button--small" @click="waPhoneEdit = true" v-if="!waPhoneEdit" style="margin-right: 15px;"><i class="fas fa-pen"></i></span>
                                                <span data-testid="phoneSaveButton" class="edit-button edit-button--small" @click="saveWaPhone()" v-else style="margin-right: 15px;"><i class="fas fa-save"></i></span>
                                            </label>
                                            <p data-testid="phoneNumberText" v-if="!waPhoneEdit" style="margin-bottom: 0;">{{ accountInfo.updates_via_wa_phonenr }}</p>
                                            <input data-testid="phoneNumberInput" type="text" class="form-control" v-model="accountInfo.updates_via_wa_phonenr" @input="validateWAPhone()" :class="{valid: validWAPhone, error: invalidWAPhone}" v-else />
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col">
                                            <div class="switch-wrapper" :class="{disabled: !this.accountInfo.updates_via_wa_phonenr}">
                                                <label class="switch">
                                                    <input type="checkbox" v-model="accountInfo.new_incident_updates_via_wa_active" :disabled="!this.accountInfo.updates_via_wa_phonenr" @change="toggleNotsWA()" >
                                                    <span data-testid="phoneNotificationSlider" class="slider round"></span>
                                                </label>
                                                <span style="font-weight: 100;"><i class="fab fa-whatsapp"></i> {{profile.accountPage.account.new_incidents_wa_supplier}}</span>
                                            </div>                                     
                                        </div>
                                    </div>
                                </div>           
                            </div>
                        </div>
                    </div>
                </div>
                <EmployeesBlock :accountInfo="accountInfo" />
            </div>
        </div>
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale-supplier.js';
import EmployeesBlock from "@/components/supplier/EmployeesBlock.vue";

export default {
    mixins: [setLocale],
    components: {
        EmployeesBlock
    },
    data() {
        return {
            profile: {},
            accountInfo: {},
            uploadError: false,
            accountInfoEdit: false,
            newPassword: '',
            newPasswordConfirm: '',
            passwordRequirements: {
                characters: false,
                uppercase: false,
                lowercase: false,
                number: false
            },
            passwordCorrect: true,
            passwordsMatch: true,
            companyInfoEdit: false,
            industries: [],
            industry: {},
            industryDropdownToggled: false,
            validEmail: false,
            invalidEmail: false,
            validGeneralPhone: false,
            invalidGeneralPhone: false,
            validMobilePhone: false,
            invalidMobilePhone: false,
            validWAPhone: false,
            invalidWAPhone: false,
            canEditWA: false,
            waPhoneEdit: false
        }
    },
    created() {
        document.title = 'S1MONE | Account';
        this.getAccountInfo();
    },
    methods: {
        track() {
            this.$gtag.pageview(this.$route);
        },
        getAccountInfo() {
            let self = this;
            fetch('/api/v1/userandrepaircompany')
            .then(response => { response.json().then(
                function(data) {
                    self.accountInfo = data;
                    self.getIndustries();
                });
            });
        },
        getIndustries() {
            let self = this;
            fetch('/api/v1/industry')
            .then(response => { response.json().then(
                function(data) {
                    for (var i = 0; i < data.data.length; i++) {
                        let industry = data.data[i];
                        if (industry.display_name !== 'Sensors') {
                            self.industries.push(industry);
                        }
                    }
                    for (var i = 0; i < self.industries.length; i++) {
                        let industry = self.industries[i];
                        if (industry.constant_name === self.accountInfo.industry_constant) {
                            self.industry = industry;
                        }
                    }
                });
            });
        },
        selectIndustry(industry) {
            this.industry = industry;
            this.industryDropdownToggled = false;
        },
        editLogo() {
            if (this.accountInfo.repair_company_admin) {
                document.getElementById('profile-image-upload').click();
            }
        },
        uploadLogo($event) {
            if ($event.target.files.length == 1) {
                this.loading = true;
                const data = new FormData();
                data.append('file', $event.target.files[0]);
                let self = this;
                fetch('/api/v1/fileupload/uploadlogo', {
                    method: 'post',
                    body: data
                }).then((resp) => {return resp.text();
                }).then(function(data) {
                    if (!data.includes('Error')) {
                        self.accountInfo.profile_image_loc = data;
                        self.uploadError = false;
                        self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                    }
                    else {
                        self.uploadError = true;
                    }
                    self.loading = false;
                });
            }
        },
        removeLogo() {
            const data = new URLSearchParams();
            let self = this;
            fetch('/api/v1/fileupload/deletelogo', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.text();
            }).then(function(data) {
                self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                self.accountInfo.profile_image_loc = '';
            });
        },
        saveAccountInfo() {
            let self = this;
            if (this.passwordCorrect && this.passwordsMatch && this.newPassword.length) {
                this.saveCorrEmail();
                const data = new URLSearchParams();
                data.append('newpassword', this.newPassword);
                fetch('/api/v1/changepassword', {
                    method: 'post',
                    body: data
                }).then((resp) => {return resp.json();
                }).then(function(data) {
                    self.accountInfoEdit = false;
                });
            }
            if (!this.newPassword.length) {
                self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                this.saveCorrEmail();
            }
        },
        saveCorrEmail() {
            const params = new URLSearchParams();
            params.append('action', 'edit');
            params.append('data[0][email_addresses]', this.accountInfo.repair_company_email_addresses);
            let self = this;
            fetch('/api/v1/repaircompany', {
                method: 'post',
                body: params
            }).then((resp) => {return resp.json();
            }).then(function(data) {
                self.accountInfoEdit = false;
            });
        },
        cancelAccountInfo() {
            this.accountInfoEdit = false;
            this.newPassword = '';
            this.passwordConfirm = '';
            this.$emit('getAccountInfo');
        },
        passwordCheck() {
            const ucase = new RegExp("[A-Z]+");
            const lcase = new RegExp("[a-z]+");
            const num = new RegExp("[0-9]+");
            if (this.newPassword.length > 9) {
                this.passwordRequirements.characters = true;
            }
            else {
                this.passwordRequirements.characters = false;
                this.passwordCorrect = false;
            }
            if (ucase.test(this.newPassword)) {
                this.passwordRequirements.uppercase = true;
            }
            else {
                this.passwordRequirements.uppercase = false;
                this.passwordCorrect = false;
            }
            if (lcase.test(this.newPassword)) {
                this.passwordRequirements.lowercase = true;
            }
            else {
                this.passwordRequirements.lowercase = false;
                this.passwordCorrect = false;
            }
            if (num.test(this.newPassword)) {
                this.passwordRequirements.number = true;
            }
            else {
                this.passwordRequirements.number = false;
                this.passwordCorrect = false;
            }
            if (!this.newPassword.length) {
                this.passwordCorrect = true;
            }
            this.checkValidations();
        },
        checkValidations() {
            if (this.passwordRequirements.characters &&
                this.passwordRequirements.uppercase &&
                this.passwordRequirements.lowercase &&
                this.passwordRequirements.number &&
                this.newPassword === this.newPasswordConfirm) {
                    this.passwordCorrect = true;
            }
            else {
                this.passwordCorrect = false;
            }
            if (this.newPassword === this.newPasswordConfirm) {
                this.passwordsMatch = true;
            }
        },
        checkPasswords() {
            if (this.newPassword !== this.newPasswordConfirm) {
                this.passwordsMatch = false;
                this.passwordCorrect = false;
            }
            else {
                this.passwordsMatch = true;
            }
            this.checkValidations();
        },
        saveCompanyInfo() {
            const params = new URLSearchParams();
            params.append('action', 'edit');
            params.append('data[0][contact_name]', this.accountInfo.repair_company_contact_name);
            params.append('data[0][mobile]', this.accountInfo.repair_company_mobile);
            params.append('data[0][phone]', this.accountInfo.repair_company_phone);
            params.append('data[0][email]', this.accountInfo.repair_company_email);
            let self = this;
            if (!this.invalidEmail && !this.invalidMobilePhone && !this.invalidGeneralPhone) {
                fetch('/api/v1/repaircompany', {
                    method: 'post',
                    body: params
                }).then((resp) => {return resp.text();
                }).then(function(data) {
                    self.companyInfoEdit = false;
                    self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                    self.saveIndustry();
                });
            }
        },
        saveIndustry() {
            const params = new URLSearchParams();
            params.append('action', 'edit');
            params.append('data[' + this.accountInfo.id_repair_company + '][industry_constant]', this.industry.constant_name);
            let self = this;
            fetch('/api/v1/repaircompany', {
                method: 'post',
                body: params
            }).then((resp) => {return resp.text();
            }).then(function(data) { });
        },
        validateGeneralPhone() {
            if (this.accountInfo.repair_company_phone) {
                let pattern = new RegExp(/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/);
                if (pattern.test(this.accountInfo.repair_company_phone)) {
                    this.validGeneralPhone = true;
                    this.invalidGeneralPhone = false;
                }
                else {
                    this.invalidGeneralPhone = true;
                    this.validGeneralPhone = false;
                }
            }
            else {
                this.invalidGeneralPhone = false;
                this.validGeneralPhone = false;
            }
        },
        validateMobilePhone() {
            if (this.accountInfo.repair_company_mobile) {
                let pattern = new RegExp(/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/);
                if (pattern.test(this.accountInfo.repair_company_mobile)) {
                    this.validMobilePhone = true;
                    this.invalidMobilePhone = false;
                }
                else {
                    this.invalidMobilePhone = true;
                    this.validMobilePhone = false;
                }
            }
            else {
                this.invalidMobilePhone = false;
                this.validMobilePhone = false;
            }
        },
        validateEmail() {
            if (this.accountInfo.repair_company_email) {
                let pattern = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
                if (pattern.test(this.accountInfo.repair_company_email)) {
                    this.validEmail = true;
                    this.invalidEmail = false;
                }
                else {
                    this.invalidEmail = true;
                    this.validEmail = false;
                }
            }
            else {
                this.invalidEmail = false;
                this.validEmail = false;
            }
        },
        validateWAPhone() {
            if (this.accountInfo.updates_via_wa_phonenr) {
                let pattern = new RegExp(/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/);
                if (pattern.test(this.accountInfo.updates_via_wa_phonenr)) {
                    this.validWAPhone = true;
                    this.invalidWAPhone = false;
                }
                else {
                    this.invalidWAPhone = true;
                    this.validWAPhone = false;
                }
            }
            else {
                this.invalidWAPhone = false;
                this.validWAPhone = false;
            }
        },
        canEditWACheck() {
            if (!this.accountInfo.mobile) {
                this.canEditWA = false;
            }
            else {
                this.canEditWA = true;
            }
        },
        saveWaPhone() {
            const params = new URLSearchParams();
            params.append('action', 'edit');
            params.append('data[0][updates_via_wa_phonenr]', this.accountInfo.updates_via_wa_phonenr);
            let self = this;
            if (!this.invalidWAPhone) {
                fetch('/api/v1/repaircompany', {
                    method: 'post',
                    body: params
                }).then((resp) => {return resp.text();
                }).then(function(data) {
                    self.waPhoneEdit = false;
                    self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                });
            }
            if (!this.accountInfo.updates_via_wa_phonenr.length) {
                this.accountInfo.new_incident_updates_via_wa_active = false;
                this.toggleNotsWA();
            }
        },
        toggleNotsWA() {
            const params = new URLSearchParams();
            params.append('action', 'edit');
            params.append('data[0][new_incident_updates_via_wa_active]', this.accountInfo.new_incident_updates_via_wa_active);
            let self = this;
            fetch('/api/v1/repaircompany', {
                method: 'post',
                body: params
            }).then((resp) => {return resp.json();
            }).then(function(data) {
                self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
            });
        }
    }
}
</script>
