<template>
    <div class="block block--account block--border">
        <div class="account-info">
            <h4>{{profile.accountPage.account.unfinished_incidents}} <i class="fas fa-info-circle" v-tooltip.right="profile.accountPage.account.unfinished_tooltip" style="cursor: pointer;"></i></h4>
            <div class="account-contents">
                <div class="row">
                    <div class="col">
                        <div>
                            <div class="switch-wrapper" :class="{disabled: !accountInfo.building_manager_admin && !twinq}">
                                <label class="switch">
                                    <input type="checkbox" v-model="accountInfo.push_empty_incident" @change="toggleEmptyIncidents()" :disabled="!accountInfo.building_manager_admin && !twinq">
                                    <span class="slider round"></span>
                                </label>
                                <span><b>{{profile.accountPage.account.unfinished_incidents_text}}</b></span>
                            </div>                        
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale.js';
import { mapState } from 'vuex';

export default {
    mixins: [setLocale],
    data: function () {
        return {
            profile: {}
        }
    },
    props: ['accountInfo'],
    computed: mapState(['twinq']),
    methods: {
        toggleEmptyIncidents() {
            const params = new URLSearchParams();
            params.append('action', 'edit');
            params.append('data[0][push_empty_incident]', this.accountInfo.push_empty_incident);
            let self = this;
            fetch('/api/v1/buildingmanager', {
                method: 'post',
                body: params
            }).then((resp) => {return resp.text();
            }).then(function(data) {
                self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
            });
        }
    }
}
</script>
