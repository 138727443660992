<template>
    <div>
        <div class="main-content" v-if="profile.incident_detail">
            <div class="container">
                <div class="row">
                    <div class="col">
                        <a class="back-link" @click="checkBack()" style="margin-bottom: 15px;" v-if="recording.status !== 'started'"><i class="fas fa-chevron-left"></i>{{profile.nav.back_link}}</a>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <div class="block">
                            <div class="profile-card asset" style="border-right: 0; width: 80%;">
                                <div class="row">
                                    <div class="col-xs-12 col-sm-9">
                                        <div class="profile-pic-wrapper" v-if="thumbnail">
                                            <div class="profile-pic" style="overflow: hidden;" :style="{ backgroundImage: 'url(' + thumbnail + ')', backgroundSize: 'cover', backgroundPosition: 'center', marginBottom: '20px', cursor: 'pointer' }" @click="showThumb = true"></div>
                                        </div>
                                        <h4>{{profile.actionable.recording}}</h4>
                                        <h3 style="margin-top: 5px; font-size: 18px;">{{recording.planned_action_list_name}}</h3>
                                        <label style="margin-top: 0; margin-bottom: 0; font-size: 12px;">{{profile.actionable.latest_recording_date}}</label>
                                        <p style="margin-bottom: 0; font-size: 12px;">{{recording.latestDate}}</p>  
                                    </div>
                                </div>
                            </div>
                            <div class="warning-label" v-if="recording.status === 'started'"><i class="fas fa-exclamation-circle"></i> {{profile.actionable.open_actionables}}</div>
                        </div>
                        <div class="block block--location_detail">
                            <section class="incident-head">
                                <h1>{{profile.dashboard.building_manager.message_table.place}}</h1>
                            </section>
                            <div class="row">
                                <div class="col-sm-10">
                                    <section class="location-contents">
                                        <div class="location-info">
                                            <div class="row">
                                                <div class="col">
                                                    <span class="address" style="padding-top: 15px; float: left; width: 100%;">
                                                        <span class="text-link" style="display: block; margin: 0; float: none;" @click="toAsset()"><b>{{recording.asset_name}}</b></span>
                                                        {{recording.address}} {{recording.house_number}}, {{profile.incident_detail.floor_name}} {{recording.floor_name}}<br />
                                                        {{recording.place_name}}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                    <div class="button button--icon" style="margin-top: 10px;" @click="newIncidentModalOpen = true" v-if="recording.qr_code"><i class="fas fa-plus"></i> {{profile.actionable.create_incident}} {{recording.asset_name}}</div>
                                </div>
                                <div class="col-sm-2">
                                    <a :href="mapsUrl" target="blank" class="detail-map" style="margin-top: 20px;">
                                        <img :src="mapsApiUrl" id="address-map" />
                                    </a>
                                </div>                           
                            </div>
                        </div>
                        <div class="block block--location_detail" id="actionDetail">
                            <section class="incident-head" v-if="recording.status !== 'completed'">
                                <h1>{{profile.actionable.actions}} <span v-if="recording.planned_actions && totalActions" style="font-weight: 500;">({{ totalActions }})</span></h1>
                                <span class="edit-button icon" @click="newActionModalOpen = true" v-tooltip.right="profile.actionable.add_action"><i class="fas fa-plus"></i></span>
                            </section>
                            <div style="margin-bottom: 20px; overflow: hidden;" v-if="recording.status !== 'completed'">
                                <div class="asset-block action" v-for="action in recording.planned_actions" :key="action.id_planned_action" @click="selectAction(action.id_planned_action)" style="text-align: center; width: 100%;" v-show="!selectedAction.id_planned_action">
                                    <h4 style="float: none; margin: 0;">{{action.name}}</h4>
                                </div>
                            </div>
                            <p v-if="!recording.planned_actions && !recording.groups" style="margin-bottom: 0;">{{ profile.actionable.no_actions }}</p>
                            <div v-for="(group, index) in recording.groups" :key="index" style="text-align: center; width: 100%;" v-show="group.planned_actions.length && !selectedAction.id_planned_action">
                                <h3 style="margin: 0 0 15px 0; text-align: left; text-transform: capitalize;">{{group.group_name}}</h3>
                                <div style="padding-left: 25px; margin-bottom: 20px; overflow: hidden;">
                                    <div class="asset-block action" v-for="action in group.planned_actions" :key="action.id_planned_action" @click="selectAction(action.id_planned_action)" style="text-align: center; width: 100%;" v-show="!selectedAction.id_planned_action">
                                        <h4 style="float: none; margin: 0;">{{action.name}}</h4>
                                    </div>
                                </div>
                            </div>
                            <div class="location-contents" v-if="selectedAction.id_planned_action">
                                <div class="location-info">
                                    <span class="remove-selection" style="top: 0; right: 0; cursor: pointer; z-index: 2;" @click="deselectAction()"><i class="fas fa-times" style="font-size: 22px;"></i></span>
                                    <h4 style="margin-top: 0; padding-right: 40px;">{{selectedAction.name}}</h4>
                                    <p>{{ profile.actionable.action_question }}</p>
                                    <div class="buttons" v-if="!startFlow">
                                        <div class="row">
                                            <div class="col">
                                                <button class="button button--icon" style="float: left; width: 100%; margin-bottom: 10px; text-align:center; font-size: 18px;" @click="start()" @touchstart="start()">{{profile.incident_detail.yes}}</button>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col">
                                                <button class="button button--icon" style="float: left; width: 100%; font-size: 18px; text-align: center;" @click="confirmSubmitModalOpen = true" @touchstart="confirmSubmitModalOpen = true">{{ profile.incident_detail.no }}</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="startFlow">
                                        <div class="accordeon" :class="{active: currentAccordeon === 1}" @click="switchAccordeon(1)">
                                            <span>1</span>
                                            {{profile.accountPage.account.general}}
                                        </div>
                                        <div class="accordeon-content">
                                            <div class="dropdown-section">
                                                <span class="dropdown" v-on:click="stateDropdownToggled = !stateDropdownToggled" :class="{active: stateDropdownToggled, error: stateError}">
                                                    <span v-if="!selectedState">{{profile.actionable.allover_state}} *</span>
                                                    <span v-if="selectedState" class="selected">{{selectedState}}</span>
                                                    <i class="fas fa-chevron-down chevron"></i></span>
                                                <div class="dropdown-list" v-if="stateDropdownToggled">
                                                    <ul>
                                                        <li v-for="(option, index) in selectedAction.state_options" :key="index" @click="selectState(option)">{{option}}</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <label>{{profile.owners.ownertable.description}}</label>
                                            <textarea class="form-control" v-model="remark"></textarea>
                                            <div class="row">
                                                <div class="col">
                                                    <span class="button button--icon" style="float: right; margin-top: 15px;" @click="finishFirstStep()"><i class="fas fa-caret-right"></i> {{profile.buttons.next}}</span>
                                                </div>
                                            </div>
                                            <span class="notification notification--error" v-if="stateError" style="margin: 10px 0;">{{profile.actionable.state_error}}</span>
                                        </div>
                                        <div class="accordeon" :class="{active: currentAccordeon === 2}" @click="switchAccordeon(2)">
                                            <span>2</span>
                                            {{profile.actionable.advice_repeating}}
                                        </div>
                                        <div class="accordeon-content">
                                            <label>{{profile.actionable.work}}</label>
                                            <textarea class="form-control" v-model="work" style="margin-bottom: 10px;"></textarea>
                                            <label>{{profile.actionable.frequency}}</label>
                                            <div class="dropdown-section">
                                                <span class="dropdown" v-on:click="frequencyDropdownToggled = !frequencyDropdownToggled" :class="{active: frequencyDropdownToggled}">
                                                    <span v-if="!selectedFrequency">{{profile.actionable.frequency}} *</span>
                                                    <span v-if="selectedFrequency" class="selected">{{selectedFrequency}}</span>
                                                    <i class="fas fa-chevron-down chevron"></i>
                                                </span>
                                                <div class="dropdown-list" v-if="frequencyDropdownToggled">
                                                    <ul>
                                                        <li v-for="(option, index) in selectedAction.frequency_options" :key="index" @click="selectFrequency(option)">{{option}}</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <label>{{profile.actionable.suggestion_first_action}}</label>
                                            <flat-pickr v-model="firstActionDate" class="form-control" :config="flatPickrDateConfig" :placeholder="profile.incident_detail.pick_date"></flat-pickr>
                                            <div class="row">
                                                <div class="col">
                                                    <span class="button button--icon step-back" @click="switchAccordeon(1)" style="float: left; background: white; color: gray; padding-left: 0;"><i class="fas fa-caret-left"></i>{{profile.buttons.previous}}</span>
                                                    <span class="button button--icon" style="float: right; margin-top: 15px;" @click="switchAccordeon(3)" v-if="work || selectedFrequency || firstActionDate"><i class="fas fa-caret-right"></i> {{profile.buttons.next}}</span>
                                                    <span class="button button--icon" style="float: right; margin-top: 15px;" @click="switchAccordeon(3)" v-else><i class="fas fa-caret-right"></i> {{profile.mandates.skip}}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="accordeon" :class="{active: currentAccordeon === 3}" @click="switchAccordeon(3)">
                                            <span>3</span>
                                            {{profile.incident_detail.imagery}}
                                        </div>
                                        <div class="accordeon-content">
                                            <div class="incident-media" style="margin-top: 0; overflow: hidden;">
                                                <label>{{profile.incident_detail.imagery}} <i class="fas fa-info-circle" v-tooltip.right="profile.actionable.three_media" style="cursor: pointer;"></i></label>
                                                <span class="button button--outline button--small icon media-button" @click="uploadRecordingMediaModalOpen = true;" v-if="totalMedia < 10"><i class="fas fa-plus"></i> {{ profile.incident_detail.add_images }}</span>
                                                <div class="media-wrapper recordings" style="margin-top: 15px; padding-bottom: 70px; overflow: hidden;">
                                                    <div class="item-wrapper" v-for="(item, index) in media" :key="index">
                                                        <div class="media media--small" :class="{active: item.popover, video: item.content_type === 'video/mp4'}" v-tooltip.left="item.description">
                                                            <img :src="item.file_url" @click="openPopover(item)" v-if="item.content_type === 'image/jpeg' || item.content_type === null && !item.i_frame_code" />
                                                            <div class="iframe_overlay" v-if="item.i_frame_code" @click="openPopover(item)" style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; z-index: 20;"></div>
                                                            <div v-html="item.i_frame_code" v-if="item.i_frame_code" style="margin-top: -35px;"></div>
                                                            <div class="video_overlay" @click="openPopover(item)" v-if="item.content_type === 'video/mp4'"><i class="fas fa-play"></i></div>
                                                            <video v-if="item.content_type === 'video/mp4'" @click="openPopover(item)">
                                                                <source :src="item.file_url" type="video/mp4">
                                                            </video>
                                                            <span class="label" v-if="profile.incident_detail[item.type]">{{profile.incident_detail[item.type]}}</span>
                                                            <span class="remove" @click="selectFileToRemove(item)" style="cursor: pointer;"><i class="far fa-trash-alt"></i></span>
                                                            <div class="media-pop-over">
                                                                <span class="close-popover" @click="closePopover()"><i class="fa fa-times"></i></span>
                                                                <span class="label" v-if="profile.incident_detail[item.type]">{{profile.incident_detail[item.type]}}</span>
                                                                <span class="media-counter">{{index + 1}} / {{media.length}}</span>
                                                                <figure class="zoom" :style="{ 'background-image': 'url(' + item.file_url + ')' }" @mousemove="zoom" :class="{rotate_2: item.rotate === 2, rotate_3: item.rotate === 3, rotate_4: item.rotate === 4}">
                                                                    <img @click="closePopover()" :src="item.file_url" v-if="item.content_type === 'image/jpeg' || item.content_type === null && !item.i_frame_code" :class="{rotate_2: item.rotate === 2, rotate_3: item.rotate === 3, rotate_4: item.rotate === 4}" />
                                                                </figure>
                                                                <div v-html="item.i_frame_code" v-if="item.i_frame_code" style="margin-top: 40px;"></div>
                                                                <video v-if="item.content_type === 'video/mp4'" :class="{rotated: item.rotate}" controls><source :src="item.file_url" type="video/mp4"></video>
                                                                <p v-if="item.description">{{item.description}}</p>
                                                                <span class="media-control left" v-if="index !== 0" @click="selectMedia('prev', index)"><i class="fa fa-chevron-left"></i></span>
                                                                <span class="media-control right" v-if="index + 1 !== media.length" @click="selectMedia('next', index)"><i class="fa fa-chevron-right"></i></span>
                                                            </div>
                                                        </div>
                                                        <p style="margin-bottom: 10px;">{{ item.file_upload_description }}</p>
                                                        <div class="switch-wrapper" style="margin-bottom: 5px; position: relative; z-index: 10;" >
                                                        <label class="switch" style="float: left;">
                                                            <input type="checkbox" @change="toggleItem(item)">
                                                            <span class="slider round"></span>
                                                        </label>
                                                        <span style="font-weight: 100;"> {{profile.actionable.save_to_asset}}</span>
                                                    </div>
                                                    </div>
                                                    <div class="media video" v-for="item in movs" :key="item.id_file_upload" style="overflow: visible;">
                                                        <span class="label" v-if="profile.incident_detail[item.type]">{{profile.incident_detail[item.type]}}</span>
                                                        <span class="placeholder"><i class="fas fa-file-video"></i></span>
                                                        <a :href="item.file_url" class="video_overlay download" v-if="item.content_type === 'video/quicktime'"></a>
                                                        <span class="remove" @click="selectFileToRemove(item)" style="cursor: pointer;"><i class="far fa-trash-alt"></i></span>
                                                        <p style="margin-top: 98px;">{{ item.file_upload_description }}</p>
                                                        <div class="switch-wrapper" style="margin-top: 120px; position: relative; z-index: 10;" >
                                                        <label class="switch" style="float: left;">
                                                            <input type="checkbox" @change="toggleItem(item)">
                                                            <span class="slider round"></span>
                                                        </label>
                                                        <span style="font-weight: 100;"> {{profile.actionable.save_to_asset}}</span>
                                                    </div>
                                                    </div>                                                  
                                                </div>
                                            </div>
                                            <div class="row">                                             
                                                <div class="col">
                                                    <span class="button button--icon" style="float: right;" @click="switchAccordeon(4)" v-if="media.length"><i class="fas fa-caret-right"></i> {{profile.buttons.next}}</span>
                                                    <span class="button button--icon" style="float: right;" @click="switchAccordeon(4)" v-else><i class="fas fa-caret-right"></i> {{profile.mandates.skip}}</span>
                                                    <span class="button button--icon step-back" @click="switchAccordeon(2)" style="float: left; background: white; color: gray; padding-left: 0; margin-top: 0;"><i class="fas fa-caret-left"></i>{{profile.buttons.previous}}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="accordeon" :class="{active: currentAccordeon === 4}" @click="switchAccordeon(4)">
                                            <span>4</span>
                                            {{profile.actionable.direct_action}}
                                        </div>
                                        <div class="accordeon-content">
                                            <div class="row">
                                                <div class="col">
                                                    <div class="dropdown-section">
                                                        <span class="dropdown" data-testid="selectCategory" v-on:click="categoryDropdownToggled = !categoryDropdownToggled" :class="{active: categoryDropdownToggled, error: categoryError, valid: selectedCategory.dutch_name}">
                                                            <span v-if="!selectedCategory.id_incident_category">{{profile.incident_detail.category}} *</span>
                                                            <span v-if="selectedCategory.id_incident_category && locale === 'nl'" class="selected">{{selectedCategory.dutch_name}}</span>
                                                            <span v-if="selectedCategory.id_incident_category && locale === 'en'" class="selected">{{selectedCategory.english_name}}</span>
                                                            <i class="fas fa-chevron-down chevron"></i></span>
                                                        <div class="dropdown-list" v-if="categoryDropdownToggled" :class="{search: categories.length > 9}">
                                                            <input type="text" @input="e => categorySearchTerm = e.target.value" :value="categorySearchTerm" :placeholder="profile.incident_detail.search_category" @keyup="searchCategories()" v-if="categories.length > 9"  />
                                                            <ul>
                                                                <li v-for="category in categories" v-on:click="selectCategory(category)" :key="category.id_category" v-show="!tempCategories.length && !categoriesNoResults">
                                                                    <span data-testid="selectCategoryResultNl" v-if="locale === 'nl'">{{category.dutch_name}}</span>
                                                                    <span data-testid="selectCategoryResultEn" v-if="locale === 'en'">{{category.english_name}}</span>
                                                                </li>
                                                                <li v-for="(category, index) in tempCategories" v-on:click="selectCategory(category)" :key="index" v-show="tempCategories.length">
                                                                    <span data-testid="selectCategoryResultIndexNl" v-if="locale === 'nl'">{{category.dutch_name}}</span>
                                                                    <span data-testid="selectCategoryResultIndexEn" v-if="locale === 'en'">{{category.english_name}}</span>
                                                                </li>
                                                                <li v-if="categoriesNoResults" class="no-results">{{profile.dashboard.building_manager.no_resultss}}</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-field" :class="{focus:fields.descr.length, error: descriptionError, valid: fields.descr.length}">
                                                <label>{{profile.incidents.new_incident.description}} *</label>
                                                <textarea class="form-control" v-model="fields.descr" data-testid="inputDescription" @input="checkDescr()" style="margin-bottom: 10px;"></textarea>
                                            </div>
                                            <div class="row">
                                                <div class="col">
                                                    <span class="button button--icon step-back" @click="switchAccordeon(3)" style="float: left; background: white; color: gray; padding-left: 0;"><i class="fas fa-caret-left"></i>{{profile.buttons.previous}}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row" v-if="(finishedActions.planned_actions || finishedActions.groups) && recording.status === 'completed'">
                                <div class="col">
                                    <div class="incident-head" style="margin-bottom: 20px;">
                                        <h1>{{ profile.actionable.finished_actions }}</h1>
                                    </div>
                                </div>
                            </div>
                            <div style="overflow: hidden;" v-if="recording.status === 'completed'">
                                <div class="asset-block action" v-for="action in finishedActions.planned_actions" :key="action.id_planned_action" style="text-align: center; width: 100%; background: white; border: none; padding-left: 0; margin-bottom: 0;" v-show="!selectedAction.id_planned_action">
                                    <h4 style="float: none; margin: 0; color: #666;">{{action.name}}</h4>
                                </div>
                            </div>
                            <div v-for="(group, index) in finishedActions.groups" :key="index" style="text-align: center; width: 100%;" v-show="group.planned_actions.length && !selectedAction.id_planned_action && recording.status === 'completed'">
                                <h3 style="margin: 0; text-align: left; text-transform: capitalize;">{{group.group_name}}</h3>
                                <div style="padding-left: 25px; margin-bottom: 20px; overflow: hidden;">
                                    <div class="asset-block action" v-for="action in group.planned_actions" :key="action.id_planned_action" style="text-align: center; width: 100%;  background: white; border: none; padding-left: 0;  margin-bottom: 0;" v-show="!selectedAction.id_planned_action">
                                        <h4 style="float: none; margin: 0; color: #666;">{{action.name}}</h4>
                                    </div>
                                </div>
                            </div>
                            <span class="button button--icon" style="float: right; margin-top: 15px;" @click="saveAction" v-if="selectedActionId && startFlow">{{ profile.actionable.save_and_submit}}</span>
                        </div>
                        <div id="bottom_anchor"></div>
                    </div>
                </div>
            </div>
            <div class="incident-media" v-if="showThumb">
                <div class="media active">
                    <div class="media-pop-over">
                        <span class="close-popover" @click="showThumb = false"><i class="fa fa-times"></i></span>
                        <image-zoom 
                            :regular=thumbnail
                        />
                    </div>
                </div>
            </div>         
        </div>
        <NewIncidentModal v-if="newIncidentModalOpen" @close="newIncidentModalOpen = false" :recording="recording" @success="newIncidentModalOpen = false" />
        <NewActionModal v-if="newActionModalOpen" @close="newActionModalOpen = false" @success="getRecording()" :recording="recording" />
        <RemoveRecordingFileModal v-if="removeFileModalOpen" @close="removeFileModalOpen = false" @success="selectAction(selectedActionId)" :file="file" :actionListId="recording.id_planned_action_list" />
        <uploadRecordingMediaModal v-if="uploadRecordingMediaModalOpen" @close="uploadRecordingMediaModalOpen = false" @success="selectAction(selectedActionId)" :mediaFields="mediaFields" :actionListId="recording.id_planned_action_list"  />
        <ConfirmSubmitModal v-if="confirmSubmitModalOpen" @close="confirmSubmitModalOpen = false" @success="getRecording()" :selectedAction="selectedAction" :selectedActionId="selectedActionId" :totalActions="totalActions"  />
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale-mechanic.js';
import { mapState } from 'vuex';
import moment from 'moment';
import NewActionModal from "@/components/mechanic/modals/NewActionModal.vue";
import NewIncidentModal from "@/components/mechanic/modals/CreateIncidentModal.vue";
import uploadRecordingMediaModal from "@/components/mechanic/modals/UploadRecordingMediaModal.vue";
import RemoveRecordingFileModal from "@/components/mechanic/modals/RemoveRecordingFileModal.vue";
import ConfirmSubmitModal from "@/components/mechanic/modals/ConfirmSubmitModal.vue";
import {Dutch} from 'flatpickr/dist/l10n/nl.js';
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import VueScrollTo from 'vue-scrollto';
import uniqBy from 'lodash/uniqBy';
import sortBy from 'lodash/sortBy';
import imageZoom from 'vue-image-zoomer';

export default {
    mixins: [setLocale],
    components: {
        flatPickr,
        NewActionModal,
        uploadRecordingMediaModal,
        RemoveRecordingFileModal,
        NewIncidentModal,
        ConfirmSubmitModal,
        imageZoom
    },
    data() {
        return {
            profile: {},
            recordingId: null,
            recording: {},
            mapsApiUrl: null,
            mapsUrl: null,
            selectedAction: {},
            stateDropdownToggled: false,
            selectedState: '',
            uploadRecordingMediaModalOpen: false,
            removeFileModalOpen: false,
            media: [],
            movs: [],
            remark: '',
            work: '',
            selectedActionId: 0,
            mediaFields: [],
            totalMedia: 0,
            frequencyDropdownToggled: false,
            flatPickrDateConfig: {
                altInput: true,
                altFormat: "F j, Y",
                dateFormat: "Y-m-d"
            },
            fields: {
                category: null,
                descr: ''
            },
            uploadError: false,
            categorySearchTerm: '',
            tempCategories: [],
            categories: [],
            asset: {},
            categoryError: false,
            categoryDropdownToggled: false,
            selectedCategory: {},
            descriptionError: false,
            categoriesNoResults: false,
            tomorrow: null,
            firstActionDate: null,
            selectedFrequency: null,
            newActionModalOpen: false,
            newIncidentModalOpen: false,
            confirmSubmitModalOpen: false,
            repeatingWork: false,
            toReport: true,
            stateError: false,
            directAction: false,
            totalActions: null,
            finishedActions: [],
            startFlow: false,
            currentAccordeon: 1,
            thumbnail: '',
            showThumb: false,
        }
    },
    computed: mapState(['mapsKey']),
    created() {
        document.title = 'S1MONE | ' + this.profile.actionable.recordings;
        let queryString = window.location.search;
        this.recordingId = queryString.split('=')[1];
        this.getRecording();
        if (this.locale === 'nl') {
            this.flatPickrDateConfig.locale = Dutch;
            this.flatPickrDateConfig.altFormat = "j F, Y";
        }
        this.tomorrow = new Date();
        this.tomorrow.setDate(this.tomorrow.getDate()+1);
        this.tomorrow = moment(this.tomorrow).format();
        this.tomorrow = this.tomorrow.split('T')[0];
        this.flatPickrDateConfig.minDate = this.tomorrow;
    },
    methods: {
        track() {
            this.$gtag.pageview(this.$route);
        },
        getRecording() {
            this.currentAccordeon = 1;
            this.startFlow = false;
            this.confirmSubmitModalOpen = false;
            this.selectedState = '';
            this.remark = '';
            this.work = '';
            this.selectedFrequency = '';
            this.firstActionDate = null;
            this.selectedAction = {};
            this.recording = {};
            let self = this;
            fetch('/api/v1/plannedactionlist/byidwithactions?id_planned_action_list=' + this.recordingId + '&status=planned')
            .then(response => { response.json().then(
                function(data) {
                    data.groups = [];
                    if (data.planned_actions.length) {
                        for (var i = 0; i < data.planned_actions.length; i++) {
                            let action = data.planned_actions[i];
                            action.trimmedName = action.name.toLowerCase();
                        }
                        self.totalActions = data.planned_actions.length;
                        data.planned_actions = sortBy(data.planned_actions, 'name');
                        for (var j = 0; j < data.planned_actions.length; j++) {
                            let action = data.planned_actions[j];
                            if (action.group_name) {
                                action.group_name_trimmed = action.group_name.trim().toLowerCase();
                                data.groups.push({ 'group_name': action.group_name, 'group_name_trimmed': action.group_name_trimmed, 'planned_actions': []});
                            }
                            data.groups = uniqBy(data.groups, 'group_name_trimmed');
                            data.groups = sortBy(data.groups, 'group_name_trimmed');
                        }
                    }
                    self.recording = data;
                    self.recording.latestDate = moment(self.recording.latest_possible).locale('nl').format('D MMMM YYYY');
                    for (var i = 0; i < self.recording.groups.length; i++) {
                        let group = self.recording.groups[i];
                        const targetKey = 'group_name_trimmed';
                        const targetValue = group.group_name_trimmed;
                        // Function to check for the key and value pair
                        function hasKeyValue(obj) {
                            return obj.hasOwnProperty(targetKey) && obj[targetKey] === targetValue;
                        }
                        // Filter out objects with the specific key-value pair
                        let filteredArray =  self.recording.planned_actions.filter(hasKeyValue);
                        // Remove filtered objects from the source array
                        const sourceArrayWithoutMatch =  self.recording.planned_actions.filter(obj => !hasKeyValue(obj));
                        self.recording.planned_actions = sourceArrayWithoutMatch;
                        group.planned_actions = filteredArray;
                        group.planned_actions = sortBy(group.planned_actions, 'trimmedName');
                    }
                    self.recording.groups = sortBy(self.recording.groups, 'name');
                    let gmapsAddress    = self.recording.address + "+" + self.recording.house_number + "+"+self.recording.city.replace(/ /g , "+");
                    self.mapsApiUrl     = 'https://maps.googleapis.com/maps/api/staticmap?markers='+
                                           gmapsAddress+
                                            '&zoom=16&size=250x100&key=' + self.mapsKey;
                    self.mapsUrl        = "https://www.google.com/maps/place/"+gmapsAddress;
                    self.getAsset();
                    let element = document.getElementById('bottom_anchor');
                    VueScrollTo.scrollTo(element, 200);
                });
            });
            fetch('/api/v1/plannedactionlist/byidwithactions?id_planned_action_list=' + this.recordingId + '&status=completed')
            .then(response => { response.json().then(
                function(data) {
                    data.groups = [];
                    if (data.planned_actions.length) {
                        for (var i = 0; i < data.planned_actions.length; i++) {
                            let action = data.planned_actions[i];
                            action.trimmedName = action.name.toLowerCase();
                        }
                        data.planned_actions = sortBy(data.planned_actions, 'name');
                        for (var j = 0; j < data.planned_actions.length; j++) {
                            let action = data.planned_actions[j];
                            if (action.group_name) {
                                action.group_name_trimmed = action.group_name.trim().toLowerCase();
                                data.groups.push({ 'group_name': action.group_name, 'group_name_trimmed': action.group_name_trimmed, 'planned_actions': []});
                            }
                            data.groups = uniqBy(data.groups, 'group_name_trimmed');
                            data.groups = sortBy(data.groups, 'group_name_trimmed');
                        }
                    }
                    self.finishedActions = data;
                    for (var i = 0; i < self.finishedActions.groups.length; i++) {
                        let group = self.finishedActions.groups[i];
                        const targetKey = 'group_name_trimmed';
                        const targetValue = group.group_name_trimmed;
                        // Function to check for the key and value pair
                        function hasKeyValue(obj) {
                            return obj.hasOwnProperty(targetKey) && obj[targetKey] === targetValue;
                        }
                        // Filter out objects with the specific key-value pair
                        let filteredArray =  self.finishedActions.planned_actions.filter(hasKeyValue);
                        // Remove filtered objects from the source array
                        const sourceArrayWithoutMatch =  self.finishedActions.planned_actions.filter(obj => !hasKeyValue(obj));
                        self.finishedActions.planned_actions = sourceArrayWithoutMatch;
                        group.planned_actions = filteredArray;
                        group.planned_actions = sortBy(group.planned_actions, 'trimmedName');
                    }
                    self.finishedActions.groups = sortBy(self.finishedActions.groups, 'name');
                });
            });
        },
        checkBack() {
            this.$router.go(-1);
        },
        selectAction(id) {
            this.uploadRecordingMediaModalOpen = false;
            this.removeFileModalOpen = false;
            this.selectedActionId = id;
            this.media = [];
            this.mediaFields = [];
            this.movs = [];
            let self = this;
            fetch('/api/v1/plannedaction?id_planned_action=' + this.selectedActionId)
            .then(response => { response.json().then(
                function(data) {
                    for (var i = 0; i < data.planned_action_fields.length; i++) {
                        let field = data.planned_action_fields[i];
                        if (field.name === 'Algehele staat') {
                            let stateOptions = field.list_values.split(',');
                            data.state_options = stateOptions;
                        }
                        if (field.name === 'Repeterend werk frequentie') {
                            let frequencyOptions = field.list_values.split(',');
                            data.frequency_options = frequencyOptions;
                        }
                    }
                    for (var i = 0; i < data.planned_action_fields.length; i++) {
                        let media = data.planned_action_fields[i];
                        if (media.actionable_field_type === 'media' && media.content_type !== 'video/quicktime' && media.file_url) {
                            media.toggledAdd = false;
                            media.popover = false;
                            media.rotate = 1;
                            media.order = 1;
                            media.selected = false;
                            self.media.push(media);
                        }
                        if (media.actionable_field_type === 'media' && !media.file_url) {
                            media.toggledAdd = false;
                            media.popover = false;
                            media.rotate = 1;
                            media.order = 1;
                            media.selected = false;
                            self.mediaFields.push(media);
                        }
                        if (media.content_type === 'video/quicktime') {
                            self.movs.push(media);
                        }
                        if (media.name === 'Algehele staat' && media.value) {
                            self.selectedState = media.value;
                        }
                        if (media.name === 'Opmerkingen' && media.value) {
                            self.remark = media.value;
                        }
                    }
                    self.totalMedia = self.media.length + self.movs.length;
                    self.selectedAction = data;
                    let element = document.getElementById('bottom_anchor');
                    VueScrollTo.scrollTo(element, 200);
                });
            });
        },
        finishFirstStep() {
            if (this.selectedState) {
                this.currentAccordeon = 2;
                let element = document.getElementById('bottom_anchor');
                VueScrollTo.scrollTo(element, 200);
            }
            else {
                this.stateError = true;
            }
        },
        selectState(state) {
            this.stateDropdownToggled = false;
            this.selectedState = state;
            this.stateError = false;
        },
        selectFrequency(frequency) {
            this.frequencyDropdownToggled = false;
            this.selectedFrequency = frequency;
        },
        toggleRepeating() {
            this.repeatingWork = !this.repeatingWork;
            this.work = '';
            this.firstActionDate = null;
            this.selectedFrequency = null;
        },
        saveAction() {
            if (!this.selectedState) {
                this.stateError = true;
            }
            else {
                if (this.repeatingWork) {
                    this.saveAdvice();
                }
                let listFieldId;
                let descrFieldId;
                let dateFieldId;
                let freqFieldId;
                let workFieldId;
                for (var i = 0; i < this.selectedAction.planned_action_fields.length; i++) {
                    let field = this.selectedAction.planned_action_fields[i];
                    if (this.selectedCategory.id_incident_category) {
                        if (!this.fields.descr) {
                            this.descriptionError = true;
                        }
                        else {
                            this.descriptionError = false;
                        }
                    }
                    if (this.toReport && !this.descriptionError) {
                        if (field.name === 'Algehele staat' && this.selectedState) {
                            listFieldId = field.id_planned_action_field;
                            const data = new URLSearchParams();
                            data.append('id_planned_action_field', listFieldId);
                            data.append('value', this.selectedState);
                            fetch('/api/v1/plannedactionfield/update', {
                                method: 'post',
                                body: data
                            }).then((resp) => {
                                return resp.json();
                            }).then(function(data) {
                            });
                        }
                        if (field.name === 'Voorstel eerste actie' && this.firstActionDate) {
                            dateFieldId = field.id_planned_action_field;
                            let date = new Date(this.firstActionDate);
                            date.setHours(23, 59, 59);
                            date = date.getTime();
                            const data = new URLSearchParams();
                            data.append('id_planned_action_field', dateFieldId);
                            data.append('value', date);
                            fetch('/api/v1/plannedactionfield/update', {
                                method: 'post',
                                body: data
                            }).then((resp) => {
                                return resp.json();
                            }).then(function(data) {
                            });
                        }
                        if (field.name === 'Repeterend werk frequentie' && this.selectedFrequency) {
                            freqFieldId = field.id_planned_action_field;
                            const data = new URLSearchParams();
                            data.append('id_planned_action_field', freqFieldId);
                            data.append('value', this.selectedFrequency);
                            fetch('/api/v1/plannedactionfield/update', {
                                method: 'post',
                                body: data
                            }).then((resp) => {
                                return resp.json();
                            }).then(function(data) {
                            });
                        }
                        if ((field.name === 'Werk eerste actie' || field.name === 'Werk') && this.work) {
                            workFieldId = field.id_planned_action_field;
                            const data = new URLSearchParams();
                            data.append('id_planned_action_field', workFieldId);
                            data.append('value', this.work);
                            fetch('/api/v1/plannedactionfield/update', {
                                method: 'post',
                                body: data
                            }).then((resp) => {
                                return resp.json();
                            }).then(function(data) {
                            });
                        }
                        if (!this.selectedState ) {
                            this.stateError = true;
                        }
                    }
                    if (field.name === 'Opmerkingen' && this.remark) {
                        descrFieldId = field.id_planned_action_field;
                        const data = new URLSearchParams();
                        data.append('id_planned_action_field', descrFieldId);
                        data.append('value', this.remark);
                        fetch('/api/v1/plannedactionfield/update', {
                            method: 'post',
                            body: data
                        }).then((resp) => {
                            return resp.json();
                        }).then(function(data) {
                        });
                    }
                }
                for (var i = 0; i < this.media.length; i++) {
                    let item = this.media[i];
                    if (item.toggledAdd) {
                        const data = new URLSearchParams();
                        data.append('id_planned_action_list', this.recordingId);
                        data.append('id_file_upload', item.id_file_upload);
                        fetch('/api/v1/fileupload/updateplannedactionmedia', {
                            method: 'post',
                            body: data
                        }).then((resp) => {
                            return resp.json();
                        }).then(function(data) {
                        });
                    }
                }
                if (!this.stateError && !this.selectedCategory.id_incident_category) {
                    const data = new URLSearchParams();
                    data.append('id_planned_action', this.selectedActionId);
                    let self = this;
                    fetch('/api/v1/plannedaction/setcompleted', {
                        method: 'post',
                        body: data
                    }).then((resp) => {
                        return resp.json();
                    }).then(function(data) {
                        self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                        if (self.totalActions === 1) {
                            self.$router.push('/monteur/opnames');
                        }
                        else {
                            self.getRecording();
                        }
                        self.repeatingWork = false;
                        self.toReport = true;
                    });
                }
                if (!this.stateError && this.selectedCategory.id_incident_category) {
                    if (!this.fields.descr) {
                        this.descriptionError = true;
                    }
                    else {
                        this.createNewIncident();
                    }
                }
            }
        },
        saveAdvice() {
            const data = new URLSearchParams();
            data.append('id_planned_action', this.selectedActionId);
            data.append('advice', true);
            let self = this;
            fetch('/api/v1/plannedaction/setrecurringjobadvice', {
                method: 'post',
                body: data
            }).then((resp) => {
                return resp.json();
            }).then(function(data) {
            });
        },
        zoom(e){
            let offsetX;
            let offsetY;
            let x;
            let y;
            let zoomer = e.currentTarget;
            e.offsetX ? offsetX = e.offsetX : offsetX = e.touches[0].pageX
            e.offsetY ? offsetY = e.offsetY : offsetX = e.touches[0].pageX
            x = offsetX/zoomer.offsetWidth*100
            y = offsetY/zoomer.offsetHeight*100
            zoomer.style.backgroundPosition = x + '% ' + y + '%';
        },
        selectMedia(type, index) {
            if (type === 'prev') {
                index = index - 1;
                for (var i = 0; i < this.media.length; i++) {
                    this.media[i].popover = false;
                    this.media[index].popover = true;
                }
            }
            if (type === 'next') {
                index = index + 1;
                for (var j = 0; j < this.media.length; j++) {
                    this.media[j].popover = false;
                    this.media[index].popover = true;
                }
            }
        },
        openPopover(item) {
            item.popover = true;
            document.querySelector('body').classList.add('media-open');
        },
        closePopover() {
            document.querySelector('body').classList.remove('media-open');
            for (var i = 0; i < this.media.length; i++) {
                this.media[i].popover = false;
            }
        },
        selectFileToRemove(item) {
            this.removeFileModalOpen = true;
            this.file = item;
        },
        rotateMedia(item) {
            item.rotate++;
            if (item.rotate === 5) {
                item.rotate = 1;
            }
        },
        toggleItem(item) {
            item.toggledAdd = !item.toggledAdd;
        },
        getAsset() {
            let self = this;
            fetch('/api/v1/asset/byid?id_asset=' + this.recording.id_asset)
            .then(response => { response.json().then(
                function(data) {
                    if (data.file_upload.length) {
                        for (var i = 0; i < data.file_upload.length; i++) {
                            let media = data.file_upload[i];
                            if (media.content_type !== 'video/quicktime' && media.file_url) {
                                if (data.main_image_id_file_upload && media.id_file_upload === data.main_image_id_file_upload) {
                                    self.thumbnail = media.file_url;
                                }
                            }
                        }
                        if (!data.main_image_id_file_upload && self.media.length) {
                            self.thumbnail = self.media[0].file_url;
                        }
                    }  
                    self.asset = data;
                    self.categories = data.categories;
                    self.categories = uniqBy(self.categories, 'id_incident_category');
                    for (var i = 0; i <self.categories.length; i++) {
                        let category = self.categories[i];
                        category.dutch_name = category.dutch_name.split('*')[0];
                        category.english_name = category.english_name.split('*')[0];
                        if (category.dutch_name.includes('-2') || category.dutch_name.includes('-3')|| category.dutch_name.includes('-4')) {
                            self.categories.splice(i, 1);
                        }
                    }
                    if (self.locale === 'nl') {
                        self.categories = sortBy(self.categories, 'dutch_name');
                    }
                    else {
                        self.categories = sortBy(self.categories, 'english_name');
                    }          
                });
            });
        },
        searchCategories(type) {
            this.tempCategories = [];
            if (this.categorySearchTerm.length > 1) {
                let searchTerm = this.categorySearchTerm.toLowerCase();
                for (var i = 0; i < this.categories.length; i++) {
                    let category = this.categories[i];
                    let categoryName;
                    if (this.locale === 'en') {
                        categoryName = category.english_name.toLowerCase();
                    }
                    else {
                        categoryName = category.dutch_name.toLowerCase();
                    }
                    if (categoryName.includes(searchTerm)) {
                        this.tempCategories.push(category);
                    }
                    if (!this.tempCategories.length) {
                        this.categoriesNoResults = true;
                    }
                    else {
                        this.categoriesNoResults = false;
                    }
                }
            }
            else {
                this.categoriesNoResults = false;
            }
        },
        selectCategory(category) {
            this.categoryDropdownToggled = false;
            this.selectedCategory = category;
            this.tempCategories = [];
            this.categorySearchTerm = '';
            this.categoryError = false;
        },
        createNewIncident() {
            const data = new URLSearchParams();
            data.append('qr_code', this.recording.qr_code);
            data.append('id_incident_category', this.selectedCategory.id_incident_category);
            data.append('id_planned_action', this.selectedAction.id_planned_action)
            data.append('description', this.fields.descr);
            if (!this.fields.descr) {
                this.descriptionError = true;
            }
            if (!this.selectedCategory.id_incident_category) {
                this.categoryError = true;
            }
            if (this.fields.descr && this.selectedCategory.id_incident_category) {
                this.incidentCreate = true;
                let self = this;
                fetch('/api/v1/incident/createsimple', {
                    method: 'post',
                    body: data
                }).then((resp) => {return resp.json();
                }).then(function(data) {
                    if (self.media.length) {
                        self.addMediaIncident(data.id_incident);
                    }
                    self.setCompleted(data.id_incident);
                });
            }
        },
        setCompleted(id) {
            const data = new URLSearchParams();
            data.append('id_planned_action', this.selectedActionId);
            data.append('id_incident_created', id);
            let self = this;
            fetch('/api/v1/plannedaction/setcompleted', {
                method: 'post',
                body: data
            }).then((resp) => {
                return resp.json();
            }).then(function(data) {
                self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                self.getRecording();
                self.repeatingWork = false;
                self.toReport = true;
                self.selectedCategory = {};
                self.directAction = false;
            });
        },
        addMediaIncident(id) {
            for (var i = 0; i < this.media.length; i++) {
                let item = this.media[i];
                const data = new URLSearchParams();
                data.append('id_incident', id);
                data.append('pic', item.file_url);
                let self = this;
                fetch('/api/v1/formechanic/addincidentpic', {
                    method: 'post',
                    body: data
                }).then((resp) => {
                    return resp.json();
                }).then(function(data) {
                });
            }
            for (var i = 0; i < this.movs.length; i++) {
                let item = this.media[i];
                const data = new URLSearchParams();
                data.append('id_incident', id);
                data.append('pic', item.file_url);
                let self = this;
                fetch('/api/v1/formechanic/addincidentpic', {
                    method: 'post',
                    body: data
                }).then((resp) => {
                    return resp.json();
                }).then(function(data) {
                });
            }
            this.$vToastify.success(this.profile.toast.edited, this.profile.toast.changes_saved);
            this.getRecording();
            this.repeatingWork = false;
            this.toReport = true;
        },
        checkDescr() {
            if (this.fields.descr) {
                this.descriptionError = false;
            }
            else {
                this.descriptionError = true;
            }
        },
        deselectAction() {
            this.selectedAction = {};
            this.startFlow = false;
            this.selectedState = '';
        },
        start() {
            this.startFlow = true;
            let element = document.getElementById('bottom_anchor');
            VueScrollTo.scrollTo(element, 200);
        },
        switchAccordeon(accordeon) {
            if (this.selectedState) {
                this.currentAccordeon = accordeon;
                let element = document.getElementById('bottom_anchor');
                VueScrollTo.scrollTo(element, 200);
            }
            else {
                this.stateError = true;
            }
        },
        toAsset() {
            this.$router.push('/monteur/asset-detail?id=' + this.asset.id_asset);
        }
    }
}
</script>
