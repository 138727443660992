<template>
    <div>
        <div class="main-content" v-if="profile.incident_detail">
            <div class="container"> 
                <div class="block block--repaircompanies">
                    <div class="incident-head">
                        <h1>{{profile.invoicing.invoices}}</h1>
                        <span class="edit-button icon" @click="uploadInvoiceModalOpen = true"><i class="fas fa-plus"></i></span>
                    </div>             
                    <div class="incident-indicator">{{profile.invoicing.invoices_for}} {{ incidentId }} ({{ invoiceList.length }})</div>
                    <div class="table-wrap">
                        <table class="table table-striped" v-if="invoiceList.length">
                            <thead>
                                <tr>
                                    <th scope="col" @click="sortTable('invoice_number')" :class="{active: sortType === 'invoice_number'}">{{profile.invoicing.invoice_number}} <i class="fas fa-sort"></i></th>
                                    <th scope="col" @click="sortTable('invoice_value')" :class="{active: sortType === 'invoice_value'}">{{profile.invoicing.invoice_amount}} <i class="fas fa-sort"></i></th>
                                    <th scope="col" @click="sortTable('created')" :class="{active: sortType === 'created'}">{{profile.invoicing.invoice_added}} <i class="fas fa-sort"></i></th>
                                    <th style="width: 50px;"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="item in invoiceList" :key="item.id_place">
                                    <td @click="openInvoice(item)">{{item.invoice_number}}</td>
                                    <td @click="openInvoice(item)">{{item.invoice_value}}</td>
                                    <td @click="openInvoice(item)">{{item.date}}</td>
                                    <td @click="removeInvoice(item)"><i class="fas fa-trash-alt" style="color: #0867b3;"></i></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>      
            </div>
        </div>
        <UploadInvoiceModal v-if="uploadInvoiceModalOpen" @close="uploadInvoiceModalOpen = false" :incidentId="incidentId" :incidentInfo="{}" @success="getInvoices()" />
        <RemoveInvoiceModal v-if="removeInvoiceModalOpen" @close="removeInvoiceModalOpen = false" @success="getInvoices()" :incidentInfo="{}" :invoiceToRemove="invoiceToRemove" />
   </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale.js';
import moment from 'moment';
import sortBy from 'lodash/sortBy';
import UploadInvoiceModal from "@/components/modals/UploadInvoiceModal.vue";
import RemoveInvoiceModal from "@/components/modals/RemoveInvoiceModal.vue";

export default {
    mixins: [setLocale],
    components: {
        UploadInvoiceModal,
        RemoveInvoiceModal
    },
    data() {
        return {
            profile: {},
            invoiceList: [],
            searchTerm: '',
            incidentId: null,
            sortOrder: 'asc',
            sortType: 'invoice_number',
            uploadInvoiceModalOpen: false,
            removeInvoiceModalOpen: false,
            invoiceToRemove: {}
        }
    },
    created() {
        let queryString = window.location.search;
        this.incidentId = queryString.split('=')[1];
        if (this.profile.nav) {
            document.title = 'S1MONE | ' + this.profile.invoicing.invoices;
        }
        this.getInvoices();
    },
    methods: {  
        track() {
            this.$gtag.pageview(this.$route);
        },   
        getInvoices() {
            this.removeInvoiceModalOpen = false;
            let self = this;
            fetch('/api/v1/order/getsupplierinvoices?id_incident=' + this.incidentId)
            .then(response => { response.json().then(
                function(data) {
                    if (data.length) {
                        let invoices = [];
                        for (var i = 0; i < data.length; i++) {
                            let invoice = data[i];
                            if (self.locale === 'nl') {
                                invoice.date = moment(invoice.created).locale('nl').format('D MMM YYYY HH:mm:ss');
                                invoice.description = 'Factuur-' + invoice.invoice_number;
                            }
                            else {
                                invoice.date = moment(invoice.created).format('MMM D YYYY HH:mm:ss');
                                invoice.description = 'Invoice-' + invoice.invoice_number;
                            }
                            if (invoice.invoice_value && invoice.invoice_value.toString().includes('.')) {
                                invoice.invoice_value = (Math.round(invoice.invoice_value * 100) / 100).toFixed(2);
                                invoice.invoice_value = invoice.invoice_value.toString().replaceAll('.', ',');
                            } 
                            invoices.push(invoice);
                        }
                        self.invoiceList = invoices;
                        self.invoiceList = sortBy(self.invoiceList, 'invoice_number');

                    }                 
                });
            });
        },
        sortTable(sorter) {
            this.sortType = sorter;
            this.sortOrder = this.sortOrder !== 'asc' ? 'asc' : 'desc';
            this.invoiceList = sortBy(this.invoiceList, sorter);
            if (this.sortOrder === 'desc') {
                this.invoiceList.reverse();
            }
        },
        openInvoice(item) {
            window.open(item.file_url,'_newtab');
        },
        removeInvoice(invoice) {
            this.invoiceToRemove = invoice;
            this.removeInvoiceModalOpen = true;
        }
    }
}
</script>
