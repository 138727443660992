<template>
    <div class="block block--account block--border" style="margin-bottom: 0;">
        <div class="account-info">
            <h4>{{profile.mandates.default_mandates}} <div class="premium-label" v-tooltip.right="profile.accountPage.account.payed_functionality"><i class="fas fa-euro-sign"></i> Premium</div>
                <span class="edit-button edit-button--small" data-testid="mandateCreateButton" @click="createNewMandate = true" v-if="accountInfo.building_manager_admin"><i class="fas fa-plus" style="font-size: 18px; margin: 0;"></i></span>
            </h4>
            <div class="account-contents">
                <div class="row" v-if="createNewMandate">
                    <div class="col">
                        <div>
                            <div class="row">
                                <div class="col-sm-6">
                                    <label>Level</label>
                                    <p data-testid="mandateLevelInput">{{tempMandate.level}}</p>
                                </div>
                                <div class="col-sm-6">
                                    <label>{{profile.accountPage.account.amount}}</label>
                                    <input type="number" class="form-control" data-testid="mandateAmountInput" v-model="tempMandate.amount" />
                                </div>
                            </div>
                            <label>{{profile.employees.name}}</label>
                            <input type="text" class="form-control" data-testid="mandateNameInput" v-model="tempMandate.name" />
                            <div class="switch-wrapper ">
                                <label class="switch">
                                    <input type="checkbox" v-model="tempMandate.in_btw">
                                    <span class="slider round" data-testid="mandateBtwSlider"></span>
                                </label>
                                <span><b>Incl. BTW</b></span>
                            </div>
                            <span class="button button--right button--icon" data-testid="mandateCreateSubmit" style="margin-top: 10px;" @click="createDefaultMandate()"><i class="fas fa-plus"></i>{{profile.mandates.create_mandate}}</span>
                            <div class="errors" v-if="nameError || amountError">
                                <span class="notification notification--error" data-testid="mandateNameError" v-if="nameError">{{profile.mandates.name_error}}</span>
                                <span class="notification notification--error" data-testid="mandateAmountError" v-if="amountError">{{profile.mandates.amount_error}}</span>
                            </div>
                            <hr style="float: left; width: 100%;" />
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <div v-for="defaultMandate in defaultMandates" :key="defaultMandate.id_default_mandate" class="mandate">
                            <span class="edit-button edit-button--small" data-testid="mandateEditButton" @click="defaultMandate.edit = true" v-if="!defaultMandate.edit && accountInfo.building_manager_admin" style="margin-right: 15px; margin-top: 10px;"><i class="fas fa-pen"></i></span>
                            <span class="edit-button edit-button--small" data-testid="mandateSaveButton" @click="saveDefaultMandate(defaultMandate)" v-if="defaultMandate.edit" style="margin-right: 15px; margin-top: 10px;"><i class="fas fa-save"></i></span>
                            <span class="edit-button edit-button--small" data-testid="mandateDeleteButton" @click="deleteDefaultMandate(defaultMandate)" v-if="defaultMandate.allowedDelete && accountInfo.building_manager_admin" style="margin-right: 40px; margin-top: 10px;"><i class="far fa-trash-alt"></i></span>
                            <div v-if="!defaultMandate.edit">
                                <h6 data-testid="mandateNameText" style="margin-bottom: 10px;">{{defaultMandate.name}}</h6>
                                <div class="row">
                                    <div class="col-sm-6">
                                        <label>Level</label>
                                        <p data-testid="mandateLevelText" style="margin-bottom: 0;">{{defaultMandate.level}}</p>
                                    </div>
                                    <div class="col-sm-6">
                                        <label>{{profile.accountPage.account.amount}}</label>
                                        <p data-testid="mandateAmountText" style="margin-bottom: 0;">€ {{defaultMandate.amount}}</p>
                                        <span data-testid="mandateBtwInText" class="btw" style="float: right; padding: 3px 5px; margin-top: -3px;" v-if="defaultMandate.in_btw">Incl. BTW</span>
                                        <span data-testid="mandateBtwOutText" class="btw btw--out" style="float: right; padding: 3px 5px; margin-top: -3px;" v-else>{{profile.mandates.out_btw}}</span>
                                    </div>
                                </div>
                            </div>
                            <div v-else style="padding-top: 15px;">
                                <div class="row">
                                    <div class="col-sm-6">
                                        <label>Level</label>
                                        <p data-testid="mandateLevelEdit" style="margin-bottom: 0;">{{defaultMandate.level}}</p>
                                    </div>
                                    <div class="col-sm-6">
                                        <label>{{profile.accountPage.account.amount}}</label>
                                        <input data-testid="mandateAmountEdit" type="number" class="form-control" v-model="defaultMandate.amount" />
                                    </div>
                                </div>
                                <label>{{profile.employees.name}}</label>
                                <input data-testid="mandateNameEdit" type="text" class="form-control" v-model="defaultMandate.name" />
                                <div class="switch-wrapper ">
                                    <label class="switch">
                                        <input type="checkbox" v-model="defaultMandate.in_btw">
                                        <span data-testid="mandateBtwEdit" class="slider round"></span>
                                    </label>
                                    <span><b>Incl. BTW</b></span>
                                </div>
                                <div class="errors">
                                    <span data-testid="mandateNameErrorEdit" class="notification notification--error" v-if="nameError">{{profile.mandates.name_error}}</span>
                                    <span data-testid="mandateAmountErrorEdit" class="notification notification--error" v-if="amountError">{{profile.mandates.amount_error}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale.js';
import sortBy from 'lodash/sortBy';
import 'core-js/features/array/at';

export default {
    name: 'MandatesBlock',
    mixins: [setLocale],
    data: function () {
        return {
            profile: {},
            defaultMandates: [],
            createNewMandate: false,
            tempMandate: {
                name: '',
                amount: 0,
                in_btw: false,
                level: 1
            },
            lastMandateAmount: 0,
            lastMandateLevel: 0,
            amountError: false,
            nameError: false
        }
    },
    props: ['accountInfo'],
    created() {
        this.getDefaultMandates();
    },
    methods: {
        getDefaultMandates() {
            let self = this;
            fetch('/api/v1/defaultmandate')
            .then(response => { response.json().then(
                function(data) {
                    self.createNewMandate = false;
                    self.amountError = false;
                    self.nameError = false;
                    self.defaultMandates = [];
                    if (data.length) {
                        for (var i = 0; i < data.length; i++) {
                            let mandate = data[i];
                            mandate.edit = false;
                            self.defaultMandates.push(mandate);
                        }
                        self.lastMandateAmount = self.defaultMandates.at(-1).amount;
                        self.lastMandateLevel = self.defaultMandates.at(-1).level;
                        self.tempMandate.level = self.defaultMandates.at(-1).level + 1;
                        self.defaultMandates = sortBy(self.defaultMandates, 'level');
                        self.defaultMandates.at(-1).allowedDelete = true;
                    }
                    else {
                        self.tempMandate.level = 1;
                        self.lastMandateLevel = 0;
                        self.lastMandateAmount = 0;
                    }
                });
            });
        },
        createDefaultMandate() {
            const data = new URLSearchParams();
            data.append('name', this.tempMandate.name);
            data.append('amount', this.tempMandate.amount);
            data.append('in_btw', this.tempMandate.in_btw);
            data.append('level', this.tempMandate.level);
            if (!this.tempMandate.name.length) {
                this.nameError = true;
            }
            else {
                this.nameError = false;
            }
            if (this.tempMandate.amount <= this.lastMandateAmount) {
                this.amountError = true;
            }
            else {
                this.amountError = false;
            }
            if (!this.nameError && !this.amountError) {
                let self = this;
                fetch('/api/v1/defaultmandate/create', {
                    method: 'post',
                    body: data
                }).then((resp) => {return resp.text();
                }).then(function(data) {
                    self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                    self.getDefaultMandates();
                });
            }
        },
        saveDefaultMandate(defaultMandate) {
            let indexPrev;
            for (var i = 0; i < this.defaultMandates.length; i++) {
                let mandate = this.defaultMandates[i];
                if (mandate.id_default_mandate === defaultMandate.id_default_mandate) {
                    indexPrev = i - 1;
                }
            }
            if (indexPrev !== -1) {
                this.lastMandateAmount = this.defaultMandates[indexPrev].amount;
            }
            const data = new URLSearchParams();
            data.append('id_default_mandate', defaultMandate.id_default_mandate);
            data.append('name', defaultMandate.name);
            data.append('amount', defaultMandate.amount);
            data.append('in_btw', defaultMandate.in_btw);
            data.append('level', defaultMandate.level);
            if (!defaultMandate.name.length) {
                this.nameError = true;
            }
            else {
                this.nameError = false;
            }
            if (defaultMandate.amount <= this.lastMandateAmount) {
                this.amountError = true;
            }
            else {
                this.amountError = false;
            }
            if (!this.nameError && !this.amountError) {
                defaultMandate.edit = false;
                let self = this;
                fetch('/api/v1/defaultmandate/update', {
                    method: 'post',
                    body: data
                }).then((resp) => {return resp.text();
                }).then(function(data) {
                    self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                    self.getDefaultMandates();
                });
            }
        },
        deleteDefaultMandate(defaultMandate) {
            const data = new URLSearchParams();
            data.append('id_default_mandate', defaultMandate.id_default_mandate);
            let self = this;
            fetch('/api/v1/defaultmandate/delete', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.text();
            }).then(function(data) {
                self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                self.getDefaultMandates();
            });
        }
    }
}
</script>
