<template>
    <div class="modal-wrapper" v-if="profile.actionable">
        <div class="modal">
            <div class="modal-header">
                <h3><i class="fas fa-redo"></i>{{profile.actionable.edit_repeating_action}}</h3>
                <img src="@/assets/img/logo-white.png" class="logo" />
            </div>
            <div class="modal-inner higher">
                <span class="close" @click="closeModal()"><i class="fas fa-times"></i></span>
                <form v-on:submit.prevent="editRepeatingAction()">
                    <label>{{profile.actionable.work}}</label>
                    <textarea class="form-control" v-model="fields.action" style="margin-bottom: 10px;" :class="{error: workError}"></textarea>
                    <div class="row">
                        <div class="col">
                            <label>{{profile.actionable.frequency}}</label>
                            <div class="dropdown-section">
                                <span class="dropdown" v-on:click="frequencyDropdownToggled = !frequencyDropdownToggled" :class="{active: frequencyDropdownToggled, error: frequencyError}">
                                    <span v-if="!fields.frequency">{{profile.actionable.frequency}} *</span>
                                    <span v-if="fields.frequency" class="selected">{{fields.frequency}}</span>
                                    <i class="fas fa-chevron-down chevron"></i>
                                </span>
                                <div class="dropdown-list" v-if="frequencyDropdownToggled">
                                    <ul>
                                        <li v-for="(option, index) in frequencyOptions" :key="index" @click="selectFrequency(option)">{{option}}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <label>{{ profile.incident_detail.category }}</label>
                    <div class="row">
                        <div class="col">
                            <div class="dropdown-section">
                                <span class="dropdown" v-on:click="categoryDropdownToggled = !categoryDropdownToggled" :class="{active: categoryDropdownToggled, error: categoryError, valid: selectedCategory.dutch_name}">
                                    <span v-if="!selectedCategory.id_incident_category">{{profile.incident_detail.category}} *</span>
                                    <span v-if="selectedCategory.id_incident_category && locale === 'nl'" class="selected">{{selectedCategory.dutch_name}}</span>
                                    <span v-if="selectedCategory.id_incident_category && locale === 'en'" class="selected">{{selectedCategory.english_name}}</span>
                                    <i class="fas fa-chevron-down chevron"></i></span>
                                <div class="dropdown-list" v-if="categoryDropdownToggled" :class="{search: categories.length > 9}">
                                    <input type="text" @input="e => categorySearchTerm = e.target.value" :value="categorySearchTerm" :placeholder="profile.incident_detail.search_category" @keyup="searchCategories()" v-if="categories.length > 9"  />
                                    <ul>
                                        <li v-for="category in categories" v-on:click="selectCategory(category)" :key="category.id_category" v-show="!tempCategories.length && !categoriesNoResults">
                                            <span v-if="locale === 'nl'">{{category.dutch_name}}</span>
                                            <span v-if="locale === 'en'">{{category.english_name}}</span>
                                        </li>
                                        <li v-for="(category, index) in tempCategories" v-on:click="selectCategory(category)" :key="index" v-show="tempCategories.length">
                                            <span v-if="locale === 'nl'">{{category.dutch_name}}</span>
                                            <span v-if="locale === 'en'">{{category.english_name}}</span>
                                        </li>
                                        <li v-if="categoriesNoResults" class="no-results">{{profile.dashboard.building_manager.no_resultss}}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <label>{{profile.actionable.suggestion_first_action}}</label>
                    <flat-pickr v-model="fields.firstAction" class="form-control" :config="flatPickrDateConfig" :placeholder="profile.incident_detail.pick_date" @on-change="firstActionError = false"></flat-pickr>
                    <div class="notification notification--error" v-if="firstActionError">{{ profile.actionable.first_action_error }}</div>
                    <button type="submit" class="button button--icon"><i class="fas fa-redo"></i>{{ profile.actionable.edit_repeating_action}}</button>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale.js';
import { mapState } from 'vuex';
import {Dutch} from 'flatpickr/dist/l10n/nl.js';
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import moment from 'moment';
import uniqBy from 'lodash/uniqBy';
import sortBy from 'lodash/sortBy';

export default {
    mixins: [setLocale],
    components: {
        flatPickr
    },
    data() {
        return {
            profile: {},
            fields: {
                action: '',
                frequency: '',
                firstAction: '',
                firstActionEpoch: ''
            },
            frequencyDropdownToggled: false,
            flatPickrDateConfig: {
                altInput: true,
                altFormat: "F j, Y",
                dateFormat: "Y-m-d"
            },
            tomorrow: null,
            frequencyError: false,
            firstActionError: false,
            workError: false,
            frequencyOptions: [],
            categories: [],
            selectedCategory: {},
            categoryDropdownToggled: false,
            categorySearchTerm: '',
            categoryError: false,
            tempCategories: [],
            categoriesNoResults: false
        }
    },
    computed: mapState(['bmId']),
    props: ['selectedAction'],
    created() {
        console.log(this.selectedAction);
        this.getFrequencyOptions();
        this.getCategories();
        document.querySelector('body').classList.add('modal-open');
        let self = this;
        document.onkeydown = function(evt) {
            evt = evt || window.event;
            if (evt.keyCode == 27) {
                self.closeModal();
            }
        };
        if (this.locale === 'nl') {
            this.flatPickrDateConfig.locale = Dutch;
            this.flatPickrDateConfig.altFormat = "j F, Y";
        }
        this.tomorrow = new Date();
        this.tomorrow.setDate(this.tomorrow.getDate()+1);
        this.tomorrow = moment(this.tomorrow).format();
        this.tomorrow = this.tomorrow.split('T')[0];
        this.fields.action = this.selectedAction.description;
        if (this.selectedAction.frequency === 'WEEKLY' && this.locale === 'nl') {
            this.fields.frequency = 'Wekelijks';
        }
        if (this.selectedAction.frequency === 'MONTHLY' && this.locale === 'nl') {
            this.fields.frequency = 'Maandelijks';
        }
        if (this.selectedAction.frequency === 'QUARTERLY' && this.locale === 'nl') {
            this.fields.frequency = 'Per kwartaal';
        }
        if (this.selectedAction.frequency === 'YEARLY' && this.locale === 'nl') {
            this.fields.frequency = 'Jaarlijks';
        }
        if (this.selectedAction.frequency === 'YEARLY' && this.locale === 'nl') {
            this.fields.frequency = 'Jaarlijks';
        }
        if (this.selectedAction.frequency === 'YEAR_AND_A_HALFLY' && this.locale === 'nl') {
            this.fields.frequency = 'Elke 1.5 jaar';
        }
        if (this.selectedAction.frequency === 'THREE_YEARLY' && this.locale === 'nl') {
            this.fields.frequency = 'Elke 3 jaar';
        }
        if (this.selectedAction.frequency === 'FOUR_YEARLY' && this.locale === 'nl') {
            this.fields.frequency = 'Elke 4 jaar';
        }
        if (this.selectedAction.frequency === 'FIVE_YEARLY' && this.locale === 'nl') {
            this.fields.frequency = 'Elke 5 jaar';
        }
        if (this.selectedAction.frequency === 'WEEKLY' && this.locale === 'en') {
            this.fields.frequency = 'Weekly';
        }
        if (this.selectedAction.frequency === 'MONTHLY' && this.locale === 'en') {
            this.fields.frequency = 'Monthly';
        }
        if (this.selectedAction.frequency === 'QUARTERLY' && this.locale === 'en') {
            this.fields.frequency = 'Quarterly';
        }
        if (this.selectedAction.frequency === 'YEARLY' && this.locale === 'en') {
            this.fields.frequency = 'Yearly';
        }
        if (this.selectedAction.frequency === 'YEAR_AND_A_HALFLY' && this.locale === 'en') {
            this.fields.frequency = 'Every 1,5 years';
        }
        if (this.selectedAction.frequency === 'TWO_YEARLY' && this.locale === 'en') {
            this.fields.frequency = 'Two yearly';
        }
        if (this.selectedAction.frequency === 'THREE_YEARLY' && this.locale === 'en') {
            this.fields.frequency = 'Three yearly';
        }
        if (this.selectedAction.frequency === 'FOUR_YEARLY' && this.locale === 'en') {
            this.fields.frequency = 'Four yearly';
        }
        if (this.selectedAction.frequency === 'FIVE_YEARLY' && this.locale === 'en') {
            this.fields.frequency = 'Five yearly';
        }
        this.fields.firstAction = this.selectedAction.start_date;
    },
    methods: {
        getCategories() {
            let self = this;
            fetch('/api/v1/incident_category/bybuildingmanager?id_building_manager=' + this.bmId)
            .then(response => { response.json().then(
                function(data) {
                    self.categories = data.data;
                    self.categories = uniqBy(self.categories, 'id_incident_category');
                    for (var i = 0; i <self.categories.length; i++) {
                        let category = self.categories[i];
                        category.dutch_name = category.dutch_name.split('*')[0];
                        category.english_name = category.english_name.split('*')[0];
                        if (category.dutch_name.includes('-2') || category.dutch_name.includes('-3')|| category.dutch_name.includes('-4')) {
                            self.categories.splice(i, 1);
                        }
                        if (category.id_incident_category === self.selectedAction.id_incident_category) {
                            self.selectedCategory = category;
                        }
                    }
                    if (self.locale === 'nl') {
                        self.categories = sortBy(self.categories, 'dutch_name');
                    }
                    else {
                        self.categories = sortBy(self.categories, 'english_name');
                    }
                });
            });
        },
        searchCategories() {
            this.tempCategories = [];
            if (this.categorySearchTerm.length > 1) {
                let searchTerm = this.categorySearchTerm.toLowerCase();
                for (var i = 0; i < this.categories.length; i++) {
                    let category = this.categories[i];
                    let categoryName;
                    if (this.locale === 'en') {
                        categoryName = category.english_name.toLowerCase();
                    }
                    else {
                        categoryName = category.dutch_name.toLowerCase();
                    }
                    if (categoryName.includes(searchTerm)) {
                        this.tempCategories.push(category);
                    }
                    if (!this.tempCategories.length) {
                        this.categoriesNoResults = true;
                    }
                    else {
                        this.categoriesNoResults = false;
                    }
                }
            }
            else {
                this.categoriesNoResults = false;
            }
        },
        selectCategory(category) {
            this.categoryDropdownToggled = false;
            this.selectedCategory = category;
            this.tempCategories = [];
            this.categorySearchTerm = '';
            this.categoryError = false;
        },
        getFrequencyOptions() {
            let self = this;
            fetch('/api/v1/recurringaction/frequencyoptions')
            .then(response => { response.json().then(
                function(data) {
                    self.frequencyOptions = data;
                    console.log(data);
                });
            });
        },
        editRepeatingAction() {
            if (!this.fields.action) {
                this.workError = true;
            }
            else {
                this.workError = false;
            }
            if (!this.workError) {
                let date = new Date(this.fields.firstAction)
                this.fields.firstActionEpoch = date.getTime();
                let frequency;
                if (this.fields.frequency === 'Wekelijks') {
                    frequency = 'WEEKLY'
                }
                if (this.fields.frequency === 'Maandelijks') {
                    frequency = 'MONTHLY'
                }
                if (this.fields.frequency === 'Per kwartaal') {
                    frequency = 'QUARTERLY'
                }
                if (this.fields.frequency === 'Jaarlijks') {
                    frequency = 'YEARLY'
                }
                if (this.fields.frequency === 'Elke 1.5 jaar') {
                    frequency = 'YEAR_AND_A_HALFLY'
                }
                if (this.fields.frequency === 'Elke 2 jaar') {
                    frequency = 'TWO_YEARLY'
                }
                if (this.fields.frequency === 'Elke 3 jaar') {
                    frequency = 'THREE_YEARLY'
                }
                if (this.fields.frequency === 'Elke 4 jaar') {
                    frequency = 'FOUR_YEARLY'
                }
                if (this.fields.frequency === 'Elke 5 jaar') {
                    frequency = 'FIVE_YEARLY'
                }
                const data = new URLSearchParams();
                data.append('id_recurring_action', this.selectedAction.id_recurring_action);
                data.append('id_asset', this.selectedAction.id_asset);
                data.append('frequency', frequency);
                data.append('start_date', this.fields.firstActionEpoch);
                data.append('id_incident_category', this.selectedCategory.id_incident_category);
                data.append('description', this.fields.action);
                let self = this;
                fetch('/api/v1/recurringaction/update', {
                    method: 'post',
                    body: data
                }).then((resp) => {return resp.json();
                }).then(function(data) {
                    self.$emit('success');
                    self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                    self.closeModal();
                });
            }
        },
        selectFrequency(frequency) {
            this.frequencyDropdownToggled = false;
            this.fields.frequency = frequency;
            this.frequencyError = false;
        },
        closeModal() {
            this.$emit('close');
            document.querySelector('body').classList.remove('modal-open');
        }
    }
}
</script>
