<template>
    <div class="autodispatch" v-if="profile.accountPage">
        <div class="button-wrap--left" v-if="admin">
            <span class="button button--icon" :class="{outline: !accountInfo.autodispatch_24_7}" @click="setAutoDispatch_24_7(true)" data-testid="autoAlwaysButton"><i class="fas fa-calendar"></i>{{profile.accountPage.account.autodispatch_always_active}}</span>
            <span class="button button--icon" :class="{outline: accountInfo.autodispatch_24_7}" @click="setAutoDispatch_24_7(false)" data-testid="autoScheduledButton"><i class="fas fa-clock"></i>{{profile.accountPage.account.autodispatch_active_times}}</span>
        </div>
        <br v-if="admin" /><br v-if="admin" /><br v-if="admin" />
        <div class="notification notification--form" v-if="!accountInfo.autodispatch_24_7">
            <span>{{profile.accountPage.account.autodispatch_active_from}}</span>
            <flat-pickr v-model="accountInfo.autodispatch_from" class="form-control" :config="flatPickrTimeConfig" data-testid="autoInputFrom"  :placeholder="'09:00'" :disabled="!admin"></flat-pickr>
            <span>{{profile.accountPage.account.autodispatch_active_until}}</span>
            <flat-pickr v-model="accountInfo.autodispatch_to" class="form-control" :config="flatPickrTimeConfig" data-testid="autoInputUntil" :placeholder="'09:00'" :disabled="!admin"></flat-pickr>
            <button class="button button--small" @click="saveAutoDispatchTimes()" v-if="admin" data-testid="autoSaveButton">{{profile.buttons.save}}</button>
        </div>
        <div class="autodispatch-row" v-for="(rule, index) in accountInfo.buildingmanagerincidentcategoryrepaircompanies" :key="index">
            <span class="remove" data-testid="autoDeleteButton" @click="removeAutodispatchRule(rule)" v-if="admin"><i class="fa fa-times"></i></span>
            <div class="row">
                <div class="col-sm-6">
                    <label><b>{{profile.incident_detail.category}}</b></label>
                    <p data-testid="autoCategoryText" v-if="locale === 'nl'">{{rule.dutch_name.toLowerCase()}}</p>
                    <p data-testid="autoCategoryText" v-if="locale === 'en'">{{rule.english_name.toLowerCase()}}</p>
                </div>
                <div class="col-sm-6">
                    <label><b>{{profile.incidents.incidenttable.repair_company}}</b></label>
                    <p data-testid="autoSupplierText">{{rule.repair_company_name}}</p>
                </div>
            </div>
        </div>
        <hr v-if="admin" />
        <h4 v-if="admin">{{profile.accountPage.account.add_line}}</h4>
        <div class="row" v-if="admin">
            <div class="col-sm-6">
                <label><b>{{profile.incident_detail.category}}</b></label>
                <select class="form-select" v-model="selectedCategory" data-testid="autoCategorySelect1" v-if="locale === 'nl'">
                    <option :value="null" disabled>{{profile.pleasechoose}}</option>
                    <option v-for="(cat, index) in accountInfo.incidentcategories" :key="index" :value="cat.id_incident_category">{{cat.dutch_name}}</option>
                </select>
                <select class="form-select" v-model="selectedCategory" data-testid="autoCategorySelect" v-if="locale === 'en'">
                    <option :value="null" disabled>{{profile.pleasechoose}}</option>
                    <option v-for="(cat, index) in accountInfo.incidentcategories" :key="index" :value="cat.id_incident_category">{{cat.english_name}}</option>
                </select>
            </div>
            <div class="col-sm-6">
                <label><b>{{profile.incidents.incidenttable.repair_company}}</b></label>
                <select class="form-select" data-testid="autoMechanicSelect" v-model="selectedRep">
                    <option :value="null" disabled>{{profile.pleasechoose}}</option>
                    <option v-for="(rep, index) in accountInfo.repaircompanies" :key="index" :value="rep.id_repair_company">{{rep.name}}</option>
                </select>
            </div>
        </div>
        <br />
        <div class="notification notification--error" v-if="unableAddError">{{profile.accountPage.account.is_autodispatch}}</div>
        <br />
        <button class="button button--right button--icon" @click="createAutodispatchRule()" v-if="admin" data-testid="autoAddButton"><i class="fas fa-plus"></i>{{profile.buttons.add}}</button>
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale.js';
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import VueScrollTo from 'vue-scrollto';

export default {
    mixins: [setLocale],
    components: {
        flatPickr
    },
    name: 'autoDispatch',
    data() {
        return {
            profile: {},
            selectedCategory: null,
            selectedRep: null,
            flatPickrTimeConfig: {
                enableTime: true,
                noCalendar: true,
                dateFormat: "H:i",
                time_24hr: true
            },
            unableAddError: false
        }
    },
    props: ['accountInfo', 'admin'],
    methods: {
        setAutoDispatch_24_7(val) {
            this.accountInfo.autodispatch_24_7 = val;
            const data = new URLSearchParams();
            data.append('action', 'edit');
            data.append('data[0][autodispatch_24_7]', val);
            let self = this;
            fetch('/api/v1/buildingmanager', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.json();
            }).then(function(data) {
                self.companyInfoEdit = false;
                self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
            });
        },
        createAutodispatchRule() {
            if (this.accountInfo.canbe_id_repair_company === this.selectedRep) {
                this.unableAddError = true;
            }
            else {
                const data = new URLSearchParams();
                data.append('id_incident_category', this.selectedCategory);
                data.append('id_repair_company', this.selectedRep);
                let self = this;
                fetch('/api/v1/buildingmanagerincidentcategoryrepaircompany/create', {
                    method: 'post',
                    body: data
                }).then((resp) => {return resp.json();
                }).then(function(data) {
                    self.$emit('save');
                    self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                    self.unableAddError = false;
                    self.selectedRep = null;
                    self.selectedCategory = null;
                    setTimeout(function () {
                        var options = {
                            offset: -60
                        }
                        let element = document.getElementById('autodispatchBlock');
                        console.log(element);
                        VueScrollTo.scrollTo(element, 200, options);
                    }.bind(this), 500) 
                });
            }
        },
        removeAutodispatchRule(rule) {
            const data = new URLSearchParams();
            data.append('id_building_manager_incident_category_repair_company', rule.id_building_manager_incident_category_repair_company);
            let self = this;
            fetch('/api/v1/buildingmanagerincidentcategoryrepaircompany/delete', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.json();
            }).then(function(data) {
                self.$emit('save');
                self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                setTimeout(function () {
                    var options = {
                        offset: -60
                    }
                    let element = document.getElementById('autodispatchBlock');
                    console.log(element);
                    VueScrollTo.scrollTo(element, 200, options);
                }.bind(this), 500)              
            });
        },
        editAutodispatchRuleCategory(rule) {
            const data = new URLSearchParams();
            data.append('id_building_manager_incident_category_repair_company', rule.id_building_manager_incident_category_repair_company);
            data.append('id_incident_category', rule.id_incident_category);
            let self = this;
            fetch('/api/v1/buildingmanagerincidentcategoryrepaircompany/updateincidentcategory', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.json();
            }).then(function(data) {
                self.$emit('save');
                self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
            });
        },
        editAutodispatchRuleRepaircompany(rule) {
            const data = new URLSearchParams();
            data.append('id_building_manager_incident_category_repair_company', rule.id_building_manager_incident_category_repair_company);
            data.append('id_repair_company', rule.id_repair_company);
            let self = this;
            fetch('/api/v1/buildingmanagerincidentcategoryrepaircompany/updaterepaircompany', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.json();
            }).then(function(data) {
                self.$emit('save');
                self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
            });
        },
        saveAutoDispatchTimes() {
            const params = new URLSearchParams();
            params.append('action', 'edit');
            params.append('data[0][autodispatch_from]', this.accountInfo.autodispatch_from);
            params.append('data[0][autodispatch_to]', this.accountInfo.autodispatch_to);
            let self = this;
            fetch('/api/v1/buildingmanager', {
                method: 'post',
                body: params
            }).then((resp) => {return resp.text();
            }).then(function(data) {
                self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
            });
        }
    }
}
</script>
