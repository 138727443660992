<template>
    <div class="block block--account block--border" style="margin-bottom: 0;">
        <div class="account-info">
            <h4>{{profile.accountPage.company.my_info}}
                <span class="edit-button edit-button--small" @click="editMyInfo()" v-if="!myInfoEdit"><i class="fas fa-pen" data-testid="personalEditButton"></i></span>
                <span class="edit-button edit-button--small" @click="saveMyInfo()" v-if="myInfoEdit"><i class="fas fa-save" data-testid='personalSaveButton'></i></span>
            </h4>
            <div class="account-contents">
                <div v-if="!myInfoEdit">
                    <div class="row">
                        <div class="col-sm-6">
                            <label>{{profile.employees.name}}</label>
                            <p data-testid="userNameText">{{currentUser.actual_name}}</p>
                        </div>
                        <div class="col-sm-6">
                            <label>{{profile.accountPage.account.contactmobile}}</label>
                            <p data-testid="userMobileText" style="margin-bottom: 0;">{{currentUser.mobile}}</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-6">
                            <label>Portfolio</label>
                            <span class="portfolio" @click="portFolioModal(currentUser)"><i class="fas fa-briefcase" data-testid="personalPortfolioOpen"></i>Portfolio</span>
                        </div>
                        <div class="col-sm-6">
                            <label>{{profile.nav.account.account}}</label>
                            <p data-testid="userEmailText">{{currentUser.username}}</p>
                        </div>
                    </div>
                </div>
                <div v-else>
                    <div class="row">
                        <div class="col-sm-6">
                            <label>{{profile.employees.name}}</label>
                            <input type="text" class="form-control" v-model="currentUser.actual_name" data-testid="personalInputName" />
                        </div>
                        <div class="col-sm-6">
                            <label>{{profile.accountPage.account.contactmobile}}</label>
                            <input type="text" class="form-control" v-model="tempWaPhone" @input="validatePhone()" :class="{error: invalidPhone}" data-testid="personalInputPhone" >
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-6">
                        <label>{{profile.employees.MFA}}</label>
                        <div class="switch-wrapper" v-if="accountInfo.building_manager_admin" style="margin: 0 0 10px 0;">
                            <label class="switch">
                                <input type="checkbox" v-model="currentUser.mfa_required" @change="toggleMfa(currentUser)" >
                                <span class="slider round"></span>
                            </label>
                            <span>{{profile.employees.MFA}}</span>
                        </div>
                    </div>          
                </div>
                <hr style="margin-top: 0;" v-if="accountInfo.updates_via_wa_active" />
                <label>{{profile.accountPage.account.notifications_via_email}}</label>
                <div class="row">
                    <div class="col-sm-6">
                        <div class="switch-wrapper" style="margin-bottom: 20px;">
                            <label class="switch">
                                <input type="checkbox" v-model="currentUser.notify_incident_assigned" @change="toggleNotsWA(currentUser)" >
                                <span class="slider round"></span>
                            </label>
                            <span style="font-weight: 100;"><i class="fas fa-at"></i> {{profile.accountPage.account.notifications_assign}}</span>
                        </div>
                    </div>
                </div>
                <label v-if="accountInfo.updates_via_wa_active">{{profile.accountPage.account.notifications_via_wa}} <div class="premium-label" v-tooltip.right="profile.accountPage.account.payed_functionality_inactive" style="padding: 1px 5px;"><i class="fas fa-euro-sign"></i> Premium</div></label>
                <div class="row" v-if="accountInfo.updates_via_wa_active">
                    <div class="col-sm-6">
                        <div class="switch-wrapper" v-if="accountInfo.updates_via_wa_active" :class="{disabled: !currentUser.canEditWA}" @click="canEditWACheck(currentUser)">
                            <label class="switch">
                                <input type="checkbox" v-model="currentUser.notify_whatsapp_new_incident" :disabled="!currentUser.canEditWA" @change="toggleNotsWA(currentUser)" >
                                <span class="slider round" data-testid="personalNotificationsSwitch"></span>
                            </label>
                            <span style="font-weight: 100;"><i class="fab fa-whatsapp"></i> {{profile.accountPage.account.new_incidents_wa}}</span>
                        </div>
                        <div class="switch-wrapper" v-if="accountInfo.updates_via_wa_active" :class="{disabled: !currentUser.canEditWA}" @click="canEditWACheck(currentUser)">
                            <label class="switch">
                                <input type="checkbox" v-model="currentUser.notify_whatsapp_status" :disabled="!currentUser.canEditWA" @change="toggleNotsWA(currentUser)" >
                                <span class="slider round" data-testid="personalStatusSwitch"></span>
                            </label>
                            <span style="font-weight: 100;"><i class="fab fa-whatsapp"></i> {{profile.accountPage.account.status_updates_wa}}</span>
                        </div>
                        <div class="switch-wrapper" v-if="accountInfo.updates_via_wa_active" :class="{disabled: !currentUser.canEditWA}" @click="canEditWACheck(currentUser)">
                            <label class="switch">
                                <input type="checkbox" v-model="currentUser.notify_whatsapp_chat" :disabled="!currentUser.canEditWA" @change="toggleNotsWA(currentUser)" >
                                <span class="slider round" data-testid="personalMessagesSwitch"></span>
                            </label>
                            <span style="font-weight: 100;"><i class="fab fa-whatsapp"></i> {{profile.accountPage.account.chat_updates_wa}}</span>
                        </div>
                        <br v-if="currentUser.notAllowedWA" />
                        <span class="notification notification--error" v-if="currentUser.notAllowedWA">
                            {{profile.employees.wa_not_allowed}}
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <PortfolioModal v-if="portfolioModalOpen" @close="portfolioModalOpen = false" :selectedUser="selectedUser" />
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale.js';
import PortfolioModal from "@/components/modals/PortfolioModal.vue";

export default {
    name: 'CompanyBlock',
    mixins: [setLocale],
    components: {
        PortfolioModal
    },
    data: function () {
        return {
            myInfoEdit: false,
            tempWaPhone: '',
            validPhone: false,
            invalidPhone: false,
            selectedUser: {},
            currentUser: {},
            portfolioModalOpen: false
        }
    },
    props: ['accountInfo', 'userName'],
    created() {
        this.getEmployees();
    },
    methods: {
        getEmployees() {
            let self = this;
            fetch('/api/v1/buildingmanageremployee')
            .then(response => { response.json().then(
                function(data) {
                    for (var i = 0; i < data.data.length; i++) {
                        let item = data.data[i];
                        if (item.username === self.userName) {
                            self.currentUser = item;
                            console.log(self.currentUser);
                            if (self.currentUser.mobile) {
                                self.currentUser.canEditWA = true;
                            }
                            if (self.accountInfo.updates_via_wa_active) {
                                self.tempWaPhone = self.currentUser.mobile.replace('+31', '0');
                            }
                        }
                    }
                });
            });
        },
        editMyInfo() {
            this.myInfoEdit = true;
            this.tempWaPhone = this.currentUser.mobile;
            if (this.currentUser.mobile) {
                this.currentUser.canEditWA = true;
            }
            else {
                this.currentUser.canEditWA = false;
            }
        },
        saveMyInfo() {
            if (this.accountInfo.updates_via_wa_active && this.invalidPhone) {
                return;
            }
            if ((!this.invalidPhone)) {
                this.myInfoEdit = false;
                const data = new URLSearchParams();
                let nameParam = 'data[0][actual_name]';
                let mobileParam = 'data[0][mobile]';
                data.append('action', 'edit');
                data.append(nameParam, this.currentUser.actual_name);
                data.append(mobileParam, this.tempWaPhone);
                let self = this;
                fetch('/api/v1/shiro_user', {
                    method: 'post',
                    body: data
                }).then((resp) => {return resp.json();
                }).then(function(data) {
                    self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                    self.getEmployees();
                });
            }
        },
        validatePhone() {
            if (this.tempWaPhone) {
                let pattern = new RegExp(/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/);
                if (pattern.test(this.tempWaPhone)) {
                    this.validPhone = true;
                    this.invalidPhone = false;
                }
                else {
                    this.invalidPhone = true;
                    this.validPhone = false;
                }
            }
            else {
                this.validPhone = true;
                this.invalidPhone = false;
            }
            if (this.currentUser.notify_whatsapp_chat || this.currentUser.notify_whatsapp_new_incident || this.currentUser.notify_whatsapp_status) {
                let pattern = new RegExp(/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/);
                if (pattern.test(this.tempWaPhone)) {
                    this.validPhone = true;
                    this.invalidPhone = false;
                }
                else {
                    this.invalidPhone = true;
                    this.validPhone = false;
                }
            }
        },
        canEditWACheck(item) {
            if (!item.mobile) {
                item.notAllowedWA = true;
            }
            else {
                item.notAllowedWA = false;
            }
        },
        toggleNotsWA(item) {
            const data = new URLSearchParams();
            data.append('id_shiro_user', item.id_shiro_user);
            data.append('notify_whatsapp_chat', item.notify_whatsapp_chat);
            data.append('notify_whatsapp_new_incident', item.notify_whatsapp_new_incident);
            data.append('notify_whatsapp_status', item.notify_whatsapp_status);
            data.append('notify_incident_assigned', item.notify_incident_assigned);
            let self = this;
            fetch('/api/v1/buildingmanageremployee/notifywhatsapp', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.json();
            }).then(function(data) {
                self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
            });
        },
        portFolioModal(item) {
            this.selectedUser = item;
            this.portfolioModalOpen = true;
        },
        toggleMfa(item) {
            const data = new URLSearchParams();
            data.append('id_shiro_user', item.id_shiro_user);
            data.append('mfa_required', item.mfa_required);
            let self = this;
            fetch('/api/v1/buildingmanageremployee/setmfarequired', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.json();
            }).then(function(data) {
                self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
            });
        }
    }
}
</script>
