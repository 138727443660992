<template>
    <div class="modal-wrapper" v-if="profile.teams">
        <div class="modal">
            <div class="modal-header">
                <h3><i class="fas fa-users"></i>{{profile.teams.assign_team}}</h3>
                <img src="@/assets/img/logo-white.png" class="logo" />
            </div>
            <div class="modal-inner">
                <span class="close" @click="closeModal()"><i class="fas fa-times"></i></span>
                <form v-on:submit.prevent="assignTeam()">
                    <label>{{profile.teams.select_team}}</label>
                    <select class="form-select form-select--teams" data-testid="teamSelect" v-model="fields.team" required>
                        <option :value="null" disabled>{{profile.teams.select_team}} *</option>
                        <option v-for="team in teams" :value="team.id_team" :key="team.id_team">{{team.name}}</option>
                    </select>
                    <div class="switch-wrapper" v-if="incidentInfo.incident_status === 'pending_buildingmanager'">
                        <label class="switch">
                            <input type="checkbox" v-model="fields.resetStatus">
                            <span class="slider round"></span>
                        </label>
                        <span><b>{{profile.teams.reset_status}}</b> <i class="fas fa-info-circle" v-tooltip.right="profile.teams.reset_tooltip"></i></span>
                    </div>
                    <button type="submit" class="button button--icon" data-testid="teamSubmit" v-if="fields.resetStatus"><i class="fas fa-users"></i>{{profile.teams.assign_team_reset}}</button>
                    <button type="submit" class="button button--icon" data-testid="teamSubmit" v-else><i class="fas fa-users"></i>{{profile.teams.assign_team}}</button>
                    <span class="button button--icon" style="margin-right: 10px;" @click="removeAssignment()" v-if="incidentInfo.team_id_team === fields.team"><i class="fas fa-times"></i>{{profile.teams.remove_team}}</span>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale.js';
export default {
    mixins: [setLocale],
    name: 'assignTeamModal',
    data() {
        return {
            profile: {},
            fields: {
                team: null,
                resetStatus: false
            },
            teams: []
        }
    },
    props: ['incidentInfo'],
    created() {
        this.getTeams();
        document.querySelector('body').classList.add('modal-open');
        document.querySelector('.main-content').style.display = 'block';
        let self = this;
        document.onkeydown = function(evt) {
            evt = evt || window.event;
            if (evt.keyCode == 27) {
                self.closeModal();
            }
        };
    },
    methods: {
        getTeams() {
            this.teams = [];
            let self = this;
            fetch('/api/v1/team')
            .then(response => { response.json().then(
                function(data) {
                    self.teams = data.data;
                    for (var i = 0; i < self.teams.length; i++) {
                        let team = self.teams[i];
                        if (team.id_team === self.incidentInfo.team_id_team) {
                            self.fields.team = team.id_team;
                        }
                    }
                });
            });
        },
        assignTeam() {
            if (this.incidentInfo.team_id_team) {
                let self = this;
                const data = new URLSearchParams();
                data.append('id_incident', this.incidentInfo.id_incident);
                data.append('old_id_team', this.incidentInfo.team_id_team);
                data.append('new_id_team', this.fields.team);
                fetch('/api/v1/team/updateteamincident', {
                    method: 'post',
                    body: data
                }).then((resp) => {
                    if (!resp.ok) {
                        self.error = true;
                    }
                    return resp.json();
                }).then(function(data) {
                    if (self.fields.resetStatus) {
                        self.resetStatus();
                    }
                    if (!self.fields.resetStatus && !self.error) {
                        self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                        self.$emit('getIncidentInfo');
                        self.closeModal();
                    }
                });
            }
            else {
                let self = this;
                const data = new URLSearchParams();
                data.append('id_incident', this.incidentInfo.id_incident);
                data.append('id_team', this.fields.team);
                fetch('/api/v1/team/addincident', {
                    method: 'post',
                    body: data
                }).then((resp) => {
                    if (!resp.ok) {
                        self.error = true;
                    }
                    return resp.json();
                }).then(function(data) {
                    if (self.fields.resetStatus) {
                        self.resetStatus();
                    }
                    if (!self.fields.resetStatus && !self.error) {
                        self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                        self.$emit('getIncidentInfo');
                        self.closeModal();
                    }
                });
            }
        },
        removeAssignment() {
            let self = this;
            const data = new URLSearchParams();
            data.append('id_incident', this.incidentInfo.id_incident);
            data.append('id_team', this.incidentInfo.team_id_team);
            fetch('/api/v1/team/deleteincident', {
                method: 'post',
                body: data
            }).then((resp) => {
                if (!resp.ok) {
                    self.error = true;
                }
                return resp.json();
            }).then(function(data) {
                if (!self.error) {
                    self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                    self.$emit('getIncidentInfo');
                    self.closeModal();
                }
            });
        },
        resetStatus() {
            let queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            const id = urlParams.get('id');
            if (this.incidentInfo.id_incident.toString() === id) {
                const data = new URLSearchParams();
                data.append('id_incident', this.incidentInfo.id_incident);
                data.append('message', '');
                data.append('status', 'new_');
                let self = this;
                fetch('/api/v1/incident/changestatus', {
                    method: 'post',
                    body: data
                }).then((resp) => {return resp.json();
                }).then(function(data) {
                    self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                    self.closeModal();
                    self.$router.push('/buildingmanager');
                });
            }
        },
        closeModal() {
            this.$emit('close');
            document.querySelector('body').classList.remove('modal-open');
        }
    }
}
</script>
