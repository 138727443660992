import Vue from 'vue'
import Router from 'vue-router'

// Buildingmanager
import Dashboard from '@/views/buildingmanager/Dashboard.vue'
import NewIncident from '@/views/buildingmanager/NieuwIncident.vue'
import IncidentDetail from '@/views/buildingmanager/IncidentDetail.vue'
import Owners from '@/views/buildingmanager/Eigenaren.vue'
import OwnerDetail from '@/views/buildingmanager/EigenarenDetail.vue'
import Locations from '@/views/buildingmanager/Locaties.vue'
import LocationDetail from '@/views/buildingmanager/LocatieDetail.vue'
import NewLocation from '@/views/buildingmanager/NieuweLocatie.vue'
import RepairCompanies from '@/views/buildingmanager/Reparateurs.vue'
import RepairCompanyDetail from '@/views/buildingmanager/ReparateurDetail.vue'
import Account from '@/views/buildingmanager/Account.vue'
import Assets from '@/views/buildingmanager/Assets.vue'
import AssetDetail from '@/views/buildingmanager/AssetDetail.vue'
import NewObject from '@/views/buildingmanager/NieuwObject.vue'
import Connect from '@/views/buildingmanager/Connect.vue'
import Recordings from '@/views/buildingmanager/Opnames.vue'
import RepeatingWork from '@/views/buildingmanager/RepeterendWerk.vue'
import Planning from '@/views/buildingmanager/Planning.vue'
import BuildingmanagerNews from '@/views/buildingmanager/News.vue'
import Statistics from '@/views/buildingmanager/Statistics.vue'
import Invoices from '@/views/buildingmanager/Invoices.vue'

//Leveranciers
import RepDashboard from '@/views/supplier/Dashboard.vue'
import RepIncidentDetail from '@/views/supplier/IncidentDetail.vue'
import RepSpecialists from '@/views/supplier/Specialists.vue'
import RepSpecialistDetail from '@/views/supplier/SpecialistDetail.vue'
import RepAccount from '@/views/supplier/Account.vue'
import SupplierNews from '@/views/supplier/News.vue'
import SupplierLite from '@/views/supplier/Lite.vue'
import SupplierInvoices from '@/views/supplier/Invoices.vue'

// Specialisten
import MechDashboard from '@/views/mechanic/Dashboard.vue'
import MechIncidentDetail from '@/views/mechanic/IncidentDetail.vue'
import MechAssetDetail from '@/views/mechanic/AssetDetail.vue'
import MechAccount from '@/views/mechanic/Account.vue'
import MechConnect from '@/views/mechanic/Connect.vue'
import MechRecordings from '@/views/mechanic/Opnames.vue'
import MechRecordingDetail from '@/views/mechanic/OpnameDetail.vue'
import MechConnectRecording from '@/views/mechanic/ConnectRecording.vue'
import MechanicNews from '@/views/mechanic/News.vue'

// Eigenaren
import OwnerDashboard from '@/views/owner/Dashboard.vue'
import OwnerIncidentDetail from '@/views/owner/IncidentDetail.vue'
import OwnerOwners from '@/views/owner/Eigenaren.vue'
import OwnerOwnerDetail from '@/views/owner/EigenarenDetail.vue'
import OwnerLocationDetail from '@/views/owner/LocatieDetail.vue'
import OwnerAssetDetail from '@/views/owner/AssetDetail.vue'

// S1MONE
import S1moneDashboard from '@/views/s1mone/Dashboard.vue'
import S1monePartners from '@/views/s1mone/Partners.vue'
import S1monePartnerFeatures from '@/views/s1mone/PartnerFeatures.vue'
import S1monePartnerDetail from '@/views/s1mone/PartnerDetail.vue'
import S1moneStatistics from '@/views/s1mone/Statistics.vue'
import S1moneNews from '@/views/s1mone/maintenance/News.vue'
import S1moneSuppliers from '@/views/s1mone/maintenance/Suppliers.vue'
import S1moneCreatedAssets from '@/views/s1mone/maintenance/CreatedAssets.vue'
import S1moneIndustries from '@/views/s1mone/maintenance/Industries.vue'
import S1moneSpecialisms from '@/views/s1mone/maintenance/Specialisms.vue'
import S1moneIncidentCategories from '@/views/s1mone/maintenance/IncidentCategories.vue'
import S1moneDefaultCategories from '@/views/s1mone/maintenance/DefaultCategories.vue'
import S1moneAssetTypes from '@/views/s1mone/maintenance/AssetTypes.vue'
import S1moneTexts from '@/views/s1mone/maintenance/Texts.vue'
import S1moneNumberReset from '@/views/s1mone/maintenance/NumberReset.vue'

Vue.use(Router)

export default new Router({
    mode: 'history',
    routes: [
        {
            path: '/buildingmanager',
            name: 'Dashboard',
            component: Dashboard
		},
        {
            path: '/buildingmanager/new-incident',
            name: 'NewIncident',
            component: NewIncident
		},
        {
            path: '/buildingmanager/connect',
            name: 'Connect',
            component: Connect
		},
        {
            path: '/buildingmanager/incident-detail',
            name: 'IncidentDetail',
            component: IncidentDetail
		},
        {
            path: '/buildingmanager/owners',
            name: 'Owners',
            component: Owners
		},
        {
            path: '/buildingmanager/owner-detail',
            name: 'OwnerDetail',
            component: OwnerDetail
		},
        {
            path: '/buildingmanager/locations',
            name: 'Locations',
            component: Locations
		},
        {
            path: '/buildingmanager/new-location',
            name: 'NewLocation',
            component: NewLocation
		},
        {
            path: '/buildingmanager/repaircompanies',
            name: 'RepairCompanies',
            component: RepairCompanies
		},
        {
            path: '/buildingmanager/repaircompany-detail',
            name: 'RepairCompanyDetail',
            component: RepairCompanyDetail
		},
        {
            path: '/buildingmanager/location-detail',
            name: 'LocationDetail',
            component: LocationDetail
		},
        {
            path: '/buildingmanager/assets',
            name: 'Assets',
            component: Assets
		},
        {
            path: '/buildingmanager/asset-detail',
            name: 'AssetDetail',
            component: AssetDetail
		},
        {
            path: '/buildingmanager/new-object',
            name: 'NewObject',
            component: NewObject
		},
        {
            path: '/buildingmanager/account',
            name: 'Account',
            component: Account
		},
        {
            path: '/buildingmanager/recordings',
            name: 'Recordings',
            component: Recordings
		},
        {
            path: '/buildingmanager/repeating-work',
            name: 'RepeatingWork',
            component: RepeatingWork
		},
        {
            path: '/buildingmanager/planning',
            name: 'Planning',
            component: Planning
		},
        {
            path: '/buildingmanager/statistics',
            name: 'Statistics',
            component: Statistics
		},
        {
            path: '/buildingmanager/invoices',
            name: 'Invoices',
            component: Invoices
		},
        {
            path: '/monteur',
            name: 'MechDashboard',
            component: MechDashboard
        },
        {
            path: '/monteur/incident-detail',
            name: 'MechIncidentDetail',
            component: MechIncidentDetail
        },
        {
            path: '/monteur/asset-detail',
            name: 'MechAssetDetail',
            component: MechAssetDetail
        },
        {
            path: '/monteur/account',
            name: 'MechAccount',
            component: MechAccount
        },
        {
            path: '/monteur/opnames',
            name: 'MechRecordings',
            component: MechRecordings
        },
        {
            path: '/monteur/opname-detail',
            name: 'MechRecordingDetail',
            component: MechRecordingDetail
        },
        {
            path: '/monteur/connect',
            name: 'MechConnect',
            component: MechConnect
        },
        {
            path: '/monteur/scanrecording',
            name: 'MechConnectRecording',
            component: MechConnectRecording
        },
        {
            path: '/supplier',
            name: 'RepDashboard',
            component: RepDashboard
		},
        {
            path: '/supplier/incident-detail',
            name: 'RepIncidentDetail',
            component: RepIncidentDetail
		},
        {
            path: '/supplier/specialists',
            name: 'RepSpecialists',
            component: RepSpecialists
		},
        {
            path: '/supplier/specialist-detail',
            name: 'RepSpecialistDetail',
            component: RepSpecialistDetail
		},
        {
            path: '/supplier/account',
            name: 'RepAccount',
            component: RepAccount
		},
        {
            path: '/supplier/lite',
            name: 'SupplierLite',
            component: SupplierLite
		},
        {
            path: '/buildingmanager/news',
            name: 'Buildingmanager News',
            component: BuildingmanagerNews
		},
        {
            path: '/supplier/news',
            name: 'Supplier News',
            component: SupplierNews
		},
        {
            path: '/supplier/invoices',
            name: 'Supplier Invoices',
            component: SupplierInvoices
		},
        {
            path: '/monteur/news',
            name: 'Mechanic News',
            component: MechanicNews
		},
        {
            path: '/owner/dashboard',
            name: 'Owner Dashboard',
            component: OwnerDashboard
		},
        {
            path: '/owner/incident-detail',
            name: 'Owner Incident Detail',
            component: OwnerIncidentDetail
		},
        {
            path: '/owner/owners',
            name: 'Owner Owners',
            component: OwnerOwners
		},
        {
            path: '/owner/owner-detail',
            name: 'Owner Owner Detail',
            component: OwnerOwnerDetail
		},
        {
            path: '/owner/location-detail',
            name: 'Owner Location Detail',
            component: OwnerLocationDetail
		},
        {
            path: '/owner/asset-detail',
            name: 'Owner Asset Detail',
            component: OwnerAssetDetail
		},
        {
            path: '/s1mone',
            name: 'S1MONE Dashboard',
            component: S1moneDashboard
		},
        {
            path: '/s1mone/buildingmanagers',
            name: 'S1MONE Partners',
            component: S1monePartners
		},
        {
            path: '/s1mone/buildingmanagerFeatures',
            name: 'S1MONE PartnerFeatures',
            component: S1monePartnerFeatures
		},
        {
            path: '/s1mone/buildingmanager-detail',
            name: 'S1MONE Buildingmanager Detail',
            component: S1monePartnerDetail
        },
        {
            path: '/s1mone/statistics',
            name: 'S1MONE Statistics',
            component: S1moneStatistics
        },
        {
            path: '/s1mone/news',
            name: 'S1MONE News',
            component: S1moneNews
        },
        {
            path: '/s1mone/suppliers',
            name: 'S1MONE Suppliers',
            component: S1moneSuppliers
        },
        {
            path: '/s1mone/createdAssets',
            name: 'S1MONE CreatedAssets',
            component: S1moneCreatedAssets
        },
        {
            path: '/s1mone/industries',
            name: 'S1MONE Industries',
            component: S1moneIndustries
        },
        {
            path: '/s1mone/specialisms',
            name: 'S1MONE Specialisms',
            component: S1moneSpecialisms
        },
        {
            path: '/s1mone/incidentCategories',
            name: 'S1MONE Incident Categories',
            component: S1moneIncidentCategories
        },
        {
            path: '/s1mone/defaultCategories',
            name: 'S1MONE Incident Default Categories',
            component: S1moneDefaultCategories
        },
        {
            path: '/s1mone/assetTypes',
            name: 'S1MONE Asset Types',
            component: S1moneAssetTypes
        },
        {
            path: '/s1mone/texts',
            name: 'S1MONE Texts',
            component: S1moneTexts
        },
        {
            path: '/s1mone/numberReset',
            name: 'S1MONE Number Reset',
            component: S1moneNumberReset
        }
	]
})
