<template>
    <div>
        <div class="main-content">
            <div class="container">
                <div class="row">
                    <div class="col">
                        <div class="block block--owners">
                            <section class="incident-head">
                                <h1>Specialismes</h1>
                                <span class="edit-button icon" @click="newSpecialismModalOpen = true"><i class="fas fa-plus"></i></span>
                            </section>
                            <div class="recording-list" style="margin-top: 10px;" v-for="specialism in specialisms" :key="specialism.id_specialism">
                                <h3 v-if="!specialism.edit">{{specialism.name}}</h3>
                                <div v-else>
                                    <label>Nieuwe specialisme naam</label>
                                    <input type="text" class="form-control" v-model="specialism.name"  />
                                </div>
                                <span class="edit-button edit-button--small" @click="specialism.edit = true;" v-if="!specialism.edit" style="padding: 0; margin-right: 30px;"><i class="fas fa-pen"></i></span>
                                <span class="edit-button edit-button--small" @click="removeSpecialismModalOpen=true; specialismToRemove=specialism" v-if="!specialism.edit"><i class="far fa-trash-alt"></i></span>
                                <span class="edit-button edit-button--small" @click="saveSpecialism(specialism)" v-if="specialism.edit" style="padding: 0;"><i class="fas fa-save"></i></span>
                            </div>                       
                        </div>
                    </div>
                </div>
            </div>    
        </div>
        <NewSpecialismModal v-if="newSpecialismModalOpen" @close="loadSpecialisms()" />
        <RemoveSpecialismModal v-if="removeSpecialismModalOpen" @close="loadSpecialisms()" :specialism="specialismToRemove" />
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale-mechanic.js';
import NewSpecialismModal from '@/views/s1mone/modals/NewSpecialismModal.vue';
import RemoveSpecialismModal from '@/views/s1mone/modals/RemoveSpecialismModal.vue';
import sortBy from 'lodash/sortBy'

export default {
    mixins: [setLocale],
    components: {
        NewSpecialismModal,
        RemoveSpecialismModal
    },
    data() {
        return {
            specialisms: [],
            specialismToRemove: {},
            removeIndustryID: '',
            newSpecialismModalOpen: false,
            removeSpecialismModalOpen: false,
            editIndustry: {},
        }
    },
    created() {
        document.title = 'S1MONE | Specialisms';
        this.loadSpecialisms();
    },
    methods: {
        loadSpecialisms() {
            let self = this;
            this.newSpecialismModalOpen = false;
            this.removeSpecialismModalOpen = false;
            this.specialisms = [];
            fetch('/api/v1/specialism')
            .then(response => { response.json().then(
                function(data) {
                    for (var i = 0; i < data.data.length; i++) {
                        let specialism = data.data[i];
                        specialism.edit = false;
                        self.specialisms.push(specialism);
                    }
                });
            });
        },
        sortTable(sorter) {
            this.industrySearchType = sorter;
            this.sortOrder = this.sortOrder !== 'asc' ? 'asc' : 'desc';
            this.industriesShown = sortBy(this.industriesShown, sorter);
            if (this.sortOrder === 'desc') {
                this.industriesShown.reverse();
            }
        },
        saveSpecialism(specialism) {
            const data = new URLSearchParams();
            data.append('id_specialism', specialism.id_specialism);
            data.append('name', specialism.name);
            let self = this;
            fetch('/api/v1/specialism/update', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.json();
            }).then(function(data) {
                specialism.edit = false;
                self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
            });
        },
    }
}
</script>
