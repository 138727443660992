<template>
    <div>
        <div class="main-content" v-if="profile.incident_detail">
            <div class="container">
                <div class="tabs">
                    <div class="tab" data-testid="recordingPlanning" @click="saveTab('planning')" :class="{active: currentTab === 'planning'}">
                        <h3>{{profile.actionable.recording_planning}}</h3>
                    </div>
                    <div class="tab" data-testid="completedRecordings" @click="saveTab('opnames')" :class="{active: currentTab === 'opnames'}">
                        <h3>{{profile.actionable.completed_recordings}}</h3>
                    </div>
                    <div class="tab right" data-testid="recordingLists" @click="saveTab('lists')" :class="{active: currentTab === 'lists'}" v-if="admin">
                        <h3>{{profile.actionable.recordingslists}}</h3>
                    </div>
                </div>
                <!-- Recording planning Tab -->
                <div class="tab-view" :class="{active: currentTab === 'planning'}" style="border-radius: 0 0 5px 5px;">
                    <div class="row">
                        <div class="col" style="position: relative;">
                            <h1>{{profile.actionable.recording_planning}}</h1>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <div class="link-switch">
                                <span :class="{active: switchType === 'plan'}" @click="saveType('plan')">{{profile.actionable.plan_recording}}</span>
                                <span :class="{active: switchType === 'planned'}" @click="saveType('planned')">{{profile.actionable.planned_recordings}}</span>
                                <span :class="{active: switchType === 'started'}" @click="saveType('started')">{{profile.actionable.started_recordings}}</span>
                            </div>
                        </div>
                    </div>
                    <hr style="margin-top: 0;" />
                    <PlanRecordings v-if="switchType === 'plan' && recordingsWithActions.length" :recordings="recordingsWithActions" :mechanics="mechanics" />
                    <PlannedRecordings v-if="switchType === 'planned'" :mechanics="mechanics" />
                    <StartedRecordings v-if="switchType === 'started'" :mechanics="mechanics" />
                </div>
                <!-- Recordings tab -->
                <div class="tab-view" :class="{active: currentTab === 'opnames'}" style="border-radius: 0 0 5px 5px; overflow: visible;">
                    <div class="row">
                        <div class="col" style="position: relative;">
                            <h1>{{profile.actionable.completed_recordings}}</h1>
                        </div>
                    </div>
                    <Recordings  />
                </div>
                <!-- Lists tab -->
                <div class="tab-view" :class="{active: currentTab === 'lists'}" style="border-radius: 0 0 5px 5px;">
                    <div class="row">
                        <div class="col" style="position: relative;">
                            <h1>{{profile.actionable.recordingslists}}</h1>
                            <span class="edit-button icon" data-testid="newRecordingListButton" @click="newRecordingModalOpen = true"><i class="fas fa-plus"></i></span>
                        </div>
                    </div>
                    <p style="margin-bottom: 0;" v-if="!recordings.length">{{profile.actionable.no_lists}}</p>
                    <div class="recording-list" v-for="recording in recordings" :key="recording.id_actionable_list" :class="{toggled: recording.toggled}">
                        <h3 v-if="!recording.edit" data-testid="recordingListName">{{recording.name}}</h3>
                        <div v-else>
                            <label>{{profile.actionable.recording_name}}</label>
                            <input type="text" data-testid="recordingEditName" class="form-control" v-model="recording.name"  />
                        </div>
                        <span class="edit-button edit-button--small" data-testid="recordingListEdit" @click="recording.edit = true" v-if="!recording.edit && recording.toggled" style="padding: 0; margin-right: 25px;"><i class="fas fa-pen"></i></span>
                        <span class="edit-button edit-button--small" data-testid="recordingListDelete" @click="selectRecording(recording, 'remove')" v-if="recording.edit && recording.toggled" style="padding: 0; margin-right: 25px;"><i class="far fa-trash-alt"></i></span>
                        <span class="edit-button edit-button--small" data-testid="recordingListSave" @click="saveRecording(recording)" v-if="recording.edit && recording.toggled" style="padding: 0;"><i class="fas fa-save"></i></span>
                        <span class="edit-button edit-button--small" data-testid="recordingListAddActionable" @click="selectRecording(recording, 'create')" v-if="!recording.edit && recording.toggled" style="padding: 0;" v-tooltip.right="profile.actionable.add_action_for_list"><i class="fas fa-plus" style="margin-top: 0;"></i></span>
                        <div class="toggler" data-testid="toggleRecordingList" @click="recording.toggled = !recording.toggled">
                            <i class="fas fa-chevron-down chevron"></i>
                        </div>
                        <div class="actions-list" v-for="actionable in recording.actionables" :key="actionable.id_actionable" v-show="recording.toggled">
                            <h3 v-if="!actionable.edit" data-testid="actionName">{{actionable.name}}</h3>
                            <div v-else>
                                <label>{{profile.actionable.actionable_name}}</label>
                                <input type="text" data-testid="actionNameEdit" class="form-control" v-model="actionable.name"  />
                            </div>
                            <span class="edit-button edit-button--small" data-testid="actionEdit" @click="selectActionable(recording, actionable)" v-if="!actionable.edit" style="padding: 0;"><i class="fas fa-pen"></i></span>
                            <span class="edit-button edit-button--small" data-testid="actionDelete" @click="selectActionable(recording, actionable, 'remove')" v-if="actionable.edit" style="padding: 0; margin-right: 25px;"><i class="far fa-trash-alt"></i></span>
                            <span class="edit-button edit-button--small" data-testid="actionSave" @click="saveActionable(actionable)" v-if="actionable.edit" style="padding: 0;"><i class="fas fa-save"></i></span>
                        </div>
                        <div class="group-list" v-for="(group, index) in recording.groups" :key="index" v-show="recording.toggled">
                            <h3 data-testid="actionGroupName">{{ group.group_name }}</h3>
                            <div class="actions-list" v-for="(actionable, index) in group.actionables" :key="index">
                                <h3 v-if="!actionable.edit" data-testid="actionInGroupName">{{actionable.name}}</h3>
                                <div v-else>
                                    <label>{{profile.actionable.actionable_name}}</label>
                                    <input type="text" data-testid="actionInGroupNameEdit" class="form-control" v-model="actionable.name"  />
                                </div>
                                <span class="edit-button edit-button--small" data-testid="actionInGroupEdit" @click="selectActionable(recording, actionable)" v-if="!actionable.edit" style="padding: 0;"><i class="fas fa-pen"></i></span>
                                <span class="edit-button edit-button--small" data-testid="actionInGroupDelete" @click="selectActionable(recording, actionable, 'remove')" v-if="actionable.edit" style="padding: 0; margin-right: 25px;"><i class="far fa-trash-alt"></i></span>
                                <span class="edit-button edit-button--small" data-testid="actionInGroupSave" @click="saveActionable(actionable)" v-if="actionable.edit" style="padding: 0;"><i class="fas fa-save"></i></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <NewRecordingModal v-if="newRecordingModalOpen" @close="getRecordings()" />
        <NewActionModal v-if="newActionModalOpen" :selectedRecording="selectedRecording" @close="getRecordings()" @success="getRecordings" />
        <RemoveRecordingModal v-if="removeRecordingModalOpen" :selectedRecording="selectedRecording" @close="removeRecordingModalOpen = false" @remove="getRecordings()" />
        <RemoveActionableModal v-if="removeActionableModalOpen" :selectedActionable="selectedActionable" @close="removeActionableModalOpen = false" @remove="getRecordings()" />
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale.js';
import debounce from 'lodash/debounce';
import NewRecordingModal from "@/components/modals/recordings/NewRecordingModal.vue";
import NewActionModal from "@/components/modals/recordings/NewActionModal.vue";
import RemoveRecordingModal from "@/components/modals/recordings/RemoveRecordingModal.vue";
import RemoveActionableModal from "@/components/modals/recordings/RemoveActionableModal.vue";
import PlanRecordings from "@/components/recordings/PlanRecordings.vue";
import PlannedRecordings from "@/components/recordings/PlannedRecordings.vue";
import StartedRecordings from "@/components/recordings/StartedRecordings.vue";
import Recordings from "@/components/recordings/Recordings.vue";
import uniqBy from 'lodash/uniqBy';
import sortBy from 'lodash/sortBy';

export default {
    mixins: [setLocale],
    components: {
        NewRecordingModal,
        NewActionModal,
        RemoveRecordingModal,
        RemoveActionableModal,
        PlanRecordings,
        PlannedRecordings,
        StartedRecordings,
        Recordings
    },
    data() {
        return {
            profile: {},
            admin: false,
            currentTab: 'lists',
            recordings: [],
            recordingsWithActions: [],
            newRecordingModalOpen: false,
            newActionModalOpen: false,
            removeRecordingModalOpen: false,
            selectedRecording: {},
            selectedActionable: {},
            removeActionableModalOpen: false,
            searchTerm: '',
            searchActive: false,
            assets: [],
            loading: false,
            selectedAssets: [],
            switchType: 'plan',
            groups: [],
            mechanics: []
        }
    },
    created() {
        let self = this;
        fetch('/api/v1/userandbuildingmanager')
        .then(response => { response.json().then(
            function(data) {
                self.admin = data.building_manager_admin;
            });
        });
        if (this.profile.actionable) {
            document.title = 'S1MONE | ' + this.profile.actionable.recordings;
        }
        this.getMechanics();
        this.getRecordings();
        if (localStorage.getItem('S1-recordingstab')) {
            if (localStorage.getItem('S1-recordingstab') === 'lists') {
                this.currentTab = 'lists';
            }
            if (localStorage.getItem('S1-recordingstab') === 'planning') {
                this.currentTab = 'planning';
            }
            if (localStorage.getItem('S1-recordingstab') === 'opnames') {
                this.currentTab = 'opnames';
            }
        }
        if (localStorage.getItem('S1-recordingPlanningTab')) {
            if (localStorage.getItem('S1-recordingPlanningTab') === 'plan') {
                this.switchType = 'plan';
            }
            if (localStorage.getItem('S1-recordingPlanningTab') === 'planned') {
                this.switchType = 'planned';
            }
        }
    },
    methods: {
        track() {
            this.$gtag.pageview(this.$route);
        },
        saveTab(type) {
            this.currentTab = type;
            localStorage.setItem('S1-recordingstab', type);
        },
        saveType(type) {
            if (type === 'plan') {
                this.switchType = 'plan';
            }
            if (type === 'planned') {
                this.switchType = 'planned';
            }
            if (type === 'started') {
                this.switchType = 'started';
            }
            localStorage.setItem('S1-recordingPlanningTab', type);
        },
        getMechanics() {
            this.mechanics = [];
            let self = this;
            fetch('/api/v1/mechanic')
            .then(response => { response.json().then(
                function(data) {
                    self.mechanics = data.data;
                });
            });
        },
        getRecordings(id) {
            this.recordings = [];
            this.recordingsWithActions = [];
            this.newRecordingModalOpen = false;
            this.removeRecordingModalOpen = false;
            this.removeActionableModalOpen = false;
            this.newActionModalOpen = false;
            let self = this;
            fetch('/api/v1/actionablelist/withactionables')
            .then(response => { response.json().then(
                function(data) {
                    for (var i = 0; i < data.length; i++) {
                        let recording = data[i];
                        recording.toggled = false;
                        recording.edit = false;
                        recording.groups = [];
                        if (recording.id_actionable_list === id) {
                            recording.toggled = true;
                        }
                        recording.actionables = sortBy(recording.actionables, 'name');
                        for (var j = 0; j < recording.actionables.length; j++) {
                            let actionable = recording.actionables[j];
                            actionable.trimmedName = actionable.name.toLowerCase();
                            if (actionable.group_name) {
                                actionable.group_name_trimmed = actionable.group_name.trim().toLowerCase();
                                recording.groups.push({ 'group_name': actionable.group_name, 'group_name_trimmed': actionable.group_name_trimmed, 'actionables': [], 'toggled': false});
                            }
                            actionable.edit = false;
                        }
                        recording.groups = uniqBy(recording.groups, 'group_name_trimmed');
                        recording.groups = sortBy(recording.groups, 'group_name_trimmed');
                        recording.assets = [];
                        recording.toggled = false;
                        self.recordings.push(recording);
                        if (recording.actionables.length) {
                            self.recordingsWithActions.push(recording);
                        }
                    }

                    for (var i = 0; i < self.recordingsWithActions.length; i++) {
                        let recording = self.recordingsWithActions[i];
                        for (var j = 0; j < recording.groups.length; j++) {
                            let group = recording.groups[j];
                            const targetKey = 'group_name_trimmed';
                            const targetValue = group.group_name_trimmed;
                            // Function to check for the key and value pair
                            function hasKeyValue(obj) {
                                return obj.hasOwnProperty(targetKey) && obj[targetKey] === targetValue;
                            }
                            // Filter out objects with the specific key-value pair
                            const filteredArray = recording.actionables.filter(hasKeyValue);
                            // Remove filtered objects from the source array
                            const sourceArrayWithoutMatch = recording.actionables.filter(obj => !hasKeyValue(obj));
                            recording.actionables = sourceArrayWithoutMatch;
                            group.actionables = filteredArray;
                            group.actionables = sortBy(group.actionables, 'trimmedName');
                        }
                    }
                    self.recordings = sortBy(self.recordings, 'name');
                    self.recordingsWithActions = sortBy(self.recordingsWithActions, 'name');
                });
            });
        },
        saveRecording(recording) {
            const data = new URLSearchParams();
            data.append('id_actionable_list', recording.id_actionable_list);
            data.append('name', recording.name);
            let self = this;
            fetch('/api/v1/actionablelist/update', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.json();
            }).then(function(data) {
                recording.edit = false;
                self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
            });
        },
        selectRecording(recording, type) {
            if (type === 'remove') {
                this.removeRecordingModalOpen = true;
            }
            else {
                this.newActionModalOpen = true;
            }
            this.selectedRecording = recording;
        },
        saveActionable(actionable) {
            const data = new URLSearchParams();
            data.append('id_actionable', actionable.id_actionable);
            data.append('id_actionable_list', this.selectedRecording.id_actionable_list);
            data.append('name', actionable.name);
            if (actionable.group_name) {
                data.append('group_name', actionable.group_name);
            }
            let self = this;
            fetch('/api/v1/actionable/update', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.json();
            }).then(function(data) {
                actionable.edit = false;
                self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
            });
        },
        selectActionable(recording, actionable, type) {
            actionable.edit = true;
            if (type === 'remove') {
                this.removeActionableModalOpen = true;
            }
            this.selectedRecording = recording;
            this.selectedActionable = actionable;
        },
        search($event) {
            let searchTerm = this.searchTerm.toLowerCase();
            if (searchTerm.length > 2) {
                this.searchActive = true;
                this.getAssets();
            }
            if (searchTerm.length < 2 && this.searchActive)  {
                this.searchActive = false;
                this.getAssets();
            }
            if($event.keyCode === 27) {
                this.searchActive = false;
                this.searchTerm = '';
                this.getAssets();
            }
        },
        clearSearch() {
            this.assets = [];
            this.searchActive = false;
            this.searchTerm = '';
        },
        getAssets: debounce(function (e) {
            this.loading = true;
            this.assets = [];
            let searchParam = '';
            if (this.searchTerm) {
                searchParam = '&search_name=' + this.searchTerm;
            }
            let self = this;
            fetch('/api/v1/asset/filtered?limit=25' + searchParam)
            .then(response => { response.json().then(
                function(data) {
                    for (var i = 0; i < data.data.length; i++) {
                        let item = data.data[i];
                        item.selected = false;
                        self.assets.push(item);
                    }
                    self.loading = false;
                });
            });
        }, 500),
        selectAsset(asset) {
            asset.selected = !asset.selected;
            if (asset.selected) {
                this.selectedAssets.push(asset);
            }
            else {
                for (var i = 0; i < this.selectedAssets.length; i++) {
                    let assetItem = this.selectedAssets[i];
                    if (assetItem.id_asset === asset.id_asset) {
                        this.selectedAssets.splice(i, 1);
                    }
                }
            }
        }
    }
}
</script>
