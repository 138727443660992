<template>
    <div class="modal-wrapper" v-if="profile.incident_detail">
        <div class="modal">
            <div class="modal-header">
                <h3><i class="fas fa-book"></i>{{profile.incident_detail.log_this_incident}}</h3>
                <img src="@/assets/img/logo-white.png" class="logo" />
            </div>
            <div class="modal-inner">
                <span class="close" @click="closeModal()"><i class="fas fa-times"></i></span>
                <section class="modal-search" :class="{active: searchSuggestions.length}">
                    <form>
                        <input type="text" :placeholder="profile.incident_detail.search_log" @input="e => searchTerm = e.target.value" :value="searchTerm" v-on:keyup="searchSuggest($event)" />
                        <span class="close-suggestions" @click="clearSuggestions()" v-if="searchSuggestions.length"><i class="fas fa-times"></i></span>
                    </form>
                </section>
                <table class="table table-striped" v-if="logBook.length">
                    <thead>
                        <tr>
                            <th scope="col" @click="sortTable('created')">{{profile.incident_detail.logTable.created}}<i class="fas fa-sort"></i></th>
                            <th scope="col" @click="sortTable('actual_name')">{{profile.incident_detail.logTable.username}}<i class="fas fa-sort"></i></th>
                            <th scope="col" @click="sortTable('message')">{{profile.incident_detail.logTable.message}}<i class="fas fa-sort"></i></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in logBook" :key="index" v-show="!searchSuggestions.length">
                            <td>{{item.date}} {{item.time}}</td>
                            <td>{{item.actual_name}}</td>
                            <td>{{item.message}}</td>
                        </tr>
                        <tr v-for="(item, index) in searchSuggestions" :key="index" v-show="searchSuggestions.length">
                            <td>{{item.date}} {{item.time}}</td>
                            <td>{{item.actual_name}}</td>
                            <td>{{item.message}}</td>
                        </tr>
                    </tbody>
                </table>
                <p v-if="!logBook.length">{{profile.incident_detail.logTable.message}}</p>
            </div>
        </div>
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale.js';
import moment from 'moment';
import sortBy from 'lodash/sortBy';
import uniqBy from 'lodash/uniqBy';

export default {
    mixins: [setLocale],
    name: 'IncidentLogModal',
    data() {
        return {
            profile: {},
            logBook: [],
            sortOrder: 'asc',
            searchSuggestions: [],
            searchTerm: '',
        }
    },
    props: ['incidentInfo'],
    created() {
        document.querySelector('body').classList.add('modal-open');
        let self = this;
        fetch('/api/v1/log/byincident?id_incident=' + this.incidentInfo.id_incident + '&_=1595488612287')
        .then(response => { response.json().then(
            function(data) {
                for (var i = 0; i < data.data.length; i++) {
                    let item = data.data[i];
                    item.date = moment(item.created).locale('nl').format('D MMMM YYYY');
                    item.time = moment(item.created).locale('nl').format('LT');
                    self.logBook.push(item);
                }
            });
        });
        document.onkeydown = function(evt) {
            evt = evt || window.event;
            if (evt.keyCode == 27) {
                self.closeModal();
            }
        };
    },
    methods: {
        closeModal() {
            this.$emit('close');
            document.querySelector('body').classList.remove('modal-open');
        },
        sortTable(sorter) {
            this.sortOrder = this.sortOrder !== 'asc' ? 'asc' : 'desc';
            this.logBook = sortBy(this.logBook, sorter);
            if (this.sortOrder === 'desc') {
                this.logBook.reverse();
            }
        },
        searchSuggest($event) {
            this.searchSuggestions = [];
            let searchTerm = this.searchTerm.toLowerCase();
            if (searchTerm.length > 2) {
                for (var i = 0; i < this.logBook.length; i++) {
                    let item = this.logBook[i];
                    let itemDescr = item.message.toLowerCase();
                    let itemUser = item.actual_name.toString();
                    if (itemDescr.includes(searchTerm) || itemUser.includes(searchTerm)) {
                        this.searchSuggestions.push(item);
                    }
                }
            }
            this.searchSuggestions = uniqBy(this.searchSuggestions, 'id_incident');
            if($event.keyCode === 27) {
                this.searchTerm = '';
                this.searchSuggestions = [];
            }
        },
        clearSuggestions() {
            this.searchSuggestions = [];
            this.searchTerm = '';
        }
    }
}
</script>
