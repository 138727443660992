<template>
    <div class="modal-wrapper" v-if="profile.incident_detail">
        <div class="modal">
            <div class="modal-header">
                <h3><i class="fas fa-parking"></i>{{profile.incident_detail.remove_parking}}</h3>
                <img src="@/assets/img/logo-white.png" class="logo" />
            </div>
            <div class="modal-inner">
                <span class="close" @click="closeModal()"><i class="fas fa-times"></i></span>
                <form v-on:submit.prevent="unparkIncident()">
                    <p>{{profile.incident_detail.remove_parking_expla}}<br /><br /></p>
                    <button type="submit" data-testid="unParkSubmit" class="button button--cta button--icon"><i class="fas fa-parking"></i>{{profile.incident_detail.remove_parking}}</button>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale.js';
import moment from 'moment';
export default {
    mixins: [setLocale],
    name: 'unparkIncidentModal',
    data() {
        return {
            profile: {},
        }
    },
    props: ['incidentId'],
    methods: {
        closeModal() {
            this.$emit('close');
            document.querySelector('body').classList.remove('modal-open');
        },
        unparkIncident() {
            const data = new URLSearchParams();
            data.append('id_incident', this.incidentId);
            let self = this;
            fetch('/api/v1/incident/resetsuspendservicedesk', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.json();
            }).then(function(data) {
                self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                document.querySelector('body').classList.remove('modal-open');
                self.$emit('success');
            });
        }
    }
}
</script>
