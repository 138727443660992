<template>
    <div>
        <div class="main-content" v-if="profile.repair_companies">
            <div class="container">
                <div class="row">
                    <div class="col">
                        <a class="back-link" @click="$router.go(-1)"><i class="fas fa-chevron-left"></i>{{profile.nav.back_link}}</a>
                    </div>
                </div>
                <div class="block block--account">
                    <div class="profile-card" style="border-right: 0;">
                        <div class="row">
                            <div class="col-3">
                                <div class="profile-pic-wrapper">
                                    <img src="@/assets/icons/loading.gif" v-if="logoLoading" class="loader" style="position: relative; width: 100%; float: right; right: auto;" />
                                    <div v-else>
                                        <span data-testid="specialistLogoEdit" class="edit" @click="editLogo()" v-if="specialistInfo.profile_image_loc && specialistInfo.profile_image_loc !== '/images/no-profile-img.png'"><i class="fas fa-pen"></i></span>
                                        <span class="seperator" v-if="specialistInfo.profile_image_loc && specialistInfo.profile_image_loc !== '/images/no-profile-img.png'">|</span>
                                        <span data-testid="specialistLogoRemove" class="remove" @click="removeLogo()" v-if="specialistInfo.profile_image_loc && specialistInfo.profile_image_loc !== '/images/no-profile-img.png'"><i class="far fa-trash-alt"></i></span>
                                        <img data-testid="specialistLogoImage" :src="specialistInfo.profile_image_loc" class="profile-pic" v-if="specialistInfo.profile_image_loc && specialistInfo.profile_image_loc !== '/images/no-profile-img.png'" />
                                        <span data-testid="specialistLogoAdd" class="add" @click="editLogo()" v-if="!specialistInfo.profile_image_loc || specialistInfo.profile_image_loc === '/images/no-profile-img.png'"><i class="fas fa-plus"></i></span>
                                        <div class="profile-pic placeholder" v-if="!specialistInfo.profile_image_loc || specialistInfo.profile_image_loc === '/images/no-profile-img.png'">
                                            <i class="far fa-image"></i>
                                        </div>
                                        <input data-testid="specialistLogoInput" type="file" id="profile-image-upload" style="display: none;" @change="uploadLogo($event)"/>
                                    </div>
                                </div>
                            </div>
                            <div class="col-9">
                                <h4>{{profile.employees.mechanic}}</h4>
                                <h2>{{specialistInfo.name}}</h2>
                            </div>
                        </div>
                    </div>
                    <span data-testid="specialistRemoveButton" class="edit-button icon" @click="removeSpecialistModalOpen = true" style="position: absolute; top: 0; bottom: 0; margin: auto; height: 26px;"><i class="far fa-trash-alt"></i></span>
                </div>
                <div class="row">
                    <div class="col">
                        <div class="block block--location_detail">
                            <section class="incident-head">
                                <h1>{{profile.accountPage.account.general}}</h1>
                                <span data-testid="specialistEditButton" class="edit-button icon" @click="specialistEdit = true" v-if="!specialistEdit"><i class="fas fa-pen" style="margin-top: 5px; font-size: 18px;"></i></span>
                                <span data-testid="specialistSaveButton" class="edit-button icon" @click="saveSpecialistInfo()" v-if="specialistEdit"><i class="fas fa-save" style="font-size: 21px;"></i></span>
                            </section>
                            <section class="location-contents">
                                <div class="location-info">
                                    <div v-if="!specialistEdit">
                                        <br />
                                        <div class="row">
                                            <div class="col-sm-6">
                                                <label>{{profile.employees.name}}</label>
                                                <p data-testid="specialistNameText">{{specialistInfo.name}}</p>
                                            </div>
                                            <div class="col-sm-6">
                                                <label>{{profile.accountPage.account.contactmobile}}</label>
                                                <p data-testid="specialistPhoneText">{{specialistInfo.mobile}}</p>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-sm-6">
                                                <label>{{profile.incidents.new_incident.emailaddress}} <i class="fas fa-info-circle" v-tooltip.right="profile.repair_companies.repaircompanytable.company_email_tooltip" style="cursor: pointer;"></i></label>
                                                <p data-testid="specialistEmailText">{{specialistInfo.email}}</p>
                                            </div>
                                            <div class="col-sm-6">
                                                <label>{{profile.accountPage.account.password}}</label>
                                                <p data-testid="specialistPasswordText" style="margin-bottom: 0;">•••••••••</p>
                                            </div>
                                        </div>                                                            
                                    </div>
                                    <div v-if="specialistEdit">
                                        <br />
                                        <div class="row">
                                            <div class="col-sm-6">
                                                <label>{{profile.employees.name}}</label>
                                                <input data-testid="specialistNameInput" type="text" class="form-control" v-model="specialistInfo.name" />
                                            </div>
                                            <div class="col-sm-6">
                                                <label>{{profile.accountPage.account.contactmobile}}</label>
                                                <input data-testid="specialistPhoneInput" type="text" class="form-control" v-model="specialistInfo.mobile" :class="{valid: validPhone, error: invalidPhone}" @input="validatePhone()" />
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-sm-6">
                                                <label>{{profile.incidents.new_incident.emailaddress}} <i class="fas fa-info-circle" v-tooltip.right="profile.repair_companies.repaircompanytable.company_email_tooltip" style="cursor: pointer;"></i></label>
                                                <input data-testid="specialistEmailInput" type="text" class="form-control" v-model="specialistInfo.email" :class="{valid: validEmail, error: invalidEmail}" @input="validateEmail()" />
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-lg-6">
                                                <label>{{profile.accountPage.account.new_pw}}</label>
                                                <input type="password" class="form-control" data-testid="accountInputPassword" v-model="newPassword" @input="passwordCheck()"/>
                                                <ul class="password-requirements" v-if="!passwordCorrect">
                                                    <li data-testid="specialistPasswordError1"><i class="fas fa-times red" v-if="!passwordRequirements.characters"></i><i class="fas fa-check green" v-if="passwordRequirements.characters"></i> {{profile.accountPage.account.length}}</li>
                                                    <li data-testid="specialistPasswordError2"><i class="fas fa-times red" v-if="!passwordRequirements.uppercase"></i><i class="fas fa-check green" v-if="passwordRequirements.uppercase"></i> {{profile.accountPage.account.ucase}}</li>
                                                    <li data-testid="specialistPasswordError3"><i class="fas fa-times red" v-if="!passwordRequirements.lowercase"></i><i class="fas fa-check green" v-if="passwordRequirements.lowercase"></i> {{profile.accountPage.account.lcase}}</li>
                                                    <li data-testid="specialistPasswordError4"><i class="fas fa-times red" v-if="!passwordRequirements.number"></i><i class="fas fa-check green" v-if="passwordRequirements.number"></i> {{profile.accountPage.account.nr}}</li>
                                                </ul>
                                            </div>
                                            <div class="col-lg-6">
                                                <label>{{profile.accountPage.account.confirm_new_pw}}</label>
                                                <input type="password" class="form-control" v-model="newPasswordConfirm" @input="checkPasswords()" data-testid="accountInputConfirmPassword" />
                                                <p data-testid="specialistPasswordError5" class="password-requirements" v-if="!passwordsMatch"><b>{{profile.accountPage.account.match}}</b></p>
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <label>{{profile.accountPage.account.communication}}</label>
                                    <div class="row" v-if="!specialistInfo.email">
                                        <div class="col">
                                            <div class="notification notification--warning" style="margin-bottom: 20px;">{{profile.repair_companies.repaircompanydetail.comm_not}}</div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-6" style="cursor: pointer;">
                                            <div class="dropdown-section" style="margin-bottom: 0;">
                                                <span class="dropdown" v-on:click="communicationDropdownToggled = !communicationDropdownToggled" :class="{active: communicationDropdownToggled}">
                                                    <span data-testid="specialistWarining1" v-if="!comm" style="color: #212529; font-size: 14px;">{{profile.accountPage.account.communication}}</span>
                                                    <span data-testid="specialistWarining2" v-if="comm === 'only_email'" class="selected">{{ profile.repair_companies.repaircompanydetail.onlyemail }}</span>
                                                    <span data-testid="specialistWarining3" v-if="comm === 'only_simone'" class="selected">{{ profile.repair_companies.repaircompanydetail.onlysimone }}</span>
                                                    <span data-testid="specialistWarining4" v-if="comm === 'simone_email'" class="selected">{{ profile.repair_companies.repaircompanydetail.simone_email }}</span>
                                                    <i class="fas fa-chevron-down chevron"></i>
                                                </span>
                                                <div class="dropdown-list search" v-if="communicationDropdownToggled">
                                                    <ul>
                                                        <li data-testid="specialistWariningDropdown2" v-on:click="saveComms('only_email')">{{ profile.repair_companies.repaircompanydetail.onlyemail }}</li>
                                                        <li data-testid="specialistWariningDropdown3" v-on:click="saveComms('only_simone')">{{ profile.repair_companies.repaircompanydetail.onlysimone }}</li>
                                                        <li data-testid="specialistWariningDropdown4" v-on:click="saveComms('simone_email')">{{ profile.repair_companies.repaircompanydetail.simone_email }}</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>                
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
                <div id="calendar">
                    <MechanicSchedule :specialistInfo="specialistInfo" v-if="specialistInfo.id_mechanic" />
                </div>
            </div>
        </div>
        <RemoveSpecialistModal v-if="removeSpecialistModalOpen" @close="removeSpecialistModalOpen = false" :specialistInfo="specialistInfo"/>

    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale-supplier.js';
import MechanicSchedule from "@/components/supplier/MechanicSchedule.vue";
import RemoveSpecialistModal from "@/components/supplier/modals/RemoveSpecialistModal.vue";

export default {
    mixins: [setLocale],
    components: {
        MechanicSchedule,
        RemoveSpecialistModal
    },
    data() {
        return {
            profile: {},
            loading: true,
            specialistId: null,
            specialistInfo: {},
            logoLoading: false,
            specialistEdit: false,
            validEmail: false,
            invalidEmail: false,
            validPhone: false,
            invalidPhone: false,
            communicationDropdownToggled: false,
            comm: '',
            newPassword: '',
            newPasswordConfirm: '',
            passwordRequirements: {
                characters: false,
                uppercase: false,
                lowercase: false,
                number: false
            },
            passwordCorrect: true,
            passwordsMatch: true,
            removeSpecialistModalOpen: false
        }
    },
    created() {
        let queryString = window.location.search;
        this.specialistId = queryString.split('=')[1];
        this.getSpecialistInfo();
    },
    methods: {
        track() {
            this.$gtag.pageview(this.$route);
        },
        getSpecialistInfo() {
            let self = this;
            fetch('/api/v1/mechanic/byid?id_mechanic=' + this.specialistId)
            .then(response => { response.json().then(
                function(data) {
                    self.specialistInfo = data;
                    if (self.specialistInfo.send_email && self.specialistInfo.only_email) {
                        self.comm = 'only_email';
                    }
                    if (!self.specialistInfo.send_email && !self.specialistInfo.only_email) {
                        self.comm = 'only_simone';
                    }
                    if (self.specialistInfo.send_email && !self.specialistInfo.only_email) {
                        self.comm = 'simone_email';
                    }
                    document.title = 'S1MONE | ' + self.specialistInfo.name;
                });
            });
        },
        saveSpecialistInfo() {
            if (this.passwordCorrect && this.passwordsMatch && this.newPassword.length) {
                const data = new URLSearchParams();
                data.append('newpassword', this.newPassword);
                data.append('id_mechanic', this.specialistInfo.id_mechanic);
                let self = this;
                fetch('/api/v1/changepassword/formechanic', {
                    method: 'post',
                    body: data
                }).then((resp) => {return resp.json();
                }).then(function(data) {
                    self.specialistEdit = false;
                    self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                });
            }
            if (this.validPhone) {
                const data = new URLSearchParams();
                data.append('action', 'edit');
                data.append('data[' + this.specialistId + '][mobile]', this.specialistInfo.mobile);
                let self = this;
                fetch('/api/v1/mechanic', {
                    method: 'post',
                    body: data
                }).then((resp) => {return resp.text();
                }).then(function(data) {
                    self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                    self.specialistEdit = false;
                });
            }
            if (this.validEmail) {
                const data = new URLSearchParams();
                data.append('action', 'edit');
                data.append('data[' + this.specialistId + '][email]', this.specialistInfo.email);
                let self = this;
                fetch('/api/v1/mechanic', {
                    method: 'post',
                    body: data
                }).then((resp) => {return resp.text();
                }).then(function(data) {
                    self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                    self.specialistEdit = false;
                });
            }
            const data = new URLSearchParams();
            data.append('action', 'edit');
            data.append('data[' + this.specialistId + '][name]', this.specialistInfo.name);
            let self = this;
            fetch('/api/v1/mechanic', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.text();
            }).then(function(data) {
                self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                self.specialistEdit = false;
            });
        },
        editLogo() {
            document.getElementById('profile-image-upload').click();
        },
        uploadLogo($event) {
            this.logoLoading = true;
            if ($event.target.files.length == 1) {
                const data = new FormData();
                data.append('file', $event.target.files[0]);
                data.append('id_mechanic', this.specialistId);
                let self = this;
                fetch('/api/v1/fileupload/uploadlogo', {
                    method: 'post',
                    body: data
                }).then((resp) => {return resp.text();
                }).then(function(data) {
                    self.specialistInfo.profile_image_loc = data;
                    self.logoLoading = false;
                });
            }
        },
        removeLogo() {
            const data = new URLSearchParams();
            data.append('id_mechanic', this.specialistId);
            let self = this;
            fetch('/api/v1/fileupload/deletelogo', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.text();
            }).then(function(data) {
                self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                self.getSpecialistInfo();
            });
        },
        saveComms(type) {
            this.communicationDropdownToggled = false;
            const data = new URLSearchParams();
            data.append('action', 'edit');
            if (type === 'only_email') {
                data.append('data[' + this.specialistId + '][send_email]', true);
                data.append('data[' + this.specialistId + '][only_email]', true);
            }  
            if (type === 'only_simone') {
                data.append('data[' + this.specialistId + '][send_email]', false);
                data.append('data[' + this.specialistId + '][only_email]', false);
            }  
            if (type === 'simone_email') {
                data.append('data[' + this.specialistId + '][send_email]', true);
                data.append('data[' + this.specialistId + '][only_email]', false);
            }        
            let self = this;
            fetch('/api/v1/mechanic', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.text();
            }).then(function(data) {
                self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                self.getSpecialistInfo();
            });
        },
        passwordCheck() {
            const ucase = new RegExp("[A-Z]+");
            const lcase = new RegExp("[a-z]+");
            const num = new RegExp("[0-9]+");
            if (this.newPassword.length > 9) {
                this.passwordRequirements.characters = true;
            }
            else {
                this.passwordRequirements.characters = false;
                this.passwordCorrect = false;
            }
            if (ucase.test(this.newPassword)) {
                this.passwordRequirements.uppercase = true;
            }
            else {
                this.passwordRequirements.uppercase = false;
                this.passwordCorrect = false;
            }
            if (lcase.test(this.newPassword)) {
                this.passwordRequirements.lowercase = true;
            }
            else {
                this.passwordRequirements.lowercase = false;
                this.passwordCorrect = false;
            }
            if (num.test(this.newPassword)) {
                this.passwordRequirements.number = true;
            }
            else {
                this.passwordRequirements.number = false;
                this.passwordCorrect = false;
            }
            if (!this.newPassword.length) {
                this.passwordCorrect = true;
            }
            this.checkValidations();
        },
        checkValidations() {
            if (this.passwordRequirements.characters &&
                this.passwordRequirements.uppercase &&
                this.passwordRequirements.lowercase &&
                this.passwordRequirements.number &&
                this.newPassword === this.newPasswordConfirm) {
                    this.passwordCorrect = true;
            }
            else {
                this.passwordCorrect = false;
            }
            if (this.newPassword === this.newPasswordConfirm) {
                this.passwordsMatch = true;
            }
        },
        checkPasswords() {
            if (this.newPassword !== this.newPasswordConfirm) {
                this.passwordsMatch = false;
                this.passwordCorrect = false;
            }
            else {
                this.passwordsMatch = true;
            }
            this.checkValidations();
        },
        validateEmail() {
            if (this.specialistInfo.email) {
                let pattern = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
                if (pattern.test(this.specialistInfo.email)) {
                    this.validEmail = true;
                    this.invalidEmail = false;
                }
                else {
                    this.invalidEmail = true;
                    this.validEmail = false;
                }
            }
            else {
                this.invalidEmail = false;
                this.validEmail = false;
            }
        },
        validatePhone() {
            if (this.specialistInfo.mobile) {
                let pattern = new RegExp(/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/);
                if (pattern.test(this.specialistInfo.mobile)) {
                    this.validPhone = true;
                    this.invalidPhone = false;
                }
                else {
                    this.invalidPhone = true;
                    this.validPhone = false;
                }
            }
            else {
                this.invalidPhone = false;
                this.validPhone = false;
            }
        }
    }
}
</script>
