<template>
  <header id="header" v-if="profile.nav">
      <v-idle
        @idle="onidle"
        :loop="true"
        :duration="14400"
        style="display: none;" />
    <div class="container">
        <div @click="closeNav()">
            <router-link tag="a" to="/owner/dashboard" class="logo"><img src="@/assets/img/logo.png" /></router-link>
        </div>
        <nav class="d-none d-md-block d-lg-block" :class="{active: mobileNavToggle}">
            <router-link @click.native="openNav()" data-testid="tabDashboard" tag="a" to="/owner/dashboard">{{profile.nav.dashboard}}</router-link>
            <router-link @click.native="openNav()" data-testid="tabOwners" tag="a" to="/owner/owners">{{profile.parts.parts}}</router-link>
        </nav>
        <section class="utils">
            <span class="util util--language" v-if="langSwitch"><img v-if="locale === 'nl'" src="@/assets/icons/dutch.svg" /><img v-if="locale === 'en'" src="@/assets/icons/english.svg" />
                <ul class="dropdown-nav">
                    <span class="util util--language" v-if="locale === 'en'" data-testid="ownerTabNl" @click="setLang('nl')"><img src="@/assets/icons/dutch.svg" /></span>
                    <span class="util util--language" v-if="locale === 'nl'" data-testid="ownerTabEn" @click="setLang('en')"><img src="@/assets/icons/english.svg" /></span>
                </ul>
            </span>
            <span class="util util--user" data @click="showUtils = !showUtils"><i class="fas fa-user"></i></span>
            <ul class="dropdown-nav large" v-show="showUtils">
                <router-link tag="li" data-testid="tabAppointments"  to="/buildingmanager/daily-appointments" @click.native="closeNav()">{{profile.nav.daily_appointments}}</router-link>
                <li><a href="/api/v1/logout" data-testid="tabLogout" style="display: block; width: 47px; text-decoration: none; color: #212529;">{{profile.nav.account.logout}}</a></li>
            </ul>
        </section>
        <span class="open-nav d-block d-md-none" @click="openNav()" v-if="!mobileNavToggle"><i class="fas fa-bars"></i></span>
        <span class="open-nav d-block d-md-none" @click="openNav()" v-if="mobileNavToggle"><i class="fa fa-times"></i></span>
    </div>
  </header>
</template>

<script>
import { EventBus } from '@/js/event-bus.js';

export default {
    name: 'Header',
    data: function () {
        return {
            mobileNavToggle: false,
            industry: '',
            profile: {},
            locale: '',
            bmInterval: null,
            langSwitch: true,
            authenticated: false,
            isVve: false,
            showUtils: false
        }
    },
    created() {
        fetch('/api/v1/isauthenticated')
        .then(response => { response.text().then(
            function(data) {
                if (data === 'false') {
                    window.location = '/signin.html';
                }
            });
        });
        this.industry = 'housing';
        this.locale = 'nl';
        this.profile = require('../../assets/locales/' + this.locale + '/' + this.industry + '.json');  
    },
    beforeDestroy() {
        clearInterval(this.bmInterval);
    },
    methods: {
        getUrlParameter(name) {
            return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.search)||[""])[1].replace(/\+/g, '%20'))||null;
        },
        onidle() {
            window.location = '/signin.html?timeout=true';
        },
        openNav() {
            this.mobileNavToggle = !this.mobileNavToggle;
            let el = document.body;
            if (this.mobileNavToggle) {
                el.classList.add("modal-open");
            }
            if (!this.mobileNavToggle) {
                el.classList.remove("modal-open");
            }
        },
        closeNav() {
            this.mobileNavToggle = false;
            let el = document.body;
            el.classList.remove("modal-open");
            this.showUtils = false;
        },
        setLang(lang) {
            this.locale = lang;
            let busObj = {
                locale: lang,
                industry: this.industry
            }
            localStorage.setItem('S1-locale', lang);
            EventBus.$emit('langChange', busObj);
            this.profile = require('../../assets/locales/' + this.locale + '/' + this.industry + '.json');
        }
    }
}
</script>
