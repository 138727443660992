<template>
    <div>
        <div class="main-content">
            <div class="container">
                <div class="row">
                    <div class="col">
                        <div class="block block--owners" >
                            <section class="incident-head">
                                <h1>Melding Categorieën</h1>
                                <span class="edit-button icon" @click="newCategoryModalOpen = true"><i class="fas fa-plus"></i></span>
                            </section>
                            <div class="row">
                                <div class="col-sm-8">
                                    <section class="search search-alt" v-if="categories.length">
                                        <form @submit.prevent="searchSuggest()">
                                            <input type="text" ref="search" v-bind:placeholder="'Zoek een categorie'" @input="e => searchTerm = e.target.value" :value="searchTerm" v-on:keyup="searchSuggest($event)" class="no-margin" />
                                            <span class="close-suggestions" @click="clearSuggestions()" v-if="searchActive"><i class="fas fa-times"></i></span>
                                        </form>
                                    </section>
                                </div>
                            </div>
                            <div>
                                <h4 class="result-counter" v-if="!searchActive">{{categories.length}} {{'categorieën'}}</h4>
                                <h4 class="result-counter" v-if="searchSuggestions.length === 1">{{searchSuggestions.length}} {{'resultaat gevonden met de term'}} '{{searchTerm}}'</h4>
                                <h4 class="result-counter" v-if="!searchSuggestions.length && searchActive">{{"Geen resultaten gevonden met de term"}} '{{searchTerm}}'.</h4>
                                <h4 class="result-counter" v-if="searchSuggestions.length > 1">{{searchSuggestions.length}} {{'resultaten gevonden met de term'}} '{{searchTerm}}'</h4>
                            </div>
                            <div class="table-wrap" style="height: 600px; overflow: auto;">
                                <table class="table table-striped" v-if="categories.length && !searchActive">
                                    <colgroup>
                                        <col style="width: 20%;" />
                                        <col style="width: 20%;" />
                                        <col style="width: 12%;" />
                                        <col style="width: 10%;" />
                                        <col style="width: 5%;" />
                                        <col style="width: 6%;" />
                                        <col style="width: 5%;" />
                                        <col style="width: 3%;" />
                                        <col style="width: 3%;" />
                                    </colgroup>
                                    <thead class="sticky">
                                        <tr>
                                            <th scope="col" @click="sortTable('canonical')" :class="{active: categorySearchType === 'canonical'}">Canonieke naam <i class="fas fa-sort"></i></th>
                                            <th scope="col" @click="sortTable('dutch_name')" :class="{active: categorySearchType === 'dutch_name'}">NL<i class="fas fa-sort"></i></th>
                                            <th scope="col" @click="sortTable('english_name')" :class="{active: categorySearchType === 'english_name'}">EN<i class="fas fa-sort"></i></th>
                                            <th scope="col" @click="sortTable('english_name')" :class="{active: categorySearchType === 'english_name'}">Beheerder<i class="fas fa-sort"></i></th>
                                            <th scope="col" @click="sortTable('active')" :class="{active: categorySearchType === 'active'}">Actief<i class="fas fa-sort"></i></th>
                                            <th scope="col" @click="sortTable('list_order')" :class="{active: categorySearchType === 'list_order'}">Volgorde<i class="fas fa-sort"></i></th>
                                            <th scope="col" @click="sortTable('media_required')" :class="{active: categorySearchType === 'media_required'}">Media<i class="fas fa-sort"></i></th>
                                            <th></th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(category, index) in categoriesShown" :key="index">
                                            <td>{{category.canonical}}</td>
                                            <td>{{category.dutch_name}}</td>
                                            <td>{{category.english_name }}</td>
                                            <td>{{getManagerName(category.id_building_manager) }}</td>
                                            <td>{{category.active }}</td>
                                            <td>{{category.list_order }}</td>
                                            <td>{{category.media_required ? "Verplicht" : "Optioneel" }}</td>
                                            <td><i class="fas fa-pen" @click="editCategoryModalOpen = true; editCategory=category"></i></td>
                                            <td><i class="far fa-trash-alt" @click="removeCategoryModalOpen = true; removeCategory=category"></i></td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table class="table table-striped" v-if="categories.length && searchActive">
                                    <colgroup>
                                        <col style="width: 20%;" />
                                        <col style="width: 20%;" />
                                        <col style="width: 12%;" />
                                        <col style="width: 10%;" />
                                        <col style="width: 5%;" />
                                        <col style="width: 6%;" />
                                        <col style="width: 5%;" />
                                        <col style="width: 3%;" />
                                        <col style="width: 3%;" />
                                    </colgroup>
                                    <thead class="sticky">
                                        <tr>
                                            <th scope="col" @click="sortTable('canonical')" :class="{active: categorySearchType === 'canonical'}">Canonieke naam <i class="fas fa-sort"></i></th>
                                            <th scope="col" @click="sortTable('dutch_name')" :class="{active: categorySearchType === 'dutch_name'}">NL<i class="fas fa-sort"></i></th>
                                            <th scope="col" @click="sortTable('english_name')" :class="{active: categorySearchType === 'english_name'}">EN<i class="fas fa-sort"></i></th>
                                            <th scope="col" @click="sortTable('english_name')" :class="{active: categorySearchType === 'english_name'}">Beheerder<i class="fas fa-sort"></i></th>
                                            <th scope="col" @click="sortTable('active')" :class="{active: categorySearchType === 'active'}">Actief<i class="fas fa-sort"></i></th>
                                            <th scope="col" @click="sortTable('list_order')" :class="{active: categorySearchType === 'list_order'}">Volgorde<i class="fas fa-sort"></i></th>
                                            <th scope="col" @click="sortTable('media_required')" :class="{active: categorySearchType === 'media_required'}">Media<i class="fas fa-sort"></i></th>
                                            <th></th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(category, index) in searchSuggestions" :key="index">
                                            <td>{{category.canonical}}</td>
                                            <td>{{category.dutch_name}}</td>
                                            <td>{{category.english_name }}</td>
                                            <td>{{getManagerName(category.id_building_manager) }}</td>
                                            <td>{{category.active }}</td>
                                            <td>{{category.list_order }}</td>
                                            <td>{{category.media_required ? "Verplicht" : "Optioneel" }}</td>
                                            <td><i class="fas fa-pen" @click="editCategoryModalOpen = true; editCategory=category"></i></td>
                                            <td><i class="far fa-trash-alt" @click="removeCategoryModalOpen = true; removeCategory=category"></i></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>                          
                        </div>
                    </div>
                </div>
            </div>  
        </div>
        <NewcategoryModal v-if="newCategoryModalOpen" @close="loadCategories()" />  
        <RemovecategoryModal v-if="removeCategoryModalOpen" :category="removeCategory" @close="loadCategories()" />
        <editCategoryModal v-if="editCategoryModalOpen" :category="editCategory" @close="loadCategories()" />
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale-mechanic.js';
import sortBy from 'lodash/sortBy'
import NewcategoryModal from '@/views/s1mone/modals/NewIncidentCategoryModal.vue';
import RemovecategoryModal from '@/views/s1mone/modals/RemoveIncidentCategoryModal';
import editCategoryModal from '@/views/s1mone/modals/EditIncidentCategoryModal.vue';

export default {
    mixins: [setLocale],
    components: {
        NewcategoryModal,
        RemovecategoryModal,
        editCategoryModal
    },
    data() {
        return {
            searchTerm: '',
            searchSuggestions: [],
            categories: [],
            categoriesShown: [],
            managers: [],
            searchActive: false,
            categorySearchType: '',
            removeCategory: {},
            newCategoryModalOpen: false,
            removeCategoryModalOpen: false,
            editCategoryModalOpen: false,
            editCategory: {},
        }
    },
    created() {
        document.title = 'S1MONE | Incident Categories';
        this.loadCategories();
        this.loadManagers();
    },
    methods: {
        loadCategories() {
            let self = this;
            this.newCategoryModalOpen = false;
            this.removeCategoryModalOpen = false;
            this.editCategoryModalOpen = false;
            fetch('/api/v1/incident_category?')
            .then(response => { response.json().then(
                function(data) {
                    self.categories = data.data;
                    self.categoriesShown = data.data;
                });
            });
        },
        loadManagers(){
            let self = this;
            fetch('/api/v1/buildingmanager?')
            .then(response => { response.json().then(
                function(data) {
                    self.managers = data.data;
                });
            });
        },
        getManagerName(id){
            const manager = this.managers.find(m => m.id_building_manager === id);
            return manager ? manager.name : '';
        },
        searchSuggest($event) {
            this.searchSuggestions = [];
            let searchTerm = this.searchTerm.toLowerCase();
            if (searchTerm.length > 2) {
                this.searchActive = true;
                for (var i = 0; i < this.categoriesShown.length; i++) {
                    let category = this.categoriesShown[i];
                    let canonical = category.canonical.toLowerCase();
                    let dutch = category.dutch_name.toLowerCase();
                    let english = category.english_name.toLowerCase();
                    let manager = this.getManagerName(category.id_building_manager).toLowerCase();
                    let active = category.active.toString().toLowerCase();
                    let listOrder = category.list_order ? category.list_order.toString().toLowerCase() : '';
                    let mediaRequired = category.media_required ? 'verplicht' : 'optioneel';
                    if (canonical.includes(searchTerm) || dutch.includes(searchTerm) || english.includes(searchTerm) || manager.includes(searchTerm) || active.includes(searchTerm) || listOrder.includes(searchTerm) || mediaRequired.includes(searchTerm)) {
                        this.searchSuggestions.push(category);
                    }
                }
            }
            if (searchTerm.length === 0) {
                this.searchActive = false;
            }
            if($event && $event.keyCode === 27) {
                this.searchActive = false;
                this.searchTerm = '';
                this.searchSuggestions = [];
            }
        },
        clearSuggestions() {
            this.searchSuggestions = [];
            this.searchTerm = '';
            this.searchActive = false;
        },
        sortTable(sorter) {
            this.categorySearchType = sorter;
            this.sortOrder = this.sortOrder !== 'asc' ? 'asc' : 'desc';
            this.categoriesShown = sortBy(this.categoriesShown, sorter);
            if (this.sortOrder === 'desc') {
                this.categoriesShown.reverse();
            }
        }
        // selectasset(id) {
        //     this.$router.push('/s1mone/buildingmanager-detail?id=' + id);
        // }
    }
}
</script>
