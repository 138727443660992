<template>
    <div>
        <div class="block block--incident_detail" style="overflow: visible;">
            <div class="incident-head">
                <h1 style="max-width: 100%; font-size: 18px;">{{profile.dashboard.reporter_info}}</h1>
            </div>
            <section class="incident-contents">
                <div class="incident-info">
                    <div class="row">
                        <div class="col">
                            <label>{{profile.incident_detail.rating_comment}}</label>
                            <p>{{incidentInfo.reporter_name}}</p>
                        </div>
                    </div>
                    <div class="row" v-if="incidentInfo.reporter_phone">
                        <div class="col">
                            <label style="position: relative;">{{profile.accountPage.account.contactmobile}}</label>
                            <p v-html="incidentInfo.reporter_phone"></p>
                        </div>
                    </div>
                    <div class="row" v-if="incidentInfo.reporter_address">
                        <div class="col">
                            <label>{{ profile.employees.address }}</label>
                            <p>{{ incidentInfo.reporter_address }} {{ incidentInfo.reporter_house_number }}, {{ incidentInfo.reporter_zipcode }}</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <div v-if="incidentInfo.asset_building_manager_is_vve && industry === 'housing'">
                                <label>{{profile.incident_detail.renter}} / {{profile.incident_detail.owner}}</label>
                                <p v-if="incidentInfo.reporter_is_owner">{{profile.incident_detail.owner}}</p>
                                <p v-else>{{profile.incident_detail.renter}}</p>
                            </div>
                        </div>
                    </div>           
                    <div class="row" v-if="incidentInfo.id_external">
                        <div class="col">
                            <label style="position: relative;">{{profile.incident_detail.ref_number}}</label>
                            <p v-if="incidentInfo.id_external" v-html="incidentInfo.id_external"></p>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale-supplier.js';

export default {
    mixins: [setLocale],
    props: ['incidentInfo']
}
</script>
