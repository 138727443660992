<template>
    <div class="modal-wrapper" v-if="profile.incident_detail">
        <div class="modal">
            <div class="modal-header">
                <h3><i class="fas fa-pen"></i>{{profile.incident_detail.reactivate_task}}</h3>
                <img src="@/assets/img/logo-white.png" class="logo" />
            </div>
            <div class="modal-inner">
                <span class="close" @click="closeModal()"><i class="fas fa-times"></i></span>
                <p>{{profile.incident_detail.restart_incident_start}}</p>
                <br />
                <section class="actions actions--full">
                    <div class="button-wrap">
                        <span data-testid="submitRestartIncident" class="button button--icon button--cta button--right" @click.stop="restartIncident()"><i class="fas fa-play"></i>{{profile.incident_detail.reactivate_task}}</span>
                    </div>
                </section>
            </div>
        </div>
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale.js';
export default {
    mixins: [setLocale],
    name: 'appointmentEditModal',
    data() {
        return {
            profile: {}
        }
    },
    props: ['incidentId'],
    created() {
        document.querySelector('body').classList.add('modal-open');
        let self = this;
        document.onkeydown = function(evt) {
            evt = evt || window.event;
            if (evt.keyCode == 27) {
                self.closeModal();
            }
        };
    },
    methods: {
        closeModal() {
            this.$emit('close');
            document.querySelector('body').classList.remove('modal-open');
        },
        restartIncident() {
            this.$emit('restartincident');
            this.$emit('close');
        }
    }
}
</script>
