<template>
    <div class="modal-wrapper" v-if="profile.places">
        <div class="modal">
            <div class="modal-header">
                <h3><i class="fas fa-trash-alt"></i>{{profile.owners.agreementflow.remove_agreement_flow}}</h3>
                <img src="@/assets/img/logo-white.png" class="logo" />
            </div>
            <div class="modal-inner">
                <span class="close" @click="closeModal()"><i class="fas fa-times"></i></span>
                <p>{{profile.owners.agreementflow.remove_agreement_flow_sure}}<br /><br /></p>
                <button class="button button--icon button--right" data-testid="buttonStopAgreementSubmit" v-on:click="removeFromFlow()"><i class="fas fa-trash-alt"></i>{{profile.owners.agreementflow.remove_agreement_flow}}</button>
            </div>
        </div>
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale.js';
export default {
    mixins: [setLocale],
    props: ['incidentId'],
    data() {
        return {
            profile: {}
        }
    },
    created() {
        document.querySelector('body').classList.add('modal-open');
        let self = this;
        document.onkeydown = function(evt) {
            evt = evt || window.event;
            if (evt.keyCode == 27) {
                self.closeModal();
            }
        };
    },
    methods: {
        removeFromFlow() {
            const data = new URLSearchParams();
            data.append('id_incident', this.incidentId);
            let self = this;
            fetch('/api/v1/incident/cancelaccflow', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.json();
            }).then(function(data) {
                document.querySelector('body').classList.remove('modal-open');
                self.$emit('success');
            });
        },
        closeModal() {
            this.$emit('close');
            document.querySelector('body').classList.remove('modal-open');
        }
    }
}
</script>
