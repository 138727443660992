<template>
    <div class="block block--incident_detail">
        <div class="incident-head" :class="{empty: !media.length && !movs.length}">
            <h1>{{profile.incident_detail.imagery}}</h1>
            <span class="edit-button icon" @click="uploadMediaModalOpen = true;" style="margin-top: 0;" v-if="admin"><i class="fas fa-plus"></i></span>
        </div>
        <div class="incident-media" v-if="media.length || movs.length">
            <div class="media-wrapper">
                <div class="media media--small" data-testid="showMediaItem" v-for="(item, index) in media" :key="index" :class="{active: item.popover, video: item.content_type === 'video/mp4'}" v-tooltip.left="item.description">
                    <img :src="item.file_url" @click="openPopover(item)" v-if="item.content_type === 'image/jpeg' || item.content_type === null && !item.i_frame_code" />
                    <div class="iframe_overlay" v-if="item.i_frame_code" @click="openPopover(item)" style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; z-index: 20;"></div>
                    <div v-html="item.i_frame_code" v-if="item.i_frame_code" style="margin-top: -35px;"></div>
                    <div class="video_overlay" @click="openPopover(item)" v-if="item.content_type === 'video/mp4'"><i class="fas fa-play"></i></div>
                    <video v-if="item.content_type === 'video/mp4'" @click="openPopover(item)">
                        <source :src="item.file_url" type="video/mp4">
                    </video>
                    <span class="label" v-if="profile.incident_detail[item.type]">{{profile.incident_detail[item.type]}}</span>
                    <span class="thumb" v-tooltip.right="profile.incident_detail.set_thumb" @click="setAsThumb(item)" v-if="item.content_type === 'image/jpeg'"><i class="fas fa-image"></i></span>
                    <span class="remove" data-testid="removeMediaItem" v-if="(item.type === 'incident_building_manager' || admin)" @click="selectFileToRemove(item)"><i class="far fa-trash-alt"></i></span>
                    <div class="media-pop-over">
                        <span class="close-popover" @click="closePopover()"><i class="fa fa-times"></i></span>
                        <span class="label" v-if="profile.incident_detail[item.type]">{{profile.incident_detail[item.type]}}</span>
                        <span class="media-counter">{{index + 1}} / {{media.length}}</span>
                        <image-zoom 
                            :regular=item.file_url
                        />
                        <div v-html="item.i_frame_code" v-if="item.i_frame_code" style="margin-top: 40px;"></div>
                        <video data-testid="mediaVideoOverlay" v-if="item.content_type === 'video/mp4'" :class="{rotated: item.rotate}" controls><source :src="item.file_url" type="video/mp4"></video>
                        <p v-if="item.description">{{item.description}}</p>
                        <span class="media-control left" v-if="index !== 0" @click="selectMedia('prev', index)"><i class="fa fa-chevron-left"></i></span>
                        <span class="media-control right" v-if="index + 1 !== media.length" @click="selectMedia('next', index)"><i class="fa fa-chevron-right"></i></span>
                    </div>
                    <p style="position: absolute; z-index: 1; background: rgba(0,0,0,.4); font-size: 12px; padding: 3px; color: white; border-radius: 0 0 5px 0;">{{ item.createdDate }}</p>
                </div>
                <div class="media video" data-testid="showMediaVideo" v-for="item in movs" :key="item.id_file_upload">
                    <span class="label" v-if="profile.incident_detail[item.type]">{{profile.incident_detail[item.type]}}</span>
                    <span class="placeholder"><i class="fas fa-file-video"></i></span>
                    <a :href="item.file_url" class="video_overlay download" v-if="item.content_type === 'video/quicktime'"></a>
                    <span class="remove" data-testid="buttonRemoveVideo" v-if="(item.type === 'incident_building_manager' || admin)" @click="selectFileToRemove(item)"><i class="far fa-trash-alt"></i></span>
                    <p style="position: absolute; z-index: 1; background: rgba(0,0,0,.4); font-size: 12px; padding: 3px; color: white; border-radius: 0 0 5px 0;">{{ item.createdDate }}</p>
                </div>
            </div>
        </div> 
        <UploadMediaModal v-if="uploadMediaModalOpen" @close="uploadMediaModalOpen = false" @success="getAsset()" :asset="asset" />
        <RemoveFileModal v-if="removeFileModalOpen" @close="removeFileModalOpen = false" @success="getAsset()" :file="file" /> 
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale.js';
import UploadMediaModal from "@/components/asset/modals/UploadMediaModal.vue";
import RemoveFileModal from "@/components/asset/modals/RemoveFileModal.vue";
import imageZoom from 'vue-image-zoomer';

export default {
    name: 'MediaDocs',
    mixins: [setLocale],
    components: {
        UploadMediaModal,
        RemoveFileModal,
        imageZoom
    },
    data: function () {
        return {
            uploadMediaModalOpen: false,
            removeFileModalOpen: false
        }
    },
    props: ['media', 'movs', 'asset', 'admin'],
    methods: {
        selectMedia(type, index) {
            if (type === 'prev') {
                index = index - 1;
                for (var i = 0; i < this.media.length; i++) {
                    this.media[i].popover = false;
                    this.media[index].popover = true;
                }
            }
            if (type === 'next') {
                index = index + 1;
                for (var j = 0; j < this.media.length; j++) {
                    this.media[j].popover = false;
                    this.media[index].popover = true;
                }
            }
        },
        openPopover(item) {
            item.popover = true;
            document.querySelector('body').classList.add('media-open');
        },
        closePopover() {
            document.querySelector('body').classList.remove('media-open');
            for (var i = 0; i < this.media.length; i++) {
                this.media[i].popover = false;
            }
        },
        selectFileToRemove(item) {
            this.removeFileModalOpen = true;
            this.file = item;
        },
        rotateMedia(item) {
            item.rotate++;
            if (item.rotate === 5) {
                item.rotate = 1;
            }
        },
        getAsset() {
            this.uploadMediaModalOpen = false;
            this.removeFileModalOpen = false;
            this.$emit('getAsset');
        },
        setAsThumb(item) {
            const data = new URLSearchParams();
            data.append('id_asset', this.asset.id_asset);
            data.append('main_image_id_file_upload', item.id_file_upload);
            let self = this;
            fetch('/api/v1/asset/update', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.text();
            }).then(function(data) {
                self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                self.$emit('getAsset');
            });
        },
        copyText(text) {
            if (text) {
                navigator.clipboard.writeText(text);
                this.$vToastify.success(this.profile.incident_detail.text_copied, this.profile.toast.changes_saved);
            }
        }
    }
}
</script>
